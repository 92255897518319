// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function lazyLoad_nextSlideImg(nextSlide) {
  const imgWrappers = nextSlide.querySelectorAll("[g-ref='images']");
  if (!imgWrappers) return console.warn("lazyLoad_nextSlideImg(): No imgWrappers found in nextSlide");
  const imgWrapper0 = imgWrappers[0];
  const imgWrapper1 = imgWrappers[1];
  [imgWrapper0, imgWrapper1].forEach((imgWrapper) => {
    const imgEl = imgWrapper.querySelector("img");
    if (imgEl) {
      eventbus.emit("LazyLoader.api.loadImg", { imgEl });
      nextSlide.setAttribute("data-has-been-loaded", "true");
    }
  });
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: ["slide has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_slideChange(SELECTEDINDEX) {
  // Setup...
  const { slides } = this.ref;
  this.logger("event", msgs.start, "event", { inline: true });

  // Lazy load 1st img. of next 2 unloaded slides...
  // const unloadedSlides = slides.filter((slideEl) => slideEl.dataset.hasBeenLoaded === "false");
  // if (unloadedSlides.length > 0) {
  //   const nextSlides = [unloadedSlides[0], unloadedSlides[1]];
  //   nextSlides.forEach((nextSlide) => lazyLoad_nextSlideImg(nextSlide));
  // }

  const activeSlide = slides[SELECTEDINDEX];
  if (!activeSlide) return;

  this.setState({ activeSlideIndex: SELECTEDINDEX });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
