// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.hide_cardFollower", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "hide_cardFollower"],
  end: ["PartiForm", "API", "hide_cardFollower", "API call complete"],
  no_cardEl: ["DragCards", "API", "hide_cardFollower", "no valid card el. provided"],
  no_followerEl: (CARDEL) => ["DragCards", "API", "hide_cardFollower", "no follower el. found for card el.: ", CARDEL],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function hide_cardFollower(CARDEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(msgs.no_cardEl);

  //////////////////////////////// Card follower visibility updt. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find related follower el...
  const followerEl = this.get_cardFollowerEl(CARDEL);
  if (!followerEl) return this.logger("error", msgs.no_followerEl(CARDEL), "error", { ...logArgs, inline: false });

  //////////////////////////// Mobile screens ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (this.state.is_mobile) followerEl.style.height = "0";

  ///////////////////////////// All screens //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Hide follower...
  // - Mark card as having its follower hidden...
  followerEl.setAttribute("data-is-hidden", "true");
  CARDEL.setAttribute("data-follower-hidden", "true");

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
