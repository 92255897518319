// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_widgetCloseBtn_click", inline: true };
const msgs = {
  start: ["widget close button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_widgetCloseBtn_click() {
  // Setup...
  this.logger("event", msgs.start, "event", logArgs);

  // Updt. widget state:
  // - Hide widget...
  const { activeTermWidgetState } = this.state;
  this.setState({ activeTermWidgetState: { ...activeTermWidgetState, is_hidden: true } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
