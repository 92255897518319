// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger.js";
import set_state from "../../baseUtilities/state/set_state";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_glossaryLinks from "./init/init_glossaryLinks.js";
import init_activeTermDisplayWidget from "./init/init_activeTermDisplayWidget.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_swup_content_replace from "./eventbusHandlers/ebh_swup_content_replace.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode";
import stChH_activeTermWidgetState from "./stateChangeHandlers/stChH_activeTermWidgetState";

import fetch_termText from "./api/fetch_termText.js";
import show_widget from "./api/show_widget.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logStyles = {
  default: "#4361BC",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00ff00",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default class GlossaryLinks {
  ///////////////////////////////////////// Constructor //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: { run_withLogs: false, logStyles: logStyles },
      customHandlers: { stateChange: [] },
    },
    OPTIONS
  ) {
    ///////////////////////// Config. //////////////////////////
    ////////////////////////////////////////////////////////////

    this.config = {
      customHandlers: CONFIG?.customHandlers ?? { stateChange: [] },
    };

    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "GlossaryLinks",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
      breakpoints: {
        mobile: 300,
        tablet: 700,
        desktop_sm: 1200,
        desktop_med: 1400,
      },
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_glossaryLinks = init_glossaryLinks.bind(this);
    this.init_activeTermDisplayWidget = init_activeTermDisplayWidget.bind(this);

    ///////////////////////// Modules //////////////////////////
    ////////////////////////////////////////////////////////////

    this.modules = {};

    /////////////////////////// API ////////////////////////////
    ////////////////////////////////////////////////////////////

    this.api = {
      fetch_termText: fetch_termText.bind(this),
      show_widget: show_widget.bind(this),
    };

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);

    ////////////////// State-change listeners //////////////////
    ////////////////////////////////////////////////////////////

    this.stChH_mode = stChH_mode.bind(this);
    this.stChH_activeTermWidgetState = stChH_activeTermWidgetState.bind(this);
  }

  ///////////////////////////////////////////// Init. ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// Base init. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_state();
    this.init_eventbus();
    this.init_activeTermDisplayWidget();
    this.init_glossaryLinks();
    this.setState({ initialised: true, mode: "scrollEnabled" });
  }

  ///////////////////////////// States init. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_state() {
    this.logger("init", ["state"], "default", { inline: true });
    const { breakpoints } = this.options;
    const w_viewport = window.innerWidth;
    this.setState({
      features: { mouse: window.matchMedia("(pointer:fine)").matches },
      is_mobile: w_viewport < breakpoints.tablet,
      is_tablet: w_viewport >= breakpoints.tablet && w_viewport < breakpoints.desktop_sm,
      is_desktop_sm: w_viewport >= breakpoints.desktop_sm,
      is_desktop_md: w_viewport >= breakpoints.desktop_med,
    });
  }

  //////////////////////////// Eventbus init. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    //////////// Listener registration /////////////
    ////////////////////////////////////////////////

    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);

    //////////// API call registration /////////////
    ////////////////////////////////////////////////

    // ...
  }

  /////////////////////////////////////// State management ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    if ("mode" in CHANGES) this.stChH_mode(CHANGES);
    if ("activeTermWidgetState" in CHANGES) this.stChH_activeTermWidgetState(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
