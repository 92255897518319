// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.scrollTo_section", inline: true };
const msgs = {
  start: ["PartiForm", "API", "scrollTo_section"],
  end: ["PartiForm", "API", "scrollTo_section", "API call complete"],
  no_sectionId: ["PartiForm", "API", "scrollTo_section", "no section ID provided"],
  no_fullpageInst: ["PartiForm", "API", "scrollTo_section", "no fullpage inst. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function scrollTo_section(SECTIONID, CALLBACK = null) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const fullpageInst = this.modules.fullpage.instance;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!valid_str(SECTIONID)) return this.logger("error", msgs.noSectionID, "error", logArgs);
  if (!fullpageInst) return this.logger("error", msgs.no_fullpageInst, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Scroll to section group...
  fullpageInst.moveTo(SECTIONID);

  // Callback...
  if (CALLBACK) CALLBACK();

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.end, "event", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
