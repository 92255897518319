// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { ScrollTrigger } from "gsap/ScrollTrigger";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTrigger_gameSelectSection", inline: true };
const logArgs_wildcard = { eventName: "*", inline: true };
const msgs = {
  start: ["init. scrollTrigger_gameSelectSection"],
  end: ["init. scrollTrigger_gameSelectSection", "init. success"],
  no_sectionEl: ["no valid section el. found"],
  no_subsectionEl: ["no valid subsection el. found"],
  no_overflowEl: ["no valid overflow el. found"],
  stop_mobile: ["scrollTrigger_gameSelectSection", "stop. on mobile"],
  on_enter: ["PartiForm", "scrolling into game selection section"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTrigger_gameSelectSection() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { sections, subsections } = this.ref;
  const section_intro = sections?.find((el) => el.dataset.id === "intro");
  const subsection_gameSelect = subsections?.find((el) => el.dataset.id === "gameSelection");
  const overflowWrapperEL = section_intro?.querySelector(".fp-overflow");
  const { sectionHeadlines } = this.options;
  const sectionHdl_gameSelect = valid_str(sectionHeadlines?.gameSelection) ? sectionHeadlines.gameSelection : null;
  const sectionHdl_intro = valid_str(sectionHeadlines?.intro) ? sectionHeadlines.intro : "Intro";
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(section_intro)) return this.cancel_featInit(msgs.no_sectionEl);
  if (!this.validate_refEl(subsection_gameSelect)) return this.cancel_featInit(msgs.no_subsectionEl);
  if (!this.validate_refEl(overflowWrapperEL)) return this.cancel_featInit(msgs.no_overflowEl);

  //////////////////////////////// Scroll trigger creation ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const scrollTrigger_gameSelect = ScrollTrigger.create({
    trigger: subsection_gameSelect,
    scroller: overflowWrapperEL,
    start: "top center",
    end: "bottom center",

    /////////////////////////////// On enter ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    onEnter: () => {
      // - Stop, if on mobile...
      // - Log...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);
      this.logger("event", msgs.on_enter, "event", logArgs_wildcard);

      // Set form header title to game selection section headline (if avail., otherwise set back to intro section hdl.)...
      this.api.updt_formHeaderTitle(sectionHdl_gameSelect || sectionHdl_intro);
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.modules.scrollTriggers.gameSelect = scrollTrigger_gameSelect;
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
