// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_enterMatrixAnswerGraphBtn_click", inline: true };
const msgs = {
  start: ["enter-matrix-answer-graph-btn was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_enterMatrixAnswerGraphBtn_click() {
  // Setup...
  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////// Matrix answer-graph enter. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - set mode to 'entering_answerGraph'...
  // - reveal + scroll to next section group...
  // - set mode to 'viewingMatrixAnswerGraph'
  // - set currentStep to 'viewMatrixAnswerGraph'...
  this.setState({ mode: "entering_answerGraph" });
  api.show_section("matrixAnswerGraph");
  api.scrollTo_section("matrixAnswerGraph", () => console.log("scrolling to answer graph..."));
  setTimeout(() => {
    this.setState({
      mode: "viewingMatrixAnswerGraph",
      currentStep: "viewMatrixAnswerGraph",
    });
  }, 700); // ← let scroll anim. finish (to do: promise based solution)
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
