// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_ctaPopup", inline: true };
const msgs = {
  start: ["init. ctaPopup"],
  end: ["init. ctaPopup", "init. success"],
  no_el: ["init. ctaPopup", "no valid DOM element(s) provided"],
  noRelatedModal: ["init. ctaPopup", "no related modal element found"],
  noOpenModalBtn: ["init. ctaPopup", "no open-modal button found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_ctaPopup(CARDEL) {
  // Setup...
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(msgs.no_el);

  /////////////////
  // Modal init. //
  /////////////////

  // Get related modal el...
  const relatedModalEl = this.get_cardRelatedModalEl(CARDEL);
  if (!relatedModalEl) return this.cancel_featInit(msgs.noRelatedModal);

  // Get open-modal btn. el...
  const openModalBtn = CARDEL.querySelector('[data-role="openModalBtn"]');
  if (!openModalBtn) return this.cancel_featInit(msgs.noOpenModalBtn);

  // Open/close modal hdl...
  openModalBtn.addEventListener("click", () => relatedModalEl.showModal());
  relatedModalEl.addEventListener("click", (e) => (e.target === relatedModalEl ? relatedModalEl.close() : null));

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
