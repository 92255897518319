// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_bgIllus", inline: true };
const msgs = {
  start: ["init. bgIllus"],
  end: ["init. bgIllus", "init. success"],
  no_el: ["init. bgIllus", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_bgIllus() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { bgIllus } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (bgIllus === undefined) return this.cancel_featInit(msgs.no_el);
  if (bgIllus.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// Action ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Set init. backgr. illu. states...
  const bgIlluStates = bgIllus.map((el) => ({
    el,
    id: el.dataset.id,
    is_hidden: el.dataset.isHidden === "true",
  }));
  this.setState({ bgIlluStates });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
