// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.filter_eventCards", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "filter_eventCards"],
  end: ["EventCalendar", "API", "filter_eventCards", "API call complete"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function filter_eventCards(FILTERS_month, FILTERS_context) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { eventCards } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Combine active month and context filters...
  const activeFilters = [...new Set([...FILTERS_month, ...FILTERS_context])];

  // Check 'all' filters for month and context...
  const showAll_month = FILTERS_month.length === 1 && FILTERS_month.includes("all");
  const showAll_context = FILTERS_context.length === 1 && FILTERS_context.includes("all");
  const showAll = activeFilters.length === 1 && activeFilters.includes("all");

  // If only active filter is 'all', show all event cards...
  if (showAll) {
    eventCards.forEach((el) => el.setAttribute("data-is-hidden", false));
  }
  // Hide all event cards with month or context not included in activeFilters...
  else {
    eventCards.forEach((el) => {
      const is_hidden_month = !showAll_month && !FILTERS_month.includes(el.dataset.monthFilter);
      const is_hidden_context = !showAll_context && !FILTERS_context.includes(el.dataset.context);
      const is_hidden = is_hidden_month || is_hidden_context;
      el.setAttribute("data-is-hidden", is_hidden);
    });
  }

  // Scroll to top of page...
  if (this.state.mode != "init") eventbus.emit("WindowScroll.api.scroll_toTopOfPage", { duration: 1250 });
  //  ^^^^^^^^^^^^^^^^^^^^^^^^^
  //  ↳ prevent anim. scroll to top of page on initial page load

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
