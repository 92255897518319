// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_commentsHidden", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`comments hidden: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_commentsHidden(CHANGES) {
  if (!("commentsHidden" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { commentsHidden } = CHANGES;
  const { showCommentsBtn, commmentsWrapper } = this.ref;
  this.logger("state-change", msgs.stateChange(commentsHidden), "default", logArgs);

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Show/hide comments...
  commmentsWrapper.setAttribute("data-is-hidden", commentsHidden);

  // Toggle show-comments btn. active attr...
  showCommentsBtn.setAttribute("data-is-active", !commentsHidden);

  ////////////// [some attr.] updt. //////////////
  ////////////////////////////////////////////////

  // [action]...
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
