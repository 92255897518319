// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_formHeaderState", inline: true };
const msgs = {
  change: (CHANGE) => [`form header state:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_formHeaderState(CHANGES) {
  if (!("formHeaderState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const formHeaderState = { ...this.state?.formHeaderState, ...CHANGES.formHeaderState };
  const { floatingFormHeader } = this.ref;
  const { is_hidden, titleText } = formHeaderState;
  const titleWrapper = floatingFormHeader.querySelector("[data-ref='titleWrapper']");
  this.logger("state-change", msgs.change(formHeaderState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////// hidden attr. updt. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in formHeaderState) floatingFormHeader.setAttribute("data-is-hidden", is_hidden);

  /////////////////////////// title text updt. ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("titleText" in formHeaderState && titleText != null && titleWrapper) {
    // Find pill el. to contain title text...
    const titlePill = titleWrapper.querySelector("[g-ref='pills']");
    const pillTextEl = titlePill.querySelector("[data-ref='textEl']");

    // Guard: stop updt. if curr. text matches new text...
    if (pillTextEl.textContent.trim() === titleText) return;

    // Update text content:
    // - Hide header...
    // - Update text...
    // - Show header...
    if (pillTextEl) {
      floatingFormHeader.setAttribute("data-is-hidden", "true");
      setTimeout(() => {
        pillTextEl.textContent = titleText;
        floatingFormHeader.setAttribute("data-is-hidden", "false");
      }, 500);
    }
  }

  ///////////////////// title height CSS var. updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("titleHeight" in formHeaderState && titleWrapper) {
    const { titleHeight } = formHeaderState;
    this.element.style.setProperty("--h-floatingTitle", `${titleHeight}px`);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
