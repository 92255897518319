// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";
import format_cardData from "./util/format_cardData.js";
import get_highestCardZIndex from "./util/get_highestCardZIndex.js";
import get_cardFollowerEl from "./util/get_cardFollowerEl.js";
import get_cardRelatedModalEl from "./util/get_cardRelatedModalEl.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_scrollTriggers from "./init/init_scrollTriggers.js";
import init_surveyForm from "./init/init_surveyForm.js";
import init_cardFollower from "./init/init_cardFollower.js";
import init_cardCommentBtn from "./init/init_cardCommentBtn.js";
import init_ctaPopup from "./init/init_ctaPopup.js";
import init_filterMenu from "./init/init_filterMenu.js";
// import init_audioPlayer from "./init/init_audioPlayer.js"; // NOT FULLY IMPLEMENTED YET
import init_cards from "./init/init_cards.js";
import init_marquee from "./init/init_marquee.js";
import init_cardSlideShow from "./init/init_cardSlideShow.js";
import init_commentWidget from "./init/init_commentWidget.js";
import init_cardRotation from "./init/init_cardRotation.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_windowScroll_stCh_scrollPosition from "./eventbusHandlers/ebh_windowScroll_stCh_scrollPosition.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_cardStates from "./stateChangeHandlers/stChH_cardStates.js";
import stChH_commentWidgetState from "./stateChangeHandlers/stChH_commentWidgetState.js";
import stChH_activeCardFilters from "./stateChangeHandlers/stChH_activeCardFilters.js";
import stChH_cardDragEnabled from "./stateChangeHandlers/stChH_cardDragEnabled.js";
import stChH_prevVpWidth from "./stateChangeHandlers/stChH_prevVpWidth.js";
import stChH_filterMenuState from "./stateChangeHandlers/stChH_filterMenuState";

import filter_cards from "./api/filter_cards.js";
import distribute_cards from "./api/distribute_cards.js";
import move_cardIntoViewport from "./api/move_cardIntoViewport.js";

import updt_cardFollowerPos from "./api/updt_cardFollowerPos.js";
import hide_cardFollower from "./api/hide_cardFollower.js";
import show_cardFollower from "./api/show_cardFollower.js";

import expand_card from "./api/expand/expand_card.js";
import expand_cardBg from "./api/expand/expand_cardBg.js";
import expand_cardBorder from "./api/expand/expand_cardBorder.js";
import expand_cardClipPath from "./api/expand/expand_cardClipPath.js";

import contract_card from "./api/contract/contract_card.js";
import contract_cardBg from "./api/contract/contract_cardBg.js";
import contract_cardBorder from "./api/contract/contract_cardBorder.js";
import contract_cardClipPath from "./api/contract/contract_cardClipPath.js";

import disable_cardDrag from "./api/disable_cardDrag.js";
import disable_cardDrag_all from "./api/disable_cardDrag_all.js";
import enable_cardDrag_all from "./api/enable_cardDrag_all.js";

import show_commentWidget from "./api/show_commentWidget.js";
import hide_commentWidget from "./api/hide_commentWidget.js";
import updt_commentWidgetPos from "./api/updt_commentWidgetPos.js";

import fetch_postComments from "./api/fetch_postComments.js";
import submit_comment from "./api/submit_comment.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).
const manualOptionKeys = ["mobilebreakpoint"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).
const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles
const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class DragCards extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      cardGroupAreas: [],
      cards: [],
      cardFollowers: [],
      filterMenuWrapper: null,
      filterMenu: null,
      openFilterMenuBtn: null,
      filterBtns: [],
      commentWidget: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "DragCards",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      breakpoints: {
        tablet_prt: 700,
        tablet_ls: 1000,
        desktop_sm: 1200,
      },
      //////
      run_withLogs: true,
      logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.format_cardData = format_cardData.bind(this);
    this.get_highestCardZIndex = get_highestCardZIndex.bind(this);
    this.get_cardFollowerEl = get_cardFollowerEl.bind(this);
    this.get_cardRelatedModalEl = get_cardRelatedModalEl.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_scrollTriggers = init_scrollTriggers.bind(this);
    this.init_cardRotation = init_cardRotation.bind(this);
    this.init_cardSlideShow = init_cardSlideShow.bind(this);
    this.init_cardCommentBtn = init_cardCommentBtn.bind(this);
    this.init_commentWidget = init_commentWidget.bind(this);
    this.init_surveyForm = init_surveyForm.bind(this);
    this.init_cardFollower = init_cardFollower.bind(this);
    this.init_ctaPopup = init_ctaPopup.bind(this);
    this.init_filterMenu = init_filterMenu.bind(this);
    this.init_marquee = init_marquee.bind(this);
    // this.init_audioPlayer = init_audioPlayer.bind(this); // NOT FULLY IMPLEMENTED YET
    this.init_cards = init_cards.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      cards: { instances: [] },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      move_cardIntoViewport: move_cardIntoViewport.bind(this),
      contract_cardBorder: contract_cardBorder.bind(this),
      contract_cardClipPath: contract_cardClipPath.bind(this),
      contract_cardBg: contract_cardBg.bind(this),
      expand_cardBorder: expand_cardBorder.bind(this),
      expand_cardClipPath: expand_cardClipPath.bind(this),
      expand_cardBg: expand_cardBg.bind(this),
      submit_comment: submit_comment.bind(this),
      fetch_postComments: fetch_postComments.bind(this),
      hide_commentWidget: hide_commentWidget.bind(this),
      updt_commentWidgetPos: updt_commentWidgetPos.bind(this),
      show_commentWidget: show_commentWidget.bind(this),
      updt_cardFollowerPos: updt_cardFollowerPos.bind(this),
      hide_cardFollower: hide_cardFollower.bind(this),
      show_cardFollower: show_cardFollower.bind(this),
      contract_card: contract_card.bind(this),
      enable_cardDrag_all: enable_cardDrag_all.bind(this),
      disable_cardDrag_all: disable_cardDrag_all.bind(this),
      disable_cardDrag: disable_cardDrag.bind(this),
      expand_card: expand_card.bind(this),
      distribute_cards: distribute_cards.bind(this),
      filter_cards: filter_cards.bind(this),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_windowScroll_stCh_scrollPosition = ebh_windowScroll_stCh_scrollPosition.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_prevVpWidth = stChH_prevVpWidth.bind(this);
    this.stChL_commentWidgetState = stChH_commentWidgetState.bind(this);
    this.stChL_cardDragEnabled = stChH_cardDragEnabled.bind(this);
    this.stChL_activeCardFilters = stChH_activeCardFilters.bind(this);
    this.stChL_cardStates = stChH_cardStates.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_filterMenuState = stChH_filterMenuState.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ////// Eventbus listener de-registration ///////
    ////////////////////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);

    /////////// API call de-registration ///////////
    ////////////////////////////////////////////////

    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_states();
    this.init_cards();
    this.init_commentWidget();
    this.init_filterMenu();
    if (this.state.is_mobile) this.init_scrollTriggers();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    const { breakpoints } = this.options;
    const { innerWidth: w_viewport } = window;
    this.setState({
      activeCardFilters: ["all"],
      prevVpWidth: window.innerWidth,
      is_mobile: w_viewport < breakpoints.tablet_prt,
      is_tablet_prt: w_viewport >= breakpoints.tablet_prt && w_viewport < breakpoints.tablet_ls,
      is_tablet_ls: w_viewport >= breakpoints.tablet_ls && w_viewport < breakpoints.desktop_sm,
      is_desktop_sm: w_viewport >= breakpoints.desktop_sm,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    //////////// Listener registration /////////////
    ////////////////////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);

    //////////// API call registration /////////////
    ////////////////////////////////////////////////

    // ...
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("prevVpWidth" in CHANGES) this.stChL_prevVpWidth(CHANGES);
    if ("commentWidgetState" in CHANGES) this.stChL_commentWidgetState(CHANGES);
    if ("cardDragEnabled" in CHANGES) this.stChL_cardDragEnabled(CHANGES);
    if ("activeCardFilters" in CHANGES) this.stChL_activeCardFilters(CHANGES);
    if ("cardStates" in CHANGES) this.stChL_cardStates(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
    if ("filterMenuState" in CHANGES) this.stChL_filterMenuState(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default DragCards;
