// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getWidth, getHeight } from "mezr";
import { gsap } from "gsap";

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_cardExpandType(CARDEL, CONFIG) {
  return CARDEL.hasAttribute("data-expand-type") ? CARDEL.getAttribute("data-expand-type") : CONFIG.type;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.expand_card", inline: true };
const msgs = {
  start: ["DragCards", "API", "expand_card"],
  end: ["PartiForm", "API", "expand_card", "API call complete"],
  no_cardEl: ["DragCards", "API", "expand_card", "no valid card el. provided"],
};

const defConfig = {
  duration: 0.333,
  ease: "power2.inOut",
  type: "width-height",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function expand_card(CARDEL, CONFIG = defConfig) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  // Config. //
  const config = { ...defConfig, ...CONFIG };
  const { duration, ease } = config;
  const type = get_cardExpandType(CARDEL, config); // ← determ. expand. anim. type

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(msgs.no_cardEl);

  ////////////////////////// Anim. card expand. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Guard:
  // - stop, if card is already expanded...
  // - stop, if card is currently animating...
  // - stop, if card is currently contracting...
  if (CARDEL.getAttribute("data-is-expanded") === "true") return;
  if (CARDEL.getAttribute("data-is-animating") === "true") return;
  if (CARDEL.getAttribute("data-is-contracting") === "true") return;

  // Set card as animating & expanded...
  CARDEL.setAttribute("data-is-animating", "true");
  CARDEL.setAttribute("data-is-expanded", "true");

  // Get org. card dimensions & store in el. data attr...
  const w_card = getWidth(CARDEL);
  const h_card = getHeight(CARDEL);
  CARDEL.setAttribute("data-w-org", w_card);
  CARDEL.setAttribute("data-h-org", h_card);

  // Randomize card size...
  const scaleFactor_w = { min: 1.15, max: 1.3 };
  const scaleFactor_h = { min: 1.15, max: 1.3 };
  const randScaleFactor_width = scaleFactor_w.min + Math.random() * (scaleFactor_w.max - scaleFactor_w.min);
  const randScaleFactor_height = scaleFactor_h.min + Math.random() * (scaleFactor_h.max - scaleFactor_h.min);
  const w_card_expanded = w_card * randScaleFactor_width;
  const h_card_expanded = h_card * randScaleFactor_height;

  // Prep. anim. config...
  const animConfig = {};
  if (type === "width-height") {
    animConfig.width = w_card_expanded;
    animConfig.height = h_card_expanded;
  } else if (type === "scale") {
    animConfig.scaleX = randScaleFactor_width;
    animConfig.scaleY = randScaleFactor_height;
  }

  // Apply rand. size to card el...
  gsap.to(CARDEL, {
    ...animConfig,
    duration,
    ease,
    onComplete: () => {
      /////////// is-animating attr. reset ///////////
      ////////////////////////////////////////////////

      CARDEL.setAttribute("data-is-animating", "false");

      /////////// Card follower pos. updt. ///////////
      ////////////////////////////////////////////////

      if (CARDEL.dataset.withFollower === "true")
        api.updt_cardFollowerPos(CARDEL, { positioningScheme: CARDEL.dataset.followerPos || "below" });
    },
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
