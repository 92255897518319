// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.get_foo", inline: true, disabled: false };
const msgs = {
  start: ["Util.", "get", "get_elementMeasurements"],
  end: ["Util.", "get", "get_elementMeasurements", "Util. call complete"],
  no_el: ["Util.", "get", "get_elementMeasurements", "no el. provided"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_elementMeasurements(EL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  globalLogger.log("event", msgs.start, "event", logArgs);

  // Guard...
  if (!EL) return globalLogger.log("warning", msgs.no_el, "warning", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Measure el...
  const elRect = EL.getBoundingClientRect();
  const measurements = {
    w: elRect.width,
    h: elRect.height,
    l: elRect.left,
    r: elRect.right,
    t: elRect.top,
    b: elRect.bottom,
  };

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  globalLogger.log("success", msgs.end, "success", logArgs);
  return measurements;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
