// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_siteTitle_click", inline: true };
const msgs = {
  start: ["site title was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_siteTitle_click() {
  // Setup...
  this.logger("event", msgs.start, "event", logArgs);

  // Toggle site mode ('campus-trifft-stadt' ←→ 'campus-trifft-stadt')...
  const body = document.body;
  const mode_current = body.getAttribute("data-mode");
  const mode_new = mode_current === "campus-trifft-stadt" ? "stadt-trifft-campus" : "campus-trifft-stadt";
  body.setAttribute("data-mode", mode_new);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
