// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * cancel_featureInit
 *
 * @param {string} MESSAGE  - The error message to log.
 * @param {object} ARGS_log - The arguments to pass to the logger.
 * @returns {boolean}       - Returns false.
 *
 * @description - Util. function to cancel the initialization of a component feature by logging an
 *                error message and returning false.
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function cancel_featureInit(MESSAGE, ARGS_log) {
  this.logger("error", MESSAGE, "error", { ...logArgs, ...ARGS_log });
  return false;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
