// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";
import { getHeight } from "mezr";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import interpol_HSLcolorBrightness from "../util/interpolate_HSLcolorBrightness";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTrigger_futureMatrixSection", inline: true };
const msgs = {
  start: ["init. scrollTrigger_futureMatrixSection"],
  end: ["init. scrollTrigger_futureMatrixSection", "init. success"],
  no_sectionEl: ["no valid section el. found"],
  no_subsectionEl: ["no valid subsection el. found"],
  no_overflowEl: ["no valid overflow el. found"],
  stop_mobile: ["scrollTrigger_futureMatrixSection", "stop. on mobile"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTrigger_futureMatrixSection() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { sections, subsections, matrixQuestions } = this.ref;
  const section_futureMatrix = sections?.find((el) => el.dataset.id === "futureMatrix");
  const subsection_futureMatrixGame = subsections?.find((el) => el.dataset.id === "futureMatrixGame");
  const overflowWrapperEL = section_futureMatrix?.querySelector(".fp-overflow");
  const pageEl = document.querySelector("main");
  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(section_futureMatrix)) return this.cancel_featInit(msgs.no_sectionEl);
  if (!this.validate_refEl(subsection_futureMatrixGame)) return this.cancel_featInit(msgs.no_subsectionEl);
  if (!this.validate_refEl(overflowWrapperEL)) return this.cancel_featInit(msgs.no_overflowEl);

  /////////////////////////////// Intro-subsection scroll trigger ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const subSection_futureMatrixIntro = subsection_futureMatrixGame.querySelector("[data-ref='subSection_intro']");

  if (subSection_futureMatrixIntro) {
    const scrollTriggerInst_futureMatrix_intro = ScrollTrigger.create({
      trigger: subSection_futureMatrixIntro,
      scroller: overflowWrapperEL,
      start: "top top",
      end: "bottom bottom",

      ////////////////////////////// On update ///////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      onUpdate: (EVENTDATA) => {
        // Stop, if on mobile...
        if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

        // Use progress to gradually fade page backgr. color to white...
        const { progress } = EVENTDATA;
        const color = { hue: 18.84, saturation: 100, brightness: 59.41 };
        const color_curr = interpol_HSLcolorBrightness({ ...color, brightness: 100 }, progress, color.brightness);
        pageEl.style.backgroundColor = color_curr;

        // When scrolled 10% into intro section, hide intro bg. illu...
        if (progress > 0.1) api.hide_bgIllu("intro");
        else api.show_bgIllu("intro");

        // Updt. page header headline-stretch (via eventbus)...
        eventbus.emit("windowScroll_stCh_scrollPosition", { scrollPercentage: progress * 100 });
      },
    });

    this.modules.scrollTriggers.futureMatrix_intro = scrollTriggerInst_futureMatrix_intro;
  }

  ///////////////////////////// Questions-subsection scroll trigger //////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const subSection_questions = subsection_futureMatrixGame.querySelector("[data-ref='subSection_questions']");

  if (subSection_questions) {
    const scrollTriggerInst_futureMatrix_questions = ScrollTrigger.create({
      trigger: subSection_questions,
      scroller: overflowWrapperEL,
      start: "top bottom",
      end: "bottom bottom",

      ////////////////////////////// On update ///////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      onUpdate: (EVENTDATA) => {
        // Stop, if on mobile...
        if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

        ////// Page header hide/reveal on scroll ///////
        ////////////////////////////////////////////////

        // Calc. & use scrolled pixels to hide/show page header...
        const { start, end, progress } = EVENTDATA;
        const dir = EVENTDATA.direction === 1 ? "down" : "up";
        if (pageHeaderEl) {
          const scrolledPixels = Math.round(progress * (end - start));
          const h_header = getHeight(pageHeaderEl) + 10; // ← 10px extra padding

          // Reset page header hidden-offscreen attr. when scrolling back up...
          if (dir === "up" && scrolledPixels < h_header) pageHeaderEl.setAttribute("data-hidden-offscreen", "false");

          // Progressively hide/reveal page header in synch. with scroll...
          if (scrolledPixels <= h_header) pageHeaderEl.style.transform = `translateY(${-scrolledPixels}px)`;
          else {
            pageHeaderEl.style.transform = `translateY(${-h_header}px)`;
            pageHeaderEl.setAttribute("data-hidden-offscreen", "true");
          }
        }
      },
    });

    this.modules.scrollTriggers.futureMatrix_questionsSection = scrollTriggerInst_futureMatrix_questions;
  }

  //////////////////////////////// Matrix question scroll trigger ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (matrixQuestions && matrixQuestions.length > 0) {
    matrixQuestions.forEach((mqEl) => {
      const scrollTrigger_question = ScrollTrigger.create({
        trigger: mqEl,
        scroller: overflowWrapperEL,
        start: "top bottom",
        end: "bottom bottom",

        ////////////////////////////// On update ///////////////////////////////
        ////////////////////////////////////////////////////////////////////////

        onUpdate: (EVENTDATA) => {
          // Stop, if on mobile...
          if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

          ///// Question answer-input move-up anim. //////
          ////////////////////////////////////////////////

          // Use progress to gradually move up answer-input wrapper...
          const { progress } = EVENTDATA;
          const inputWrapper = mqEl.querySelector("[data-ref='answerInputWrapper']");
          if (inputWrapper) {
            const questionWrapperStartPos = window.innerHeight / 2;
            const questionWrapperEndPos = 0;
            const questionWrapperCurrPos = gsap.utils.interpolate(questionWrapperStartPos, questionWrapperEndPos, progress);
            inputWrapper.style.transform = `translateY(${questionWrapperCurrPos}px)`;
          }
        },
      });

      this.modules.scrollTriggers.futureMatrix_questions.push(scrollTrigger_question);
    });
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
