// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_stateChange", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`is_hidden: ${CHANGE}`],
  no_change: ["No change to state provided."],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_is_hidden(CHANGES) {
  if (!("is_hidden" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_hidden } = CHANGES;
  this.logger("state-change", msgs.change(is_hidden), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.element.setAttribute("data-is-hidden", is_hidden ? "true" : "false");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
