// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getWidth } from "mezr/getWidth";
import { getHeight } from "mezr/getHeight";
import { getIntersection } from "mezr/getIntersection";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const calc_intersectRatio = (INTERSECTION, WIDTH_el, HEIGHT_el) => {
  if (!INTERSECTION) return 0;
  const { width: w_intersect, height: h_intersect } = INTERSECTION;
  const intersectArea = w_intersect * h_intersect;
  const intersectRatio = intersectArea / (WIDTH_el * HEIGHT_el);
  return intersectRatio;
};

function gen_cardXPos(MAX_X, MIN_X) {
  return MIN_X + Math.random() * (MAX_X - MIN_X);
}

function gen_cardYPos(IS_first, MAX_Y, MIN_Y) {
  if (IS_first) return MIN_Y + Math.random() * (MAX_Y - MIN_Y);
  return Math.random() * MAX_Y;
}

function updt_cardElPos(CARDEL, X, Y) {
  const currentTransform = CARDEL.style.transform;
  const rotateTransform = currentTransform.match(/rotate\([^)]+\)/);
  const rotation = rotateTransform ? rotateTransform[0] : "";
  CARDEL.style.transform = `translate(${X}px, ${Y}px) ${rotation}`;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.distribute_cards", inline: true };
const msgs = {
  start: ["API", "distribute_cards"],
  end: ["API", "distribute_cards", "API call complete"],
  no_cardEls: ["API", "distribute_cards", "no card elements found"],
  cardIntersecting: ["API", "distribute_cards", "card is intersecting", "attempting to re-pos."],
};

const defConfig = {
  max_tries_posGen: 1000,
  max_intersectRatio: {
    tablet_prt: 0.45,
    tablet_ls: 0.425,
    desktop_sm: 0.4,
    default: 0.3,
  },
  padding_x: {
    tablet_prt: 15,
    tablet_ls: 30,
    desktop_sm: 60,
    default: 80,
  },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function distribute_cards_proto(CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { cardGroupAreas } = this.ref;
  const { is_tablet_prt, is_tablet_ls, is_desktop_sm } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////////////////// Per-group card distribution /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  cardGroupAreas.forEach((area) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const cardEls = area.querySelectorAll('[g-ref="DragCards:cards"]');
    if (!cardEls.length) return this.logger("warning", msgs.no_cardEls, "warning", logArgs);

    // Check if area is first or last in group...
    const is_first = area === cardGroupAreas[0];
    const is_last = area === cardGroupAreas[cardGroupAreas.length - 1];

    // Determ. area width/height...
    const w_area = getWidth(area);
    const h_area = !is_first && !is_last ? getHeight(area) * 1.2 : getHeight(area);

    /////////////////////////// Random position. ///////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Extract config values...
    const { max_tries_posGen, max_intersectRatio, padding_x } = config;
    const key_toUse = is_tablet_prt ? "tablet_prt" : is_tablet_ls ? "tablet_ls" : is_desktop_sm ? "desktop_sm" : "default";
    const padding_x_toUse = padding_x[key_toUse] || padding_x["default"];
    const max_intersectRatio_toUse = max_intersectRatio[key_toUse] || max_intersectRatio["default"];

    // Randomly distribute cards within group area...
    cardEls.forEach((cardEl) => {
      //////////////////// Setup /////////////////////
      ////////////////////////////////////////////////

      // - Move card into view...
      // - Determ. card width/height...
      // - Init. x/y pos. vars...
      cardEl.setAttribute("data-position-init", "true");
      const w_card = getWidth(cardEl);
      const h_card = getHeight(cardEl);
      let x, y;

      ////////////////// Pos. gen. ///////////////////
      ////////////////////////////////////////////////

      // Determ. min./max. x/y pos. values...
      const minY = is_first ? 100 : 0; // ← ensure min. dist. to top edge (first area only)
      const minX = padding_x_toUse; // ← ensure min. dist. to left edge
      const maxX = w_area - w_card - padding_x_toUse; // ← ensure min. dist. to right edge
      const maxY = h_area - h_card;

      // Gen. random position...
      x = gen_cardXPos(maxX, minX);
      y = gen_cardYPos(is_first, maxY, minY);

      // Apply position to card el...
      updt_cardElPos(cardEl, x, y);

      ////////////// Intersect. resolve //////////////
      ////////////////////////////////////////////////

      let positionFound = false;
      let tries = 0;
      while (!positionFound && tries < max_tries_posGen) {
        // Check for intersection with any other existing card els...
        let is_intersecting = false;
        const otherCardEls = [...cardEls].filter((el) => el !== cardEl);
        otherCardEls.forEach((otherCardEl) => {
          const intersect = getIntersection(cardEl, otherCardEl);
          const intersectRatio = intersect ? calc_intersectRatio(intersect, w_card, h_card) : 0;
          if (intersectRatio > max_intersectRatio_toUse) is_intersecting = true;
        });

        // If intersecting, reposition card (gen. new x/y)...
        if (is_intersecting) {
          this.logger("warning", msgs.cardIntersecting, "warning", logArgs);
          x = gen_cardXPos(maxX, minX);
          y = gen_cardYPos(is_first, maxY, minY);
          updt_cardElPos(cardEl, x, y);
        }

        // - If no intersection, mark position as found...
        // - Incr. tries...
        if (!is_intersecting) positionFound = true;
        tries++;
      }
    });
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
