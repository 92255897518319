// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const msgs = {
  start: ["PartiForm", "API", "refresh_canvasSize"],
  noFabricInst: ["no fabric inst. available"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function refresh_canvasSize() {
  // Setup...
  const fabricInst = this.modules.fabric.instance;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!fabricInst) return this.logger("warning", msgs.noFabricInst, "warning", logArgs);

  // Updt. canvas size...
  const { drawingCanvasWrapper } = this.ref;
  fabricInst.setWidth(drawingCanvasWrapper.offsetWidth);
  fabricInst.setHeight(drawingCanvasWrapper.offsetHeight);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
