// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import log from "./log";

// —————————————————————————————————————————— fn() DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function logger(TYPE, MESSAGE, STYLE, OPTIONS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compOpts = this?.options ? this.options : OPTIONS ? OPTIONS : null;
  if (!compOpts) {
    console.warn("no options available to logger call, aborting call");
    return null;
  }
  const compName = compOpts.name ? compOpts.name : this.name ? this.name : null;
  const { disabled } = OPTIONS ? OPTIONS : { disabled: false };
  if (disabled) return null; // ← stop, if manually disabled
  const eventName = OPTIONS ? OPTIONS.eventName : null;
  const eventsToLog = compOpts.eventsToLog ? compOpts.eventsToLog : null;
  const printInLine = OPTIONS ? OPTIONS.inline : false;

  // Stop if event not to be logged...
  if (eventsToLog && !eventsToLog.includes(eventName) && eventName !== "*") return null;
  //                                                  ^^^^^^^^^^^^^^^^^^^^
  //                                                  ↳ wildcard to log general/syst. events

  // Stop if logging disabled...
  const run_withLogs = compOpts.run_withLogs;
  if (!run_withLogs || run_withLogs === "defaultValue") return null;

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Determ. message prefix based on type...
  const message = TYPE && TYPE != "" && !["mount", "unmount", "info"].includes(TYPE) ? [TYPE, ...MESSAGE] : [...MESSAGE];

  // Get log. style from comp. options...
  const logStyles = compOpts.logStyles;
  const logStyle_toUse = logStyles[STYLE ? STYLE : "default"];

  // Print message...
  log(compName, message, logStyle_toUse, printInLine, TYPE);

  /////////////////////////////////////////// CONCLUDE ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  return null;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default logger;
