// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "./lib/jGia/jGia/src/eventbus";

// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Modules //
import JGiaAppModule from "./modules/baseAppModules/jGia";
import swupAppModule from "./modules/baseAppModules/appMod-swup";
import LazyLoaderAppModule from "./modules/baseAppModules/LazyLoader/LazyLoader.js";
import WindowScroll from "./modules/baseAppModules/WindowScroll/WindowScroll";
import GlossaryLinks from "./modules/GlossaryLinks/GlossaryLinks";
import PostFootnotes from "./modules/PostFootnotes/PostFootnotes";
import GlobalLogger from "./modules/baseAppModules/GlobalLogger/GlobalLogger.js";

// Components //
import ImgWidget from "../site/plugins/component-img-widget/js/ImgWidget.js";
import CookieWidget from "../site/plugins/component-cookie-widget/js/CookieWidget.js";
import DisclaimerWidget from "../site/plugins/component-disclaimer-widget/js/DisclaimerWidget.js";
import PrevNextPostWidget from "../site/plugins/component-prev-next-post-widget/js/PrevNextPostWidget.js";
import SlideShow from "../site/plugins/component-slide-show/js/SlideShow.js";
import SitePortal from "../site/plugins/component-site-portal/js/SitePortal.js";
import PartiForm from "../site/plugins/component-parti-form/js/PartiForm.js";
import GlossaryList from "../site/plugins/component-glossary-list/js/GlossaryList.js";
import TeamList from "../site/plugins/component-team-list/js/TeamList.js";
import IlluScroller from "../site/plugins/component-illu-scroller/js/IlluScroller.js";
import PageIlluDisplay from "../site/plugins/component-page-illu-display/js/PageIlluDisplay.js";
import EventCalendar from "../site/plugins/component-event-calendar/js/EventCalendar.js";
import SubpageHeader from "../site/plugins/component-subpage-header/js/SubpageHeader.js";
import ContactForm from "../site/plugins/component-contact-form/js/ContactForm.js";
import CommentSection from "../site/plugins/component-comment-section/js/CommentSection.js";
// import Modal from "../site/plugins/component-modal/js/Modal.js";
// import VideoPlayer from "../site/plugins/component-video-player/js/VideoPlayer.js";
import BgSlider from "../site/plugins/component-bg-slider/js/BgSlider.js";
import DragCards from "../site/plugins/component-drag-cards/js/DragCards.js";
// import CardArrangement from "../site/plugins/component-card-arrangement/js/CardArrangement.js"; // DEPRECATED
// import IntroSlider from "../site/plugins/component-intro-slider/js/IntroSlider.js"; // DEPRECATED
import Nav from "../site/plugins/component-nav/js/Nav.js";
// import SearchList from "../site/plugins/component-search-list/js/SearchList.js"; // INACTIVE, NOT NEEDED ATM
// import LinkList from "../site/plugins/component-link-list/js/LinkList.js"; // INACTIVE, NOT NEEDED ATM

const availableComponents = [
  // Global
  // ...

  // Local
  { type: "ImgWidget", script: ImgWidget },
  { type: "CookieWidget", script: CookieWidget },
  { type: "DisclaimerWidget", script: DisclaimerWidget },
  { type: "PrevNextPostWidget", script: PrevNextPostWidget },
  { type: "SlideShow", script: SlideShow },
  { type: "SitePortal", script: SitePortal },
  { type: "PartiForm", script: PartiForm },
  { type: "GlossaryList", script: GlossaryList },
  { type: "TeamList", script: TeamList },
  { type: "IlluScroller", script: IlluScroller },
  { type: "PageIlluDisplay", script: PageIlluDisplay },
  { type: "EventCalendar", script: EventCalendar },
  { type: "SubpageHeader", script: SubpageHeader },
  { type: "ContactForm", script: ContactForm },
  { type: "CommentSection", script: CommentSection },
  // { type: "Modal", script: Modal },
  // { type: "VideoPlayer", script: VideoPlayer },
  { type: "BgSlider", script: BgSlider },
  { type: "DragCards", script: DragCards },
  // { type: "CardArrangement", script: CardArrangement }, // DEPRECATED
  // { type: "IntroSlider", script: IntroSlider }, // DEPRECATED
  { type: "Nav", script: Nav },
  // { type: "SearchList", script: SearchList }, // INACTIVE, NOT NEEDED ATM
  // { type: "LinkList", script: LinkList }, // INACTIVE, NOT NEEDED ATM

  // Other
  // ...
];

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import consoleStyles from "./assets/consoleStyles/consoleStyles.js";
import pageTransAnim_cardExpand from "./assets/pageTransAnimations/pageTransAnim_cardExpand.js";
import pageTransAnim_fade from "./assets/pageTransAnimations/pageTransAnim_fade.js";

// —————————————————————— CUSTOM MODULE EVENT/EVENTBUS/STATE-CHANGE HANDLERS —————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_swup_page_load from "./eventHandlers/swup/eh_swup_page_load";
import eh_swup_linkClick from "./eventHandlers/swup/eh_swup_linkClick.js";
import stCh_windowScroll_scrollPosition from "./stateChangeHandlers/stCh_windowScroll_scrollPosition.js";

// Custom event handlers for swup module //
const swupEventHandlers = [
  { id: "page:load", handler: eh_swup_page_load },
  { id: "link:click", handler: eh_swup_linkClick, config: { before: true } },
];

// Custom eventbus handlers for swup module //
// const swupEventbusConfig = {
// callsToAnswer: ["document_click"],
// handlers: [{ id: "document_click", handler: () => {} }],
// };

// Custom state-change handlers for windowScroll module //
const windowScrollStChHandlers = [{ id: "scrollPosition", handler: stCh_windowScroll_scrollPosition }];

// ————————————————————————————————————————— MODULE INIT. ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

(async () => {
  // Fetch app config...
  console.log("%cFetching app config.", consoleStyles.default);
  const appConfigRequest = await fetch("/app/config.json");
  console.log("%cApp config. fetched", consoleStyles.success);
  const appConfig = await appConfigRequest.json();
  console.log("%cApp config. parsed", consoleStyles.success);
  const giaConfig = appConfig?.gia;
  const swupConfig = appConfig?.swup;
  const lazyLoaderConfig = appConfig?.lazyLoader;
  const globalComponentConfigs = appConfig?.globalComponents;
  const localComponentConfigs = appConfig?.localComponents;
  const windowScrollConfig = appConfig?.windowScroll;

  // Extend module configs...
  swupConfig.eventbus = {};
  swupConfig.eventHandlers = swupEventHandlers;
  swupConfig.useNewJGiaPlugin = true;
  swupConfig.with_jsAnimPlugin = true;
  swupConfig.pageTransAnimations = [pageTransAnim_cardExpand, pageTransAnim_fade];
  windowScrollConfig.customHandlers = { stateChange: windowScrollStChHandlers };

  // Create & collect module app module instances...
  const jGiaInst = new JGiaAppModule(giaConfig, globalComponentConfigs, { availableComponents });
  const swupInst = new swupAppModule(swupConfig, giaConfig, { availableComponents }, localComponentConfigs);
  const LazyLoaderInst = new LazyLoaderAppModule(lazyLoaderConfig);
  const WindowScrollInst = new WindowScroll(windowScrollConfig);
  const GlossaryLinksInst = new GlossaryLinks();
  const PostFootnotesInst = new PostFootnotes();
  const appModules = [jGiaInst, swupInst, LazyLoaderInst, WindowScrollInst, GlossaryLinksInst, PostFootnotesInst];

  // Include custom modules...
  // if (fooConfig) appModules.push(new Foo(fooConfig));

  // Init. app modules...
  try {
    console.log("%cInitialising app modules", "color: orange; font-weight: bold;");
    appModules.forEach((module) => {
      try {
        module.init();
      } catch (error) {
        console.error(`Module "${module.options.name}" failed to initialise.`);
        throw error;
      }
    });
    console.log("%cApp modules initialised", "color: green; font-weight: bold;");
  } catch (error) {
    console.error(error);
  }
})();

// ————————————————————————————————————————— GLOBAL STATE ————————————————————————————————————————— //
// ——————————————————————————————————————— (local storage ) ——————————————————————————————————————— //

// Feature detection //
console.log("%cDetecting features", "color: orange; font-weight: bold;");
const has_pointerEvents = window.matchMedia("(pointer: fine)").matches;
document.documentElement.setAttribute("data-has-mouse", has_pointerEvents);

// Viewport height measurement //
const measureEl_svh = document.getElementById("measure-svh");
let smallViewportHeight = window.innerHeight;
if (measureEl_svh) {
  smallViewportHeight = measureEl_svh.offsetHeight;
  measureEl_svh.remove();
}

// Create/Set global state object //
console.log("%cSetting global state", "color: orange; font-weight: bold;");
const globalState = {
  mediaIsEnabled: true,
  siteLang: document.body.dataset.lang,
  features: { has_pointerEvents },
  measurements: { svh: smallViewportHeight },
};
localStorage.setItem("globalState", JSON.stringify(globalState));

// ———————————————————————————————————————— GLOBAL LOGGER ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const globalLoggerInst = new GlobalLogger();
window.globalLogger = globalLoggerInst;

// ———————————————————————————————————————— GLOBAL EVENTS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// import ebh_Foo_bar from "./eventbusHandlers/ebh_Foo_bar";

// Event emissions //
window.addEventListener("resize", () => eventbus.emit("window_resize", { windowWidth: window.innerWidth }));
document.documentElement.addEventListener("mouseleave", () => eventbus.emit("document_mouseLeave"));
document.documentElement.addEventListener("click" || "touchstart", (e) => eventbus.emit("document_click", { eventData: e }));

// Eventbus handling //
// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
