// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: "UTIL. → calc. → calc. random el. position",
  end: "UTIL. → calc. → calc. random el. position → done",
};
const defConfig = { padding: 100 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function calc_randomElPosition(EL, CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { padding } = config;
  console.log(msgs.start);

  /////////////////////////////////////////// ACTION /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Measure viewport...
  // - Measure el...
  const { innerWidth: w_vp, innerHeight: h_vp } = window;
  const { width: w_el, height: h_el } = EL.getBoundingClientRect();

  // Calc. space available on left/right and top/bottom...
  const spaceAvail_x = w_vp - w_el;
  const spaceAvail_y = h_vp - h_el;

  // Cacl. minimum and maximum positions with padding...
  const minX = spaceAvail_x > padding ? padding : 0;
  const maxX = spaceAvail_x > padding ? w_vp - w_el - padding : w_vp - w_el;
  const minY = spaceAvail_y > padding ? padding : 0;
  const maxY = spaceAvail_y > padding ? h_vp - h_el - padding : h_vp - h_el;

  // Calc. random position anywhere on screen (with padding on all sides)...
  const randomX = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
  const randomY = Math.floor(Math.random() * (maxY - minY + 1)) + minY;
  const position = { x: randomX, y: randomY };

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  console.log(msgs.end);
  return position;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
