// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_formBorderState", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`form border state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_formBorderState(CHANGES) {
  if (!("formBorderState" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { formBorderState } = CHANGES;
  const { is_hidden, color } = formBorderState;
  const { formBorder } = this.ref;
  this.logger("state-change", msgs.stateChange(formBorderState), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  ////////////// hidden attr. updt. //////////////
  ////////////////////////////////////////////////

  if ("is_hidden" in formBorderState) formBorder.setAttribute("data-is-hidden", is_hidden);

  ///////////////// Color updt. //////////////////
  ////////////////////////////////////////////////

  if ("color" in formBorderState && color != null) formBorder.style.setProperty("--border-color", color);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
