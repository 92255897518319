// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_commentViewerNextBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["comment viewer next-btn.", "click"],
  no_comments: ["comment viewer next-btn.", "click", "no comments found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_commentViewerNextBtn_click(EL_commentViewer) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidget } = this.ref;
  const [dateDisplay, timeDisplay] = commentWidget.querySelectorAll("[data-ref='dateDisplay'], [data-ref='timeDisplay']");
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Show next comment...
  const comments = EL_commentViewer.querySelectorAll("[data-ref='comment']");
  if (!comments.length) return this.logger("warning", msgs.no_comments, "warning", logArgs);

  // Find next comment...
  const commentsArr = [...comments];
  const comment_curr = commentsArr.find((c) => c.dataset.isHidden === "false");
  const index_comment_curr = commentsArr.indexOf(comment_curr);
  const index_max = comments.length - 1;
  const index_comment_next = index_comment_curr === index_max ? 0 : index_comment_curr + 1;
  const comment_next = comments[index_comment_next];

  // Updt. date/timte display content & colors...
  if (dateDisplay && timeDisplay) {
    const { date, time, colorBg: color_bg, colorText: color_text } = comment_next.dataset;
    dateDisplay.textContent = date;
    timeDisplay.textContent = time;
    dateDisplay.parentElement.style.setProperty("--color-bg", color_bg);
    timeDisplay.parentElement.style.setProperty("--color-text", color_text);
  }

  comment_curr.dataset.isHidden = "true";
  comment_next.dataset.isHidden = "false";
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
