// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_gameSelectBtn_mouseLeave", inline: true };
const msgs = {
  start: ["mouse left game-select. btn."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_gameSelectBtn_mouseLeave(BTNEL) {
  // Setup...
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////////////
  // Btn. SVG border expand //
  ////////////////////////////

  const borderSVG = BTNEL.querySelector("[data-ref='borderSVG']");
  const borderShape = borderSVG?.querySelector("rect");
  if (!borderShape) return;
  const rx_init = borderShape.getAttribute("data-rx-init");
  const ry_init = borderShape.getAttribute("data-ry-init");
  // gsap.to(BTNEL, { duration: 1, borderRadius: "99px" });
  gsap.to(borderShape, { duration: 0.5, attr: { rx: rx_init, ry: ry_init } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
