// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_selectedWords", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`selected words:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedWords(CHANGES) {
  if (!("selectedWords" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { selectedWords } = CHANGES;
  const { writingGameSelectedWordCards: selectedWordCards } = this.ref;
  this.logger("state-change", msgs.stateChange(selectedWords), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // [action]...

  ////////// Selected-word-cards updt. ///////////
  ////////////////////////////////////////////////

  selectedWords.forEach((w) => {
    const { word, catId: wordCatID } = w;
    if (!word || !word == undefined) return;
    const correspWordCard = selectedWordCards.find((card) => card.dataset.catId == wordCatID);
    if (correspWordCard) correspWordCard.innerHTML = word;
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // ...
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
