// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_stateChange", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`infoWidgetState:`, CHANGE],
  no_change: ["No change to state provided."],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_infoWidgetState(CHANGES) {
  if (!("infoWidgetState" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoWidgetState } = CHANGES;
  const { infoWidget } = this.ref;
  this.logger("state-change", msgs.change(infoWidgetState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////// is_hidden //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in infoWidgetState) {
    const { is_hidden } = infoWidgetState;
    infoWidget.setAttribute("data-is-hidden", is_hidden);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
