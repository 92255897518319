// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_readMoreBtn_click from "../eventHandlers/eh_readMoreBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_sections", inline: true };
const msgs = {
  start: ["init. sections"],
  end: ["init. sections", "init. success"],
  no_el: ["init. sections", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_sections() {
  // Setup...
  const { sections } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (sections === undefined) return this.cancel_featInit(msgs.no_el);
  if (sections.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Section read-more-btn event hdl...
  const firstSectEl = sections[0];
  const firstSectReadMoreBtnEl = firstSectEl.querySelector("[data-ref='readMoreBtn']");
  if (firstSectReadMoreBtnEl)
    firstSectReadMoreBtnEl.addEventListener("click", eh_readMoreBtn_click.bind(this, firstSectReadMoreBtnEl));

  // Set bottom margin of last section to: viewport height - (it’s height - it’s text box’s height)
  const lastSectEl = sections[sections.length - 1];
  const lastSectElTextBoxEl = lastSectEl.querySelector("[data-ref='textBox']");
  const lastSectElHeight = lastSectEl.getBoundingClientRect().height;
  const lastSectElTextBoxHeight = lastSectElTextBoxEl.getBoundingClientRect().height;
  const lastSectElMarginBottom = window.innerHeight - (lastSectElHeight - lastSectElTextBoxHeight) / 2;
  lastSectEl.style.marginBottom = `${lastSectElMarginBottom}px`;

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
