// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import TweenMax from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";
import get_elMeasurements from "../../../../../app/baseUtilities/get/get_elementMeasurements";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_widgetPosition", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "set_widgetPosition"],
  end: ["EventCalendar", "API", "set_widgetPosition", "API call complete"],
  no_widgetEls: ["EventCalendar", "API", "set_widgetPosition", "no widget el. provided"],
  setting_mobilePos: ["EventCalendar", "API", "set_widgetPosition", "setting mobile widget position"],
};
const defConfig = {
  position: "left",
  minDist_fromVpEdge: 60,
  maxCenterOverlap: 200,
  with_follower: false,
  followerEl: null,
  followerAlign: "below-right",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_widgetPosition(EL_widget, CONFIG = defConfig) {
  if (!EL_widget) return this.logger("warning", msgs.no_widgetEls, "warning", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { position, minDist_fromVpEdge, maxCenterOverlap, with_follower, followerEl, followerAlign } = config;
  const { eventCardsWrapper } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  // Determ. allowed overlap based on viewport width...
  const { is_mobile, is_tablet_ls } = this.state;
  const allowedOverlap = is_mobile || is_tablet_ls ? 0 : maxCenterOverlap;

  ////////////////////////////////////// Random widget pos. //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Measure eventCardsWrapper...
  // - Measure widget...
  const { l: l_cardsWrapper, r: r_cardsWrapper } = get_elMeasurements(eventCardsWrapper);
  const { w: w_widget, h: h_widget } = get_elMeasurements(EL_widget);

  //////////////////////////// Mobile screens ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // On mobile, randomly position widget anywhere within viewport...
  if (is_mobile) {
    this.logger("info", msgs.setting_mobilePos, "info", logArgs);
    const widgetPos_new = calc_randomElPosition(EL_widget, { padding: 30 });
    TweenMax.set(EL_widget, { x: widgetPos_new.x, y: widgetPos_new.y });
  }

  //////////////////////////// Other screens /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // On other screens, randomly position widget within space left or right of eventCardsWrapper...
  else {
    // Measure viewport dimensions...
    const { innerWidth: w_vp, innerHeight: h_vp } = window;

    // Determ. min/max x/y pos. for widget...
    const y_min = minDist_fromVpEdge;
    const y_max = h_vp - h_widget - minDist_fromVpEdge;
    let x_min, x_max;
    switch (position) {
      case "left":
        x_min = minDist_fromVpEdge;
        x_max = l_cardsWrapper - w_widget + allowedOverlap;
        break;
      case "right":
        x_min = r_cardsWrapper - allowedOverlap;
        x_max = w_vp - w_widget - minDist_fromVpEdge;
        break;
      case "anywhere":
        x_min = minDist_fromVpEdge;
        x_max = w_vp - w_widget - minDist_fromVpEdge;
        break;
      default:
        break;
    }

    // Randomly position widget...
    const x_widgetPos = Math.random() * (x_max - x_min) + x_min;
    const y_widgetPos = Math.random() * (y_max - y_min) + y_min;
    TweenMax.set(EL_widget, { x: x_widgetPos, y: y_widgetPos });
  }

  /////////////////////// Follower el. pos. updt. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // If followerEl provided, update its position...
  if (with_follower && followerEl) this.api.updt_widgetFollowerPos(EL_widget, followerEl, { instant: true, followerAlign });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
