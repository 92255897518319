// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_pageHeader", inline: true };
const msgs = {
  start: ["PartiForm", "API", "show_pageHeader"],
  no_pageHeaderEl: ["PartiForm", "API", "show_pageHeader", "no page header el. found"],
};

const defConfig = { duration: 0.7 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_pageHeader(CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { duration } = config;
  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!pageHeaderEl) return this.logger("error", msgs.no_pageHeaderEl, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Reveal page header...
  gsap.to(pageHeaderEl, {
    y: 0,
    duration,
    onComplete: () => pageHeaderEl.setAttribute("data-hidden-offscreen", false),
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
