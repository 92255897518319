// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_footnotePositions", inline: true, disabled: false };
const msgs = {
  start: ["init. init_footnotePositions"],
  end: ["init. init_footnotePositions", "init. success"],
  no_footnoteLinks: ["init. init_footnotePositions", "no footnote link els. found"],
  no_footnoteEls: ["init. init_footnotePositions", "no footnote els. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_footnotePositions() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find footnote link & footnote DOM elements on page...
  const footnoteLinks = document.querySelectorAll("[data-footnote-link]");
  const footnotes = document.querySelectorAll("[data-ref='footnote']");

  // Guard: Stop, is no footnote links or footnote els. found...
  if (!footnoteLinks.length) return this.logger("init", msgs.no_footnoteLinks, "warning", logArgs);
  if (!footnotes.length) return this.logger("init", msgs.no_footnoteEls, "warning", logArgs);

  // Set footnote positions...
  footnoteLinks.forEach((link) => this.api.set_footnotePos(link));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
