// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_swup_content_replace", inline: true, disabled: false };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_content_replace(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Hide/Show nav. depending on type of incoming page
  // (get type from 'page-type-[TYPE]' class)...
  const incomingDocBody = ARGS?.to?.document?.body;
  const incomingPageType = incomingDocBody?.classList?.value?.split("page-type-")[1]?.split(" ")[0];
  this.setState({ hidden: incomingPageType == "participation-form" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
