// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_commentWidget", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "show_commentWidget"],
  end: ["PartiForm", "API", "show_commentWidget", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_commentWidget(POSITION = null, WIDTH = null, CONTEXT = "default", COMMENTSDATA = null) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Show comment widget via state updt...
  const { commenWidgetState } = this.state;
  const commentWidgetState_new = { ...commenWidgetState, is_hidden: false, context: CONTEXT };
  if (POSITION) commentWidgetState_new.position = POSITION;
  if (WIDTH) commentWidgetState_new.width = WIDTH;
  if (COMMENTSDATA) commentWidgetState_new.comments = COMMENTSDATA;
  this.setState({ commentWidgetState: commentWidgetState_new });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
