// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_pageBgColor", inline: true };
const msgs = {
  start: ["PartiForm", "API", "set_pageBgColor"],
  noColor: ["PartiForm", "API", "set_pageBgColor", "no color provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_pageBgColor(COLOR) {
  // Setup...
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!COLOR) return this.logger("error", msgs.noColor, "error", logArgs);

  // Set page background color...
  const pageEl = document.querySelector("main");
  gsap.to(pageEl, { backgroundColor: COLOR, duration: 0.7 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
