// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_commentWidgetState", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`commentWidgetState:`, CHANGE],
  no_stateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_commentWidgetState(CHANGES) {
  if (!("commentWidgetState" in CHANGES)) return this.logger("error", msgs.no_stateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidgetState } = CHANGES;
  const { commentWidget } = this.ref;
  this.logger("state-change", msgs.stateChange(commentWidgetState), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  //////////// Widget context updt. //////////////
  ////////////////////////////////////////////////

  if ("context" in commentWidgetState) {
    const { context } = commentWidgetState;
    commentWidget.setAttribute("data-context", context);
  }

  //////////////// Comments updt. ////////////////
  ////////////////////////////////////////////////

  if ("comments" in commentWidgetState) {
    const { comments } = commentWidgetState;
    const commentViewer = commentWidget.querySelector("[data-ref='commentViewer']");
    if (commentViewer) {
      comments.forEach((c, i) => {
        // Define comment markup...
        const { dateTagColors } = c;
        const is_first = i === 0;
        const commentMarkup = `
          <div data-ref      ="comment"
               data-is-hidden="${!is_first}"
               class         ="flex flex-col items-start txt-sm hidden-via-attr-full">
            <div class="w-full bg-white shadow-btn">
              ${c.text}
            </div>
          </div>`;

        // Insert comment markup...
        commentViewer.insertAdjacentHTML("afterbegin", commentMarkup);

        // Updt. date/time display...
        const dateDisplay = commentViewer.querySelector("[data-ref='dateDisplay']");
        const timeDisplay = commentViewer.querySelector("[data-ref='timeDisplay']");
        if (dateDisplay && timeDisplay) {
          const { date, time } = c;
          dateDisplay.textContent = date;
          timeDisplay.textContent = time;
          // dateDisplay.style.color = dateTagColors[0];
          // timeDisplay.style.color = dateTagColors[1];
        }
      });
    }
  }

  ///////////////// width updt. //////////////////
  ////////////////////////////////////////////////

  if ("width" in commentWidgetState) {
    const { width } = commentWidgetState;
    gsap.set(commentWidget, { width });
  }

  //////////////// position updt. ////////////////
  ////////////////////////////////////////////////

  if ("position" in commentWidgetState) {
    const { position } = commentWidgetState;
    const { x, y } = position;
    gsap.set(commentWidget, { x, y });
  }

  ///////////// data-is-hidden updt. /////////////
  ////////////////////////////////////////////////

  if ("is_hidden" in commentWidgetState) {
    const { is_hidden } = commentWidgetState;
    commentWidget.setAttribute("data-is-hidden", is_hidden);

    // If hidden, clear comments...
    if (is_hidden) {
      const commentViewer = commentWidget.querySelector("[data-ref='commentViewer']");
      if (commentViewer) {
        const commentNodes = Array.from(commentViewer.querySelectorAll("[data-ref='comment']"));
        commentNodes.forEach((c) => c.remove());
      }
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
