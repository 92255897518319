// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_sectionStates", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`section states:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_sectionStates(CHANGES) {
  if (!("sectionStates" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { sectionStates } = CHANGES;
  this.logger("state-change", msgs.stateChange(sectionStates), "default", { ...logArgs, inline: false });

  //////////////////// Section group visibility updt. ////////////////////
  ////////////////////////////////////////////////////////////////////////

  sectionStates?.forEach((s) => {
    const { el, is_hidden } = s;
    if (el) el.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
