// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Cookies from "js-cookie";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.declineCookies", inline: true, disabled: false };
const msgs = {
  start: ["CookieWidget", "API", "declineCookies"],
  end: ["CookieWidget", "API", "declineCookies", "API call complete"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function declineCookies() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Remove all cookies...
  // - Hide widget...
  Cookies.remove("ash-accept-cookies-essential");
  Cookies.remove("ash-accept-cookies-analytics");
  Cookies.remove("ash-accept-cookies");
  this.setState({ is_hidden: true });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
