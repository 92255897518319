// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_showCommentsBtn_click from "../eventHandlers/eh_showCommentsBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_showCommentsBtn", inline: true };
const msgs = {
  start: ["init. showCommentsBtn"],
  end: ["init. showCommentsBtn", "init. success"],
  no_el: ["init. showCommentsBtn", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_showCommentsBtn() {
  // Setup...
  const { showCommentsBtn } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(showCommentsBtn)) return this.cancel_featInit(msgs.no_el);

  // Event hdl..
  showCommentsBtn.addEventListener("click", eh_showCommentsBtn_click.bind(this));

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
