// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_infoWidget", inline: true, disabled: false };
const msgs = {
  start: ["init. infoWidget"],
  end: ["init. infoWidget", "init. success"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_infoWidget() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoWidget } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////// Widget position init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Calc. random position for widget...
  // - Calc. random rotation between -30deg and 30deg...
  const randomPos_onScreen = calc_randomElPosition(infoWidget);
  const randomRotation = Math.floor(Math.random() * 10) - 5;

  // - Apply random position & rotation to widget...
  const { x, y } = randomPos_onScreen;
  infoWidget.style.transform = `translate(${x}px, ${y}px) rotate(${randomRotation}deg)`;

  //////////////////////////////////// Widget drag init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Init. drag instance...
  const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  Draggable.create(infoWidget, dragInstConfig)[0];

  //////////////////////////////////// Widget state init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.setState({ infoWidgetState: { is_hidden: true } });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
