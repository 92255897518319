// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_position", inline: true, disabled: false };
const msgs = {
  start: ["init. position"],
  end: ["init. position", "init. success"],
  no_el: ["init. position", "no valid DOM el. provided"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_position() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Randomly position widget anywhere within viewport...
  const position = calc_randomElPosition(compEl);
  compEl.style.transform = `translate(${position.x}px, ${position.y}px)`;

  // Add random rotation (wihtin range)...
  const rotationDir = Math.random() < 0.5 ? -1 : 1;
  const max_rotation = 20;
  const rotation = Math.floor(Math.random() * max_rotation) * rotationDir;
  compEl.style.transform += ` rotate(${rotation}deg)`;

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
