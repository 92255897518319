// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function format_config(CONFIG, DEFAULTS) {
  // Merge default and provided config...
  const config = { ...DEFAULTS, ...CONFIG };

  // Check every prop. and replace with default if null...
  for (const key in DEFAULTS) if (config[key] === null) config[key] = DEFAULTS[key];

  return config;
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_slideChange from "../eventHandlers/eh_slideChange.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const defaultConfig = {
  wraparound: true,
  cellalign: "center",
  draggable: false,
  fade: false,
  autoplay: false,
  selectedattraction: 0.025,
  friction: 0.28,
};

const msgs = {
  start: ["init. flickity"],
  end: ["init. flickity", "init. success"],
  no_el: ["init. flickity", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_flickity() {
  // Setup...
  const { slidesWrapper } = this.ref;
  const { flickityConfig } = this.options;
  const config = format_config(flickityConfig, defaultConfig);
  this.logger("init", msgs.start, "action");

  // Guard...
  if (!slidesWrapper || !this.validate_refEl(slidesWrapper)) return this.cancel_featInit(msgs.no_el);

  // Create Flickity instance...
  const flickityInstance = new Flickity(slidesWrapper, {
    prevNextButtons: false,
    pageDots: false,
    //////
    wrapAround: config.wraparound,
    cellAlign: config.cellalign,
    autoPlay: config.autoplay,
    selectedAttraction: config.selectedattraction,
    draggable: config.draggable,
    fade: config.fade,
    friction: config.friction,
    //////
    on: { change: (INDEX) => eh_slideChange.bind(this)(INDEX) },
  });

  // Conclude...
  this.logger("init", msgs.end, "success", { inline: true });
  return flickityInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
