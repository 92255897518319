// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition" };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  // Setup...
  const { scrollPercentage, _name } = ARGS;
  const { is_mobile } = this.state;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // Updt. headline-stretch state (scale faster on mobile)...
  const headlineStrech = is_mobile ? (scrollPercentage / 100) * 4 : scrollPercentage / 100;
  this.setState({ headlineStrech });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
