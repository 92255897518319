// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function calc_maxListItemScale(ITEMEL, CONTAINERWIDTH = window.innerWidth) {
  if (!ITEMEL) return console.warn("calc_maxItemScale(): no item el. provided.");
  const itemElWidth = ITEMEL.offsetWidth; // ← use offsetWidth instead of getBoundingClientRect().width
  return CONTAINERWIDTH / itemElWidth;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_hoveredListItem", inline: true };
const msgs = {
  change: (CHANGE) => [`hovered list item:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_hoveredListItem(CHANGES) {
  if (!("hoveredListItem" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { hoveredListItem } = CHANGES;
  const { listItems } = this.ref;
  this.logger("state-change", msgs.change(hoveredListItem), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Scale up active list item, scale down others...
  listItems.forEach((item) => {
    if (item.dataset.id === this?.state?.activeListItem?.id) return;
    const itemID = item.dataset.id;
    const is_hovered = itemID === hoveredListItem.id;
    const scale = is_hovered ? calc_maxListItemScale(item) : 1;
    item.setAttribute("data-is-hovered", is_hovered);
    item.style.setProperty("--scale-x-when-active", scale);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
