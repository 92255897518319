// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_btns", inline: true, disabled: false };
const msgs = {
  start: ["init. btns"],
  end: ["init. btns", "init. success"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_btns() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { btn_accept, btn_decline, btn_openInfoWidget, btn_closeInfoWidget } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (btn_accept) btn_accept.addEventListener("click", () => this.api.acceptCookies());
  if (btn_decline) btn_decline.addEventListener("click", () => this.api.declineCookies());
  if (btn_openInfoWidget)
    btn_openInfoWidget.addEventListener("click", () => this.setState({ infoWidgetState: { is_hidden: false } }));
  if (btn_closeInfoWidget)
    btn_closeInfoWidget.addEventListener("click", () => this.setState({ infoWidgetState: { is_hidden: true } }));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
