// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_cardDragEnabled", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`card drag. enabled: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_cardDragEnabled(CHANGES) {
  if (!("cardDragEnabled" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.cardDragEnabled))
    return this.logger("error", msgs.error_invalidStateValue(CHANGES.cardDragEnabled), "error", logArgs);

  // Setup...
  const { cardDragEnabled } = CHANGES;
  this.logger("state-change", msgs.stateChange(cardDragEnabled), "default", logArgs);

  // [action]...
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
