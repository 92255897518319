// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_activeMonthBtn_click from "../eventHandlers/eh_activeMonthBtn_click.js";
import eh_filterBtn_click from "../eventHandlers/eh_filterBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_activeMonthDisplay", inline: true, disabled: false };
const msgs = {
  start: ["init. activeMonthDisplay"],
  end: ["init. activeMonthDisplay", "init. success"],
  no_el: ["init. activeMonthDisplay", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_activeMonthDisplay() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { activeMonthDisplay, activeMonthBtn, monthFilterBtns, contextFilterBtns } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(activeMonthDisplay)) return this.cancel_featInit(msgs.no_el);

  /////////////////////// Active-month btn. init. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (activeMonthBtn) activeMonthBtn.addEventListener("click", eh_activeMonthBtn_click.bind(this));

  ////////////////////////// Filter btn. init. ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (monthFilterBtns.length > 0)
    monthFilterBtns.forEach((btn) => btn.addEventListener("click", eh_filterBtn_click.bind(this, btn, { filterType: "month" })));
  if (contextFilterBtns.length > 0)
    contextFilterBtns.forEach((btn) =>
      btn.addEventListener("click", eh_filterBtn_click.bind(this, btn, { filterType: "context" }))
    );

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
