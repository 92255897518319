// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_futureMatrix", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_futureMatrix"],
  end: ["PartiForm", "API", "processSteps_futureMatrix", "API call complete"],
  no_stepString: ["PartiForm", "API", "processSteps_drawingGame", "No step string provided."],
  stepNotAllowed: (step) => ["PartiForm", "API", "processSteps_drawingGame", `step not allowed: ${step}`],
  futureMatrixIsDisabled: ["PartiForm", "API", "processSteps_futureMatrix", "F. Matrix is disabled.", "stopping step process"],
};

const possibleSteps = [
  "startMatrixGame",
  "startAnsweringMatrix",
  "matrixAnswerSubmitted",
  "matrixXYvaluesSubmitted",
  "viewMatrixAnswers",
  "viewMatrixAnswerGraph",
];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function processSteps_futureMatrix(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_futureMatrix) return this.logger("warning", msgs.futureMatrixIsDisabled, "warning", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { step } = DATA;
  const { futureMatrixTopic, with_matrixAnswerViewer, with_futureMatrixGraph, has_matrixAnswers } = this.options;
  const { matrixQuestionsWrapper, matrixXYInputsWrapper } = this.ref;
  const { sectionFooter_futureMatrix, sectionFooter_futureMatrixAnswerGraph } = this.ref;
  const { enterMatrixAnswerViewerBtn, enterMatrixAnswerGraphBtn } = this.ref;
  const { matrixDatapointTooltip, matrixDatapointTooltip_userAnser } = this.ref;
  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  switch (true) {
    ////////////// "startMatrixGame" ///////////////
    ////////////////////////////////////////////////

    // When starting the matrix game:
    // - Unblock fullpage scroll...
    // - Reveal + scroll to corresp. section...
    // - Updt. currentSubsection state...
    // - Reset page header headline-strech...
    // - Show bg. illu. + page header...
    // - Updt. form boder:
    //   - Show form border...
    //   - Set color to particip. bg. color...
    // - Updt. form header state:
    //   - Updt. header title text...
    //   - Show form header...
    // - Set mode to "futureMatrix"...
    // - Set current step to "startAnsweringMatrix"...
    // - set active slide of info overlay state to "futureMatrix"...
    case step == "startMatrixGame":
      const has_drawingGameInfoSlide = api.has_gameInfoSlide("futureMatrix");
      api.releaseFullpageScroll();
      api.show_section("futureMatrix");
      this.setState({
        currentSubsection: "futureMatrix",
        formBorderState: { is_hidden: false, color: "var(--color-parti-bg)" },
        formHeaderState: { is_hidden: false, titleText: futureMatrixTopic },
      });
      eventbus.emit("SubpageHeader.api.reset_headlineStretch");
      this.init_scrollTrigger_futureMatrixSection();
      api.scrollTo_section("futureMatrix", () => {
        api.show_bgIllu("intro");
        api.show_pageHeader();
        api.set_pageHeaderColor("#a200ff");
        api.set_pageBgColor("#ff7130");
      });
      setTimeout(() => {
        this.setState({
          mode: "futureMatrix",
          currentStep: "startAnsweringMatrix",
          infoOverlayState: {
            ...this.state.infoOverlayState,
            is_disabled: !has_drawingGameInfoSlide,
            activeSlide: has_drawingGameInfoSlide ? "futureMatrix" : null,
          },
        });
      }, 700); // ← let scroll anim. finish (to do: promise based solution)
      break;

    //////////// "startAnsweringMatrix" ////////////
    ////////////////////////////////////////////////

    // When starting to answer matrix questions:
    // - init. btn. borders...
    case step == "startAnsweringMatrix":
      if (with_matrixAnswerViewer) this.init_btnSVGborders([enterMatrixAnswerViewerBtn]);
      // this.setState({ formHeaderState: { is_hidden: false, titleText: futureMatrixTopic } }); // ← moved to prior step
      break;

    /////////// "matrixAnswerSubmitted" ////////////
    ////////////////////////////////////////////////

    // When matrix answer has been submitted:
    // If matrix answer graph is enabled:
    //   - show x/y-input subsection...
    //   - scroll to x/y-input subsection...
    //   - block subsection scroll...
    case step == "matrixAnswerSubmitted":
      if (with_futureMatrixGraph) {
        const subsectionSelector = '[data-ref="subSection_xyInputs"]';
        api.show_subsection(subsectionSelector);
        await api.scrollTo_subsection(subsectionSelector);
        api.blockSubsectionScroll(subsectionSelector);
      }
      // If matrix answer graph is disabled:
      //   - show submit-notif...
      //   - hide question questions wrapper...
      //   - show section footer...
      else {
        api.show_bgIllu("submitNotif");
        matrixQuestionsWrapper.setAttribute("data-is-hidden", true);
        if (has_matrixAnswers) sectionFooter_futureMatrix.setAttribute("data-is-hidden", false);
        else sectionFooter_futureMatrixAnswerGraph.setAttribute("data-is-hidden", false);
      }
      break;

    ////////// "matrixXYvaluesSubmitted" ///////////
    ////////////////////////////////////////////////

    // When matrix X/Y values have been submitted:
    // - show submit-notif...
    // - hide x/y-inputs wrapper...
    // - show section footer...
    case step == "matrixXYvaluesSubmitted":
      api.show_bgIllu("submitNotif");
      matrixXYInputsWrapper.setAttribute("data-is-hidden", true);
      sectionFooter_futureMatrix.setAttribute("data-is-hidden", false);
      break;

    //////////// "viewingMatrixAnswers" ////////////
    ////////////////////////////////////////////////

    // When viewing matrix answers (slider)...
    // - init. btn. borders...
    case step == "viewMatrixAnswers":
      this.init_btnSVGborders([enterMatrixAnswerGraphBtn]);
      break;

    /////////// "viewMatrixAnswerGraph" ////////////
    ////////////////////////////////////////////////

    // When viewing matrix answers graph...
    // - hide submit-notif...
    // - init. tooltip SVG borders...
    // - updt. user-answer tooltip (position)...
    case step == "viewMatrixAnswerGraph":
      api.hide_bgIllu("submitNotif");
      this.init_btnSVGborders([matrixDatapointTooltip, matrixDatapointTooltip_userAnser]);
      api.updt_matrixAnswerGraph_userTooltip();
      break;

    default:
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
