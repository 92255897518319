// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_eventCardStates", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`event card states:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_eventCardStates(CHANGES) {
  if (!("eventCardStates" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { eventCardStates } = CHANGES;
  const { eventCardScroller, eventListItems } = this.ref;
  const { is_mobile } = this.state;
  this.logger("state-change", msgs.stateChange(eventCardStates), "default", { ...logArgs, inline: false });

  // Find active event card...
  const activeEventCard = eventCardStates.find((s) => !s.is_collapsed);
  const has_activeCard = activeEventCard !== undefined;

  // Updt. last-active-event-card state...
  if (activeEventCard) this.setState({ lastActiveEventCard: activeEventCard });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  eventCardStates.forEach((state) => {
    const { el, is_collapsed } = state;

    // Execute is_collapsed state change...
    const relatedListItem = eventListItems.find((i) => i.dataset.id === el.dataset.id);
    el.setAttribute("data-is-collapsed", is_collapsed);
    if (relatedListItem) relatedListItem.setAttribute("data-is-active", !is_collapsed);
  });

  ////////////////////// Event card scroller updt. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  eventCardScroller.setAttribute("data-has-active-card", has_activeCard);

  ////////////////////// Controls/nav. el. updt. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  // If active card, close filter menu...
  if (has_activeCard) this.setState({ filterMenuOpen: false });

  // On mobile:
  // - Hide/Show active month display when card active/inactive...
  // - Hide/Show search bar when card active/inactive...
  if (is_mobile) {
    this.setState({ activeMonthDisplay_hidden: has_activeCard });
    if (has_activeCard) eventbus.emit("Nav.api.hide_searchBar");
    else eventbus.emit("Nav.api.show_searchBar");
  }

  ////////////////////// Active-context state updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Updt. comp. active-context state to context of active card...
  this.setState({ activeContext: activeEventCard ? activeEventCard.context : "default" });

  ///////////////////////////// Widget updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.api.updt_widgets_onEventCardsChange({ eventCardStates });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
