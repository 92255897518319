// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_section", inline: true };
const msgs = {
  start: ["PartiForm", "API", "show_section"],
  end: ["PartiForm", "API", "show_section", "API call complete"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_section(SECTIONID) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const fullpageInst = this.modules.fullpage.instance;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Set section group states w/ updt. is_hidden values...
  const sectionStates = this.state.sectionStates || [];
  const sectionStates_new = sectionStates.map((state) => {
    const { id } = state;
    const is_hidden = id === SECTIONID ? false : state.is_hidden;
    return { ...state, is_hidden };
  });
  this.setState({ sectionStates: sectionStates_new });

  // Re-render + re-build fullpage.js inst...
  if (fullpageInst) {
    fullpageInst.render();
    fullpageInst.reBuild();
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.end, "event", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
