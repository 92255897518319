// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.enable_cardDrag_all", inline: true };
const msgs = {
  start: ["DragCards", "API", "enable_cardDrag_all", "enabling all card dragging"],
  noDragCardInst: ["DragCards", "API", "enable_cardDrag_all", "no drag. card instance(s) found"],
  alreadyEnabled: ["DragCards", "API", "enable_cardDrag_all", "card dragging already enabled"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function enable_cardDrag_all() {
  // Guard: stop, if card dragging is already enabled...
  if (this.state.cardDragEnabled) return this.logger("warning", msgs.alreadyEnabled, "warning", logArgs);

  // Setup...
  const dragCardInstances = this.modules.cards.instances;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard: stop, if no drag. card instances found...
  if (!dragCardInstances || !dragCardInstances.length) return this.logger("error", msgs.noDragCardInst, "error", logArgs);

  // Enable all drag. card instances...
  dragCardInstances.forEach((c) => c.dragInst[0].enable());
  this.setState({ cardDragEnabled: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
