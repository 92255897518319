// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const msgs = {
  change: (CHANGE) => [`form header state:`, CHANGE],
  no_change: () => ["No change to state provided."],
  newTitleMatchesCurrTitle: (titleText) => ["New title matches current title:", titleText],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_formHeaderState(CHANGES) {
  if (!("formHeaderState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const formHeaderState = { ...this.state?.formHeaderState, ...CHANGES.formHeaderState };
  const { floatingFormHeader } = this.ref;
  const { is_hidden, titleText } = formHeaderState;
  const titleWrapper = floatingFormHeader.querySelector("[data-ref='titleWrapper']");
  this.logger("state-change", msgs.change(formHeaderState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// is_hidden ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in formHeaderState) floatingFormHeader.setAttribute("data-is-hidden", is_hidden);

  ////////////////////////////// titleText ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("titleText" in formHeaderState && titleText != null && titleWrapper) {
    // Find pill el. to contain title text...
    const el_titlePill = titleWrapper.querySelector("[g-ref='pills']");
    const el_pillText = el_titlePill.querySelector("[data-ref='textEl']");

    // Guard: stop updt. if curr. text matches new text...
    if (el_pillText.textContent.trim() === titleText)
      return this.logger("warning", msgs.newTitleMatchesCurrTitle(titleText), "warning", logArgs);

    // Update text content:
    // - Hide header...
    // - Update text...
    // - Show header...
    if (el_pillText) {
      floatingFormHeader.setAttribute("data-is-hidden", "true");
      setTimeout(() => {
        el_pillText.textContent = titleText;
        floatingFormHeader.setAttribute("data-is-hidden", "false");
      }, 500);
    }
  }

  ///////////////////////////// titleHeight //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("titleHeight" in formHeaderState && titleWrapper) {
    const { titleHeight } = formHeaderState;
    this.element.style.setProperty("--h-floatingTitle", `${titleHeight}px`);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
