// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * @name        stChH_currentStep
 * @description Component state change handler. Handles state changes to the currentStep state.
 *              The state value is used to determine the execution of a command sequence corresp.
 *              to the current step.
 *
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import compare_stateArrays from "../../../../../app/baseUtilities/compare/compare_stateArrays";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_currentStep", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`curr. step: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
};

const steps_consentCheck = ["consentCheck", "consentCheckDone"];
const steps_drawingGame = ["startDrawingGame", "selectDrawTool", "startDrawing", "drawingSubmitted", "viewDrawings"];
const steps_writingGame = ["startWritingGame", "startWriting", "submitWords", "writingGameSubmitted", "viewWords"];
const steps_futureMatrix = [
  "startMatrixGame",
  "startAnsweringMatrix",
  "matrixAnswerSubmitted",
  "matrixXYvaluesSubmitted",
  "viewMatrixAnswers",
  "viewMatrixAnswerGraph",
];
const steps_outro = ["startOutro"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function stChH_currentStep(CHANGES) {
  if (!("currentStep" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { currentStep: step } = CHANGES;
  const { mode, is_mobile } = this.state;
  const api = this.api;
  const { with_writingGame, with_drawingGame } = this.options;
  this.logger("state-change", msgs.stateChange(step), "default", logArgs);

  ///////////////////////////////////// Form comp. el. updt. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. comp. el’s data-curr-step attr...
  this.element.setAttribute("data-curr-step", step);

  ///////////////////////////////////// Consent-check steps //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (steps_consentCheck.includes(step)) api.processSteps_consentCheck({ step });

  ///////////////////////////// Drawing-section/-game related steps //////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (steps_drawingGame.includes(step)) api.processSteps_drawingGame({ step });

  ///////////////////////////// Writing-section/-game related steps //////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (steps_writingGame.includes(step)) api.processSteps_writingGame({ step });

  ////////////////////////////////// Fut. Matrix related steps ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (steps_futureMatrix.includes(step)) api.processSteps_futureMatrix({ step });

  ///////////////////////////////////// Outro related steps //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (steps_outro.includes(step)) api.processSteps_outro({ step });

  ///////////////////////////////// Instruct.-card states updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Setup: Showing/hiding of instruct. cards corresp. to curr. step...
  const { instructCardStates } = this.state;

  // If not in "draw" mode, hide all instruct. cards...
  if (instructCardStates && mode != "drawing" && mode != "writing") {
    const instructCardStates_new = instructCardStates.map((cS) => ({ ...cS, is_hidden: true }));
    const stateHaveChanged = compare_stateArrays(instructCardStates, instructCardStates_new);
    if (stateHaveChanged) this.setState({ instructCardStates: instructCardStates_new });
  }

  // If in "draw" mode, show instruct. card corresp. to curr. step...
  // (non-mobile only)
  else if (!is_mobile && with_drawingGame && mode == "drawing") {
    api.processSteps_updtInstructCards_drawingGame({ step });
  }

  // If in "writing" mode, show instruct. card corresp. to curr. step...
  // (non-mobile only)
  else if (!is_mobile && with_writingGame && mode == "writing") {
    api.processSteps_updtInstructCards_writingGame({ step });
  }

  //////////////////////////////////// Tooltip states updt. //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { tooltipStates } = this.state;
  if (tooltipStates) {
    const tooltipStates_new = tooltipStates.map((tS) => {
      const { id } = tS;
      switch (true) {
        case !is_mobile && with_drawingGame && id == "tooltip_drawingToolbar" && step == "selectDrawTool":
          return { ...tS, is_hidden: false };
        default:
          return { ...tS, is_hidden: true };
      }
    });

    // Conditional state updt. //
    const tooltipStates_haveChanged = compare_stateArrays(tooltipStates, tooltipStates_new);
    if (tooltipStates_haveChanged) this.setState({ tooltipStates: tooltipStates_new });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
