// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeContext", inline: true };
const msgs = {
  change: (CHANGE) => [`active context: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["default", "wirForschen", "communitySpaces", "zwischenraeume", "partizipation"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeContext(CHANGES) {
  if (!("activeContext" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.activeContext))
    return this.logger("error", msgs.inval_stateValue(CHANGES.activeContext), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeContext } = CHANGES;
  const {
    background,
    widget_date,
    widget_marquee,
    widget_description,
    widget_location,
    widget_ticketLink,
    widget_img,
    widget_slideShow,
    widgetFollower_location,
    widgetFollower_date,
    widgetFollower_img,
  } = this.ref;
  this.logger("state-change", msgs.change(activeContext), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. background’s data-active-context attr...
  background.setAttribute("data-context", activeContext);

  // Updt. data-context attr. on widget els...
  if (widget_date) widget_date.setAttribute("data-context", activeContext);
  if (widget_marquee) widget_marquee.setAttribute("data-context", activeContext);
  if (widget_description) widget_description.setAttribute("data-context", activeContext);
  if (widget_location) widget_location.setAttribute("data-context", activeContext);
  if (widget_ticketLink) widget_ticketLink.setAttribute("data-context", activeContext);
  if (widget_img) widget_img.setAttribute("data-context", activeContext);
  if (widget_slideShow) widget_slideShow.setAttribute("data-context", activeContext);

  // Updt. data-context attr. on widget follower el...
  if (widgetFollower_location) widgetFollower_location.setAttribute("data-context", activeContext);
  if (widgetFollower_date) widgetFollower_date.setAttribute("data-context", activeContext);
  if (widgetFollower_img) widgetFollower_img.setAttribute("data-context", activeContext);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
