// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect, getWidth, getHeight } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function gen_randomXYPos(x_min, x_max, y_min, y_max) {
  return {
    x: Math.floor(Math.random() * (x_max - x_min + 1) + x_min),
    y: Math.floor(Math.random() * (y_max - y_min + 1) + y_min),
  };
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_position", inline: true, disabled: false };
const msgs = {
  start: ["init. position"],
  end: ["init. position", "init. success"],
  no_el: ["init. position", "no valid DOM el. provided"],
  no_anchoEl: ["init. position", "no el. found to anchor widget to"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_position() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  const { type } = this.options;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////// Comp. el. positioning /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find inner page layout wrapper (used as reference for positioning)...
  const innerLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  if (!innerLayoutWrapper) return this.logger("init", msgs.no_anchoEl, "error", logArgs);

  // - Init. pos. vars...
  // - Measure comp. width...
  // - Measure comp. height...
  let x_min, x_max, y_min, y_max;
  const w_widget = getWidth(compEl);
  const h_widget = getHeight(compEl);

  //////////////////////// Left (type === "prev") ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Randomly position widget along bottom edge of page layout wrapper...
  if (type === "prev") {
    // - Set allowed overlap w/ page layout wrapper...
    // - Get left & bottom edge of page layout wrapper...
    const allowedOverlap_withPageLayoutWrapper = 150;
    const { left: l_pageLayoutWrapper, bottom: b_pageLayoutWrapper } = getRect(innerLayoutWrapper);

    // Set min. & max. x pos. for widget:
    // Widget may be placed anywhere between 50px from left edge of viewport & 150px from right edge of page layout wrapper...
    x_min = 50; // ← 50px from left edge of viewport
    x_max = l_pageLayoutWrapper + allowedOverlap_withPageLayoutWrapper - w_widget;

    // Set min. & max. y pos. for widget:
    // Widget may be placed anywhere between 1/2 & 1/4 of its height above bottom edge of page layout wrapper...
    y_min = b_pageLayoutWrapper - h_widget / 2;
    y_max = b_pageLayoutWrapper - h_widget / 4;
  }

  /////////////////////// Right (type === "next") ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Randomly position widget along bottom edge of page layout wrapper...
  else {
    // - Set allowed overlap w/ page layout wrapper...
    // - Get right & bottom edge of page layout wrapper...
    const allowedOverlap_withPageLayoutWrapper = 150;
    const { right: r_pageLayoutWrapper, bottom: b_pageLayoutWrapper } = getRect(innerLayoutWrapper);

    // Set min. & max. x pos. for widget:
    // Widget may be placed anywhere between 150px from right edge of page layout wrapper & 50px from right edge of viewport...
    x_min = r_pageLayoutWrapper - allowedOverlap_withPageLayoutWrapper;
    x_max = window.innerWidth - w_widget - 50;

    // Set min. & max. y pos. for widget:
    // Widget may be placed anywhere between 1/2 & 1/4 of its height above bottom edge of page layout wrapper...
    y_min = b_pageLayoutWrapper - h_widget / 2;
    y_max = b_pageLayoutWrapper - h_widget / 4;
  }

  // Generate & apply random x & y pos. to widget...
  const randomXYPos = gen_randomXYPos(x_min, x_max, y_min, y_max);
  gsap.set(compEl, randomXYPos);

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
