// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition", disabled: true };
const msgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  const api = this.api;
  const { is_mobile, is_tablet_prt } = this.state;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  ////////////////////////////////// Active event card closing ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Close active event card when it scrolls out of view...
  // (not on  mobile || portrait tablet)
  if (!is_mobile && !is_tablet_prt) {
    const activeEventCard = api.get_activeEventCard();
    if (activeEventCard) {
      // Prevent closing of card that has been active on page load || has been opened & is being scrolled to...
      if (!activeEventCard.is_activeOnLoad && !activeEventCard.is_beingScrolledTo) {
        // Measure dist. of card center from viewport center...
        const vpCenter = window.innerHeight / 2;
        const rect_card = activeEventCard.el.getBoundingClientRect();
        const { top: t_card, bottom: b_card } = rect_card;
        const cardCenter = t_card + (b_card - t_card) / 2;
        const dist_cardCenter_from_vpCenter = Math.abs(vpCenter - cardCenter);

        // Close card when it’s center is 25% of the viewport height away from the viewport center...
        if (dist_cardCenter_from_vpCenter > vpCenter / 2) api.close_eventCard(activeEventCard.id);
      }
    }
  }

  ////////////////////////////////// Active-month-display updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Determ. which event card is closest to center of viewport...
  const { eventCards } = this.ref;
  const vpCenter = window.innerHeight / 2;
  const cardDistances = eventCards.map((card) => {
    const cardRect = card.getBoundingClientRect();
    const cardTop = cardRect.top;
    const cardBottom = cardRect.bottom;
    const cardCenter = cardTop + (cardBottom - cardTop) / 2;
    return { card, distance: Math.abs(vpCenter - cardCenter) };
  });
  const closestCard = cardDistances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr)).card;

  // Updt. active month display to month of closest event card...
  const { activeMonthDisplay } = this.ref;
  const activeMonthTextEl = activeMonthDisplay.querySelector("[data-ref='activeMonthText']");
  if (activeMonthTextEl) activeMonthTextEl.textContent = closestCard.dataset.eventMonth;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
