// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect, getWidth, getHeight } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_position", inline: true, disabled: false };
const msgs = {
  start: ["init. position"],
  end: ["init. position", "init. success"],
  no_el: ["init. position", "no valid DOM el. provided"],
  no_anchoEl: ["init. position", "no el. found to anchor widget to"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_position() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  const { type } = this.options;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////// Comp. el. positioning /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find inner page layout wrapper (used as reference for positioning)...
  const innerLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  if (!innerLayoutWrapper) return this.logger("init", msgs.no_anchoEl, "error", logArgs);

  // - Init. pos. vars...
  // - Measure comp. width...
  // - Measure comp. height...
  let x, y;
  const w_widget = getWidth(compEl);
  const h_widget = getHeight(compEl);

  //////////////////////// Left (type === "prev") ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Randomly position widget along bottom edge of page layout wrapper...
  if (type === "prev") {
    // - Set allowed overlap w/ page layout wrapper...
    // - Get left & bottom edge of page layout wrapper...
    const allowedOverlap_withPageLayoutWrapper = 150;
    const { left: l_pageLayoutWrapper, bottom: b_pageLayoutWrapper } = getRect(innerLayoutWrapper);

    // Determ. min. & max. x pos. for widget...
    const x_min = 50;
    const x_max = l_pageLayoutWrapper + allowedOverlap_withPageLayoutWrapper - w_widget;

    // Determ. min. & max. y pos. for widget...
    const y_min = b_pageLayoutWrapper - h_widget / 2;
    const y_max = b_pageLayoutWrapper - h_widget / 4;

    // Determ. random x & y pos. for widget...
    x = Math.floor(Math.random() * (x_max - x_min + 1) + x_min);
    y = Math.floor(Math.random() * (y_max - y_min + 1) + y_min);
  }

  /////////////////////// Right (type === "next") ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Randomly position widget along bottom edge of page layout wrapper...
  else {
    // - Set allowed overlap w/ page layout wrapper...
    // - Get right & bottom edge of page layout wrapper...
    const allowedOverlap_withPageLayoutWrapper = 150;
    const { right: r_pageLayoutWrapper, bottom: b_pageLayoutWrapper } = getRect(innerLayoutWrapper);

    // Determ. min. & max. x pos. for widget...
    const x_min = r_pageLayoutWrapper - allowedOverlap_withPageLayoutWrapper;
    const x_max = window.innerWidth - w_widget - 50;

    // Determ. min. & max. y pos. for widget...
    const y_min = b_pageLayoutWrapper - h_widget / 2;
    const y_max = b_pageLayoutWrapper - h_widget / 4;

    // Determ. random x & y pos. for widget...
    x = Math.floor(Math.random() * (x_max - x_min + 1) + x_min);
    y = Math.floor(Math.random() * (y_max - y_min + 1) + y_min);
  }

  gsap.set(compEl, { x, y });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
