// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_window_resize", inline: true, disabled: false };
const msgs = {
  start_posUpdt: ["window resize", "updt. card positions"],
  end_posUpdt: ["window resize", "finished updt. card positions"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { _name } = ARGS;
  const api = this.api;
  const { mobileBreakpoint = 640 } = this.options;
  this.logger("eventbus call", [_name], "event", logArgs);

  /////////////////////////////// prev. viewport-width state updt. ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Measure viewport width change (difference between curr. & prev. width)...
  const prevVpWidth = this.state.prevVpWidth;
  const prevVpWidth_new = window.innerWidth;
  const vpWidth_diff = prevVpWidth_new - prevVpWidth;

  // Updt. state...
  this.setState({ prevVpWidth: prevVpWidth_new });

  //////////////////////////////////// is-mobile state updt. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. state...
  const is_mobile = window.innerWidth < mobileBreakpoint;
  this.setState({ is_mobile });

  //////////////////////////////////// Card drag en-/disable /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // If on mobile, disable dragging...
  if (is_mobile) api.disable_cardDrag_all();
  else api.enable_cardDrag_all();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
