// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_commentViewerNextBtn_click from "../eventHandlers/eh_commentViewerNextBtn_click.js";
import eh_commentSubmitBtn_click from "../eventHandlers/eh_commentSubmitBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_commentWidget", inline: true, disabled: false };
const msgs = {
  start: ["init. commentWidget"],
  end: ["init. commentWidget", "init. success"],
  no_el: ["init. commentWidget", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_commentWidget() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidget } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(commentWidget)) return this.cancel_featInit(msgs.no_el);

  ////////////////////// Comment-widget state init. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const commentWidgetState = { position: { x: 0, y: 0 }, is_hidden: true };
  this.setState({ commentWidgetState });

  ///////////////////////// Comment viewer init. /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const commentViewer = document.querySelector("[data-ref='commentViewer']");
  const nextCommentBtn = document.querySelector("[data-ref='commentViewerNextBtn']");
  if (nextCommentBtn) nextCommentBtn.addEventListener("click", eh_commentViewerNextBtn_click.bind(this, commentViewer));

  /////////////////////// New comment dialog init. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  const commentSubmitBtn = document.querySelector("[data-ref='commentSubmitBtn']");
  if (commentSubmitBtn) commentSubmitBtn.addEventListener("click", eh_commentSubmitBtn_click.bind(this, commentWidget));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
