// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeIlluID", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`active illu. ID: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeIlluID(CHANGES) {
  if (!("activeIlluID" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { activeIlluID } = CHANGES;
  const { illus } = this.ref;
  this.logger("state-change", msgs.stateChange(activeIlluID), "default", logArgs);

  // Updt. illu. el. data-is-active attr...#
  illus.forEach((illuEl) => illuEl.setAttribute("data-is-active", illuEl.dataset.id === activeIlluID));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
