// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";
import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_click", inline: true };
const msgs = {
  start: ["list item was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_click(ITEMEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplay } = this.ref;
  const { is_mobile } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////// Active list item & info display updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Updt. active-list-item state...
  // - Updt. info-display state...
  //   - text
  //   - context
  //   - visibility
  //   - No-text illu. visibility
  //   - Placeholder text visibility
  const itemID = ITEMEL.dataset.id;
  const itemInfoText = ITEMEL.dataset.infoText;
  const itemContext = ITEMEL.dataset.context;
  const with_placeholder = ITEMEL.dataset.withPlaceholder === "true";
  this.setState({
    activeListItem: { id: itemID },
    infoDisplayState: {
      text: itemInfoText,
      context: itemContext,
      is_hidden: false,
      show_illu_noText: !valid_str(itemInfoText) || with_placeholder,
      show_placeholderText: with_placeholder,
    },
  });

  ///////////////////////////////// Info display position updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  let position = {};

  //////////////////////////// Mobile screens ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (is_mobile) {
    // Calc. position in center of viewport...
    const { innerWidth: w_vp, innerHeight: h_vp } = window;
    const { width: w_widget, height: h_widget } = infoDisplay.getBoundingClientRect();
    const x = Math.floor(w_vp / 2 - w_widget / 2);
    const y = Math.floor(h_vp / 2 - h_widget / 2);
    position = { x, y };
  }

  /////////////////////// Tablet & desktop screens ///////////////////////
  ////////////////////////////////////////////////////////////////////////
  else {
    // Calc. random position anywhere on screen (with padding on all sides)...
    position = calc_randomElPosition(infoDisplay, { padding: 100 });
  }

  // Updt. info-display state w/ new position...
  const infoDisplayState_new = { ...this.state.infoDisplayState, position };
  this.setState({ infoDisplayState: infoDisplayState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
