// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elMeasurements from "../../../../../app/baseUtilities/get/get_elementMeasurements";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_widget", inline: true, disabled: false };
const msgs = {
  start: ["init. widget"],
  end: ["init. widget", "init. success"],
  no_el: ["init. widget", "no valid DOM el. provided"],
  no_slidesWrapper: ["init. widget", "no slidesWrapper el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_widget() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { widget } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(widget)) return this.cancel_featInit(msgs.no_el);

  ////////////////////////////////////// Random pos. init. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Determ. side of screen to move widget to...
  // - Measure widget...
  // - Determ. random offsets by which to move widget away from edge...
  const leftOrRight = Math.random() > 0.5 ? "left" : "right";
  // const upOrDown = Math.random() > 0.5 ? "up" : "down"; // DEPRECATED
  const { l: l_widget, r: r_widget } = get_elMeasurements(widget);
  const randomOffset_x = Math.random() * 100 + 50;
  // const randomOffset_y = Math.random() * 100; // DEPRECATED

  // Init. pos. vars...
  let x, y;

  // Move widget to left edge of screen...
  if (leftOrRight === "left") {
    // Determ. dist. to left edge...
    const dist_toLeftEdge = l_widget;

    // Set pos. vars...
    x = -dist_toLeftEdge + randomOffset_x;
    // y = -widget.offsetHeight / 2 + randomOffset_y; // DEPRECATED
    y = -widget.offsetHeight / 2;
  }

  // Move widget to right edge of screen...
  else {
    // Determ. dist. to right edge...
    const dist_toRightEdge = window.innerWidth - r_widget;

    // Set pos. vars...
    x = dist_toRightEdge - randomOffset_x;
    // y = -widget.offsetHeight / 2 + randomOffset_y; // DEPRECATED
    y = -widget.offsetHeight / 2;
  }

  // Move widget...
  widget.style.transform = `translate(${x}px, ${y}px)`;

  ///////////////////////////////////////// Drag. init. //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Init drag. inst...
  const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  Draggable.create(widget, dragInstConfig);

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
