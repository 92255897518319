// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { fabric } from "fabric";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_canvas_mouseDown from "../eventHandlers/eh_canvas_mouseDown.js";
import eh_canvas_mouseMove from "../eventHandlers/eh_canvas_mouseMove.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_fabric", inline: true };
const msgs = {
  start: ["init. fabric"],
  end: ["init. fabric", "init. success"],
  no_el: ["init. fabric", "no valid DOM element(s) provided"],
  no_canvasEl: ["init. fabric", "no valid canvas element provided"],
};

const sprayBrushImgURLs = {
  // alice: "/app/assets/fabricBrushImgs/brush-img-alice.png", // DEPRECATED
  insel: "/app/assets/fabricBrushImgs/brush-img-insel.png",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_fabric() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { drawingCanvas } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(drawingCanvas)) return this.cancel_featInit(msgs.no_canvasEl);

  ///////////////////////// Fabric instantiation /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const fabricInst = new fabric.Canvas(drawingCanvas, {
    backgroundColor: "rgba(255, 255, 255, 1)",
    selection: false,
    width: drawingCanvas.offsetWidth,
    height: drawingCanvas.offsetHeight,
    isDrawingMode: true,
    freeDrawingCursor: "none",
  });
  fabricInst.renderAll();

  //////////////////////// Canvas bg. img. init. /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { drawingGameCanvasBgImg = null } = this.options;
  if (drawingGameCanvasBgImg) {
    fabric.Image.fromURL(drawingGameCanvasBgImg, (img) => {
      // Calculate the scale factors
      const scaleX = fabricInst.width / img.width;
      const scaleY = fabricInst.height / img.height;

      // Use the larger scale factor to cover the canvas
      const scale = Math.max(scaleX, scaleY);

      // Set the background image with the calculated scale
      fabricInst.setBackgroundImage(img, fabricInst.renderAll.bind(fabricInst), {
        scaleX: scale,
        scaleY: scale,
        originX: "center",
        originY: "center",
        left: fabricInst.width / 2,
        top: fabricInst.height / 2,
      });
    });
  }

  ///////////////////// Custom undo/redo feat. init. /////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Setup //
  fabricInst.historyStack = [];
  fabricInst.historyIndex = -1; // ← init. history index
  fabricInst.historyLimit = 10; // ← limit history stack size

  // Capture init. state...
  fabricInst.historyStack.push(fabricInst.toJSON());
  fabricInst.historyIndex++;

  /////////////////////////////////////
  // Monkey patching undo/redo feat. //
  /////////////////////////////////////

  fabricInst.captureState = function () {
    // Remove "future" states if any exist...
    this.historyStack = this.historyStack.slice(0, this.historyIndex + 1);

    this.historyStack.push(this.toJSON()); // ← capture current state
    this.historyIndex++; // ← increment current history index´
  };

  fabricInst.undo = function () {
    if (this.historyIndex > 0) {
      this.historyIndex--;
      const prevState = this.historyStack[this.historyIndex];
      this.loadFromJSON(prevState, () => this.renderAll());
    }
  };

  fabricInst.clearAndResetHistory = function () {
    this.clear();
    this.historyStack = [];
    this.historyIndex = -1;
    this.captureState();
  };

  // Capture state on path creation...
  fabricInst.on("path:created", function () {
    fabricInst.captureState();
  });

  // Capture state on object creation...
  fabricInst.on("object:added", () => {
    // Only capture when using spraycan tool
    // (pen tool is captured on path:created event)...
    if (this.state.selectedDrawTool == "spraycan") fabricInst.captureState();
  });

  /////////////////////// Custom spray brush init. ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  // const { sprayBrushImg = "insel" } = this.options; // TO DO: Implement brush selection
  const sprayBrushImgURL = sprayBrushImgURLs["insel"];
  console.log("sprayBrushImgURL", sprayBrushImgURL);
  fabric.Image.fromURL(sprayBrushImgURL, function (img) {
    const CustomSprayBrush = fabric.util.createClass(fabric.PencilBrush, {
      onMouseDown: function (pointer) {
        this.isDrawing = true;
        this.addSpray(pointer);
      },
      onMouseMove: function (pointer) {
        if (this.isDrawing) {
          this.addSpray(pointer);
        }
      },
      onMouseUp: function () {
        this.isDrawing = false;
      },
      addSpray: function (pointer) {
        // Clone the image to not reuse the same instance
        const imgInstance = fabric.util.object.clone(img);
        imgInstance.set({
          left: pointer.x,
          top: pointer.y,
          originX: "center",
          originY: "center",
          selectable: false, // ← prevent the image to be selectable
          scaleX: 0.25,
          scaleY: 0.25,
        });
        this.canvas.add(imgInstance);
        this.canvas.renderAll();
      },
    });
    fabricInst.CustomSprayBrush = CustomSprayBrush;
  });

  ////////////////////////// Drawing mod. init. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const drawingModuleState = { fabricInst, isDrawing: false, brushColor: "#000", brushWidth: 5 };
  this.setState({ drawingModuleState });

  ////////////////////////////// Event hdl. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  fabricInst.on("mouse:move", eh_canvas_mouseMove.bind(this, { fabricInst }));

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
  this.modules.fabric.instances.push(fabricInst);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
