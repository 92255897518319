// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_consentCheck", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_consentCheck"],
  end: ["PartiForm", "API", "processSteps_consentCheck", "API call complete"],
  no_stepString: () => ["PartiForm", "API", "processSteps_consentCheck", "No step string provided."],
  consentCheckDisabled: ["PartiForm", "API", "processSteps_consentCheck", "Consent check is disabled.", "stopping step process"],
};

const possibleSteps = ["consentCheck", "consentCheckDone"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function processSteps_consentCheck(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_consentCheck) return this.logger("warning", msgs.consentCheckDisabled, "warning", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { step } = DATA;
  const { userConsent } = this.state;
  const { consentQuest_researchUse, consentQuest_nameAgeQuery, consentQuest_thankYouMsg } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  // Check if consent given...
  const consentGiven = userConsent?.researchUseConsent === true;

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  switch (true) {
    ////////////// "consentCheckDone" //////////////
    ////////////////////////////////////////////////

    // - Hide consent check research use info & name/age query...
    // - Show consent check Thank-you msg...
    //   - Mark as consent given/not given...
    // - reveal "game-selection" sub-section...
    // - init. game-select. btns...
    // - scroll to "game-selection" sub-section...
    //   - measure height of first text box of "game-selection" sub-section to use for scroll offset...
    case step == "consentCheckDone":
      consentQuest_researchUse.setAttribute("data-is-hidden", "true");
      consentQuest_nameAgeQuery.setAttribute("data-is-hidden", "true");
      consentQuest_thankYouMsg.setAttribute("data-consent-given", consentGiven ? "true" : "false");
      consentQuest_thankYouMsg.setAttribute("data-is-hidden", "false");
      api.show_subsection('[data-ref="section_gameSelection"]');
      this.init_gameSelectBtns();
      setTimeout(() => {
        const firstTextBox = document.querySelector('[data-ref="section_gameSelection"] .text-box');
        const textBoxHeight = firstTextBox?.offsetHeight || 0;
        api.scrollTo_subsection('[data-ref="section_gameSelection"]', { duration: 1250, addOffset: textBoxHeight / 2 });
      }, 2000);
      break;

    ///////////// Default (do nothing) /////////////
    ////////////////////////////////////////////////

    default:
      break;
  }

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
