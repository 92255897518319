// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_cardFollower_mouseLeave", inline: true, disabled: false };
const msgs = {
  start: ["mouse left card follower"],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_cardFollower_mouseLeave(EL_follower) {
  // Stop, if no pointer events...
  // const globalState = localStorage.getItem("globalState");
  // const { features } = JSON.parse(globalState);
  // if (!features?.has_pointerEvents) return;

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////// Follower SVG backgr./clip path/border reset //////////////
  ////////////////////////////////////////////////////////////////////////

  this.api.contract_cardBg(EL_follower);
  this.api.contract_cardClipPath(EL_follower);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
