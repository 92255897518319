// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_wordCardInputWidth from "../util/updt_wordCardInputWidth.js";

function set_randomWordCardPosition(CARDEL, CONFIG = { with_logs: false }) {
  const { with_logs = false } = CONFIG;
  const parentWidth = CARDEL.parentElement.offsetWidth;
  if (with_logs) console.log("parentWidth: ", parentWidth);
  const cardWidth = CARDEL.offsetWidth;
  if (with_logs) console.log("cardWidth: ", cardWidth);
  const maxPosition = parentWidth - cardWidth;
  if (with_logs) console.log("maxPosition: ", maxPosition);
  const randomPosition = Math.floor(Math.random() * maxPosition);
  if (with_logs) console.log("randomPosition: ", randomPosition);
  CARDEL.style.transform = `translateX(${randomPosition}px)`;
}

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_writingGameWordCard_click from "../eventHandlers/eh_writingGameWordCard_click.js";
import eh_submitWritingGameWordsBtn_click from "../eventHandlers/eh_submitWritingGameWordsBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_writingGame", inline: true };
const msgs = {
  start: ["init. writingGame"],
  end: ["init. writingGame", "init. success"],
  no_el: ["init. writingGame", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_writingGame() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const {
    writingGameWordGroups: wordGroups,
    writingGameWordCards: wordCards,
    writingGameCustomWordCards: customWordCards,
    submitWritingGameWordsBtn,
  } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (wordCards === undefined) return this.cancel_featInit(msgs.no_el);
  if (wordCards.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  ////////////////////// Selected-words state init. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const selectedWords = [];
  wordGroups.forEach((groupEl) => selectedWords.push({ catId: groupEl.dataset.id, word: null }));
  this.setState({ selectedWords });

  /////////////////////////// Word card init. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  wordCards.forEach((card) => {
    ////////////////////
    // Position init. //
    ////////////////////

    set_randomWordCardPosition(card);

    ////////////////
    // Event hdl. //
    ////////////////

    card.addEventListener("click", eh_writingGameWordCard_click.bind(this, card));
  });

  //////////////////////// Custom word card init. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  customWordCards.forEach((cardEl) => {
    ///////////////// Input init. //////////////////
    ////////////////////////////////////////////////

    // Get input field...
    const inputEl = cardEl.querySelector("input");

    if (inputEl) {
      // - Dyn. set width of input field to fit content...
      // - Updt. card’s data-word attr. on input...
      // - Set card’s data-has-word attr. if input has value...
      inputEl.style.width = 2 + "ch"; // ← set initial width
      inputEl.addEventListener("input", () => {
        setTimeout(() => {
          const inputVal = inputEl.value;
          updt_wordCardInputWidth(inputEl);
          cardEl.dataset.word = inputVal;
          cardEl.setAttribute("data-has-word", inputVal.length > 0);
        }, 0);
      });

      // On focus/blur, set card’s data-is-active attr...
      inputEl.addEventListener("focus", () => (cardEl.dataset.isActive = true));
      inputEl.addEventListener("blur", () => setTimeout(() => (cardEl.dataset.isActive = false), 300));
      //                                     ^^^^^^^^^^^
      //                                     ↳ delayed to allow click event to fire on word-select btn.

      // Hdl. click on word select btn...
      const selectCustomWordBtn = cardEl.querySelector("[data-ref='selectCustomWordBtn']");
      if (selectCustomWordBtn) selectCustomWordBtn.addEventListener("click", eh_writingGameWordCard_click.bind(this, cardEl));
    }

    //////////////// Position init. ////////////////
    ////////////////////////////////////////////////

    set_randomWordCardPosition(cardEl);
  });

  //////////////////////// Submit words btn init. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (this.validate_refEl(submitWritingGameWordsBtn)) {
    submitWritingGameWordsBtn.addEventListener("click", eh_submitWritingGameWordsBtn_click.bind(this));
  }

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
