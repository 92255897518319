// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_widget", inline: true };
const msgs = {
  start: ["GlossaryLinks", "API", "show glossary term widget"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_widget() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeTermWidgetState, is_mobile, is_tablet, is_desktop_sm, is_desktop_md } = this.state;
  const widgetEl = activeTermWidgetState.el;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////////////////// Random widget positioning ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Measure widget...
  const widgetRect = widgetEl.getBoundingClientRect();
  const { width: w_widget, height: h_widget } = widgetRect;

  // Measure viewport...
  const w_vp = window.innerWidth;
  const h_vp = window.innerHeight;

  //////////////////////////// Mobile screens ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (is_mobile) {
    // Calc. position in center of viewport...
    const x = Math.floor(w_vp / 2 - w_widget / 2);
    const y = Math.floor(h_vp / 2 - h_widget / 2);

    // - Set widget position...
    // - Show widget...
    gsap.set(widgetEl, { x, y });
    this.setState({ activeTermWidgetState: { ...activeTermWidgetState, is_hidden: false } });
  }

  //////////////////// Tablet & small desktop screens ////////////////////
  ////////////////////////////////////////////////////////////////////////
  else if (is_tablet || is_desktop_sm) {
    // Calc. random position anywhere on screen (with padding on all sides)...
    const padding = 100;
    const minX = padding;
    const maxX = w_vp - w_widget - padding;
    const minY = padding;
    const maxY = h_vp - h_widget - padding;
    const randomX = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
    const randomY = Math.floor(Math.random() * (maxY - minY + 1)) + minY;

    // - Set widget position...
    // - Show widget...
    gsap.set(widgetEl, { x: randomX, y: randomY });
    this.setState({ activeTermWidgetState: { ...activeTermWidgetState, is_hidden: false } });
  }

  /////////////////////////// Desktop screens ////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  else if (is_desktop_md) {
    // Get inner page content wrapper (widget is to be pos. to right of wrapper)...
    const innerPageLayoutWrapper = document.querySelector("[data-role=innerPageLayoutWrapper]");

    // Calc. random position right of layout wrapper...
    const layoutRect = innerPageLayoutWrapper.getBoundingClientRect();
    const right_layout = layoutRect.right;
    const minX = right_layout;
    const maxX = w_vp - w_widget > right_layout ? w_vp - w_widget : right_layout;
    const minY = 0;
    const maxY = h_vp - h_widget;
    const randomX = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
    const randomY = Math.floor(Math.random() * (maxY - minY + 1)) + minY;

    // - Set widget position...
    // - Show widget...
    gsap.set(widgetEl, { x: randomX, y: randomY });
    this.setState({ activeTermWidgetState: { ...activeTermWidgetState, is_hidden: false } });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
