// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_drawToolBtn_click", inline: true };
const msgs = {
  start: ["draw-tool btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_drawToolBtn_click(BUTTONEL) {
  // Setup...
  this.logger("event", msgs.start, "event", logArgs);

  // Updt. selected-draw-tool state...
  const drawToolID = BUTTONEL.dataset.toolId;
  this.setState({
    selectedDrawTool: drawToolID,
    currentStep: "startDrawing",
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
