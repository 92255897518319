// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// import calc_svgShapePerimeter from "../util/calc_svgShapePerimeter.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_openInfoOverlayBtn_click from "../eventHandlers/eh_openInfoOverlayBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_infoOverlay", inline: true, disabled: false };
const msgs = {
  start: ["init. infoOverlay"],
  end: ["init. infoOverlay", "init. success"],
  no_el: ["init. infoOverlay", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_infoOverlay() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoOverlay, openInfoOverlayBtn } = this.ref;
  const elements = [infoOverlay, openInfoOverlayBtn];
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (elements.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  /////////////////////////////////// Info Overlay state init. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { firstSectionType, has_initInfoOverlaySlide } = this.options;
  const infoOverlayState = {
    is_hidden: true,
    is_disabled: !has_initInfoOverlaySlide,
    activeSlide: has_initInfoOverlaySlide ? firstSectionType : null,
  };
  this.setState({ infoOverlayState });

  ////////////////////////////////////////// Event hdl. //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Info Overlay open btn. click hdl. //
  openInfoOverlayBtn.addEventListener("click", eh_openInfoOverlayBtn_click.bind(this));

  // Info Overlay close btn. click hdl. //
  const closeBtnEl = infoOverlay.querySelector("[data-ref='closeBtn']");
  if (closeBtnEl)
    closeBtnEl.addEventListener("click", () =>
      this.setState({ infoOverlayState: { ...this.state.infoOverlayState, is_hidden: true } })
    );

  //////////////////////////////// Open button anim. border init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // WIP //
  // const borderSVG = openInfoOverlayBtn.querySelector("svg");
  // const borderShape = borderSVG.querySelector("rect");
  // const borderPerimeter = calc_svgShapePerimeter(borderShape, { shapeType: "rect" });
  // WIP //

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
