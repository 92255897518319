// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { ScrollTrigger } from "gsap/ScrollTrigger";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTriggers", inline: true, disabled: false };
const msgs = {
  start: ["init. scrollTriggers"],
  end: ["init. scrollTriggers", "init. success"],
  on_ctaCard_enter: ["CTA card entered viewport"],
  on_ctaCard_leave: ["CTA card left viewport"],
  on_ctaCard_active: ["CTA card active"],
  on_ctaCard_inactive: ["CTA card inactive"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTriggers() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Mobile CTA card scroll trigger (expands card on viewport enter) //
  const { cards } = this.ref;
  const cards_cta = cards?.filter((el) => el.dataset.type === "cta");
  if (cards_cta && cards_cta.length > 0) {
    cards_cta.forEach((el_card) => {
      ScrollTrigger.create({
        trigger: el_card,
        start: "top center",
        end: "bottom center",
        onToggle: ({ isActive }) => {
          if (isActive) {
            api.expand_cardBg(el_card);
            api.expand_cardClipPath(el_card);
          } else {
            api.contract_cardBg(el_card);
            api.contract_cardClipPath(el_card);
          }
        },
      });
    });
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
