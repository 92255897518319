// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_click", inline: true };
const msgs = {
  start: ["card was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_click(CARDEL, EVENTDATA) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { is_mobile } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Enable clicked input or textarea (if applicable)...
  const clickTarget = EVENTDATA.target;
  const isInput = clickTarget.tagName === "INPUT";
  const isTextarea = clickTarget.tagName === "TEXTAREA";
  if (isInput || isTextarea) clickTarget.focus();

  // Trigger click on submit-btn. (if applicable)...
  const isSubmit = clickTarget.tagName === "BUTTON";
  if (isSubmit) clickTarget.click();

  // If on mobile, toggle card follower...
  if (is_mobile) {
    const has_follower = CARDEL.getAttribute("data-with-follower") === "true";
    if (has_follower) {
      const follower_isHidden = CARDEL.getAttribute("data-follower-hidden") === "true";
      if (follower_isHidden) api.show_cardFollower(CARDEL);
      else api.hide_cardFollower(CARDEL);
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
