// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";
import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_click", inline: true };
const msgs = {
  start: ["list item was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_click(ITEMEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { infoDisplay } = this.ref;
  const { is_mobile, infoDisplayState } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////// Active list item & info display updt. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Updt. active-list-item state...
  // - Updt. info-display state...
  //   - Text
  //   - Visibility
  //   - Colors (from item el. data-attr.)
  //   - No-text illu. visibility
  //   - Active illu. index (cycle through illus. if to be shown)
  //   - Placeholder text visibility
  const itemID = ITEMEL.dataset.id;
  const itemInfoText = ITEMEL.dataset.infoText;
  const itemContributorNames = ITEMEL.dataset?.contributors;
  const infoDate = ITEMEL.dataset.date;
  const has_contributors = itemContributorNames !== undefined && itemContributorNames.length > 0;
  const with_placeholder = ITEMEL.dataset.withPlaceholder === "true";
  const show_illu = !valid_str(itemInfoText) || with_placeholder;
  const activeIlluIndex_curr = infoDisplayState.activeIlluIndex;
  this.setState({
    activeListItem: { id: itemID },
    infoDisplayState: {
      text: itemInfoText,
      contributors: has_contributors ? JSON.parse(itemContributorNames) : [],
      date: infoDate,
      colors: {
        bg: ITEMEL.dataset.infoBgColor,
        txt: ITEMEL.dataset.infoTextColor,
        txt_contributors: ITEMEL.dataset.contributorTextColor,
        bg_contributors: ITEMEL.dataset.contributorBgColor,
      },
      is_hidden: false,
      show_illu_noText: show_illu,
      activeIlluIndex: show_illu ? (activeIlluIndex_curr + 1) % 3 : activeIlluIndex_curr, // ← cycle through illus.
      show_placeholderText: with_placeholder,
    },
  });

  ///////////////////////////////// Info display position updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  let position = {};

  //////////////////////////// Mobile screens ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (is_mobile) {
    // Calc. position in center of viewport...
    const { innerWidth: w_vp, innerHeight: h_vp } = window;
    const { width: w_widget, height: h_widget } = infoDisplay.getBoundingClientRect();
    const x = Math.floor(w_vp / 2 - w_widget / 2);
    const y = Math.floor(h_vp / 2 - h_widget / 2);
    position = { x, y };
  }

  /////////////////////// Tablet & desktop screens ///////////////////////
  ////////////////////////////////////////////////////////////////////////
  else {
    // Calc. random position anywhere on screen (with padding on all sides)...
    position = calc_randomElPosition(infoDisplay, { padding: 100 });
  }

  // Updt. info-display state w/ new position...
  const infoDisplayState_new = { ...this.state.infoDisplayState, position };
  this.setState({ infoDisplayState: infoDisplayState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
