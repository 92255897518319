// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger.js";
import set_state from "../../baseUtilities/state/set_state";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_breakpoints from "./init/init_breakpoints.js";
import init_footnotes from "./init/init_footnotes.js";
import init_footnotePositions from "./init/init_footnotePositions.js";
import init_footnoteInteractions from "./init/init_footnoteInteractions.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js"; // DEPRECATED
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_swup_enable from "./eventbusHandlers/ebh_swup_enable.js";
import ebh_swup_content_replace from "./eventbusHandlers/ebh_swup_content_replace.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode";

import set_footnotePos from "./api/set_footnotePos.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logStyles = {
  default: "#4361BC",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00ff00",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default class PostFootnotes {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: { run_withLogs: false, logStyles: logStyles },
      customHandlers: { stateChange: [] },
    },
    OPTIONS
  ) {
    ///////////////////////// Config. //////////////////////////
    ////////////////////////////////////////////////////////////

    this.config = {
      customHandlers: CONFIG?.customHandlers ?? { stateChange: [] },
    };

    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "PostFootnotes",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_breakpoints = init_breakpoints.bind(this);
    this.init_footnotes = init_footnotes.bind(this);
    this.init_footnotePositions = init_footnotePositions.bind(this);
    this.init_footnoteInteractions = init_footnoteInteractions.bind(this);

    ///////////////////////// Modules //////////////////////////
    ////////////////////////////////////////////////////////////

    this.modules = {};

    /////////////////////////// API ////////////////////////////
    ////////////////////////////////////////////////////////////

    this.api = {
      set_footnotePos: set_footnotePos.bind(this),
    };

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    // this.ebl_swup_page_load = ebh_swup_page_load.bind(this); // DEPRECATED
    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_swup_enable = ebh_swup_enable.bind(this);
    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);

    ////////////////// State-change listeners //////////////////
    ////////////////////////////////////////////////////////////

    this.stChH_mode = stChH_mode.bind(this);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_breakpoints();
    // this.init_state(); // NOT NEEDED ATM.
    this.init_eventbus();
    this.setState({ initialised: true });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // NOT NEEDED ATM. //
  // init_state() {
  //   this.logger("init", ["state"], "default", { inline: true });
  // }
  // NOT NEEDED ATM. //

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    // eventbus.on("swup_page_load", this.ebl_swup_page_load); // DEPRECATED
    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("swup_enable", this.ebl_swup_enable);
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    // ...
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    if ("mode" in CHANGES) this.stChH_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
