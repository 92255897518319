// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_JSONstr from "../../../../../app/baseUtilities/validate/validate_JSONstring.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_eventCard_click from "../eventHandlers/eh_eventCard_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_eventCards", inline: true };
const msgs = {
  start: ["init. eventCards"],
  end: ["init. eventCards", "init. success"],
  no_el: ["init. eventCards", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_eventCards() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { eventCards } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (eventCards === undefined) return this.cancel_featInit(msgs.no_el);
  if (eventCards.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Event hdl...
  eventCards.forEach((el) => el.addEventListener("click", eh_eventCard_click.bind(this, el)));

  // Mobile content scroller init...
  eventCards.forEach((el) => this.init_eventCardContentScroller(el));

  // Set init. event card states...
  const eventCardStates = eventCards.map((el, i) => ({
    el,
    ...el.dataset,
    dates: valid_JSONstr(el.dataset.dates) ? JSON.parse(el.dataset.dates) : [],
    is_collapsed: el.dataset.isCollapsed === "true",
    is_activeOnLoad: el.dataset.isActiveOnLoad === "true",
  }));
  this.setState({ eventCardStates });

  // Scroll to active-on-load card...
  const activeOnLoadCard = eventCardStates.find((card) => card.is_activeOnLoad);
  if (activeOnLoadCard) {
    setTimeout(() => {
      const cardRect = activeOnLoadCard.el.getBoundingClientRect();
      const cardTop = activeOnLoadCard.el.offsetTop;
      const y = cardTop + cardRect.height / 2 - window.innerHeight / 2;
      window.scrollTo({ top: y, left: 0, behavior: "instant" });
      this.api.blockScroll();
      activeOnLoadCard.is_activeOnLoad = false; // Reset flag...
    }, 100);
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
