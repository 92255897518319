// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_stateChange", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`is_enlarged: ${CHANGE}`],
  no_change: ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_is_enlarged(CHANGES) {
  if (!("is_enlarged" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.is_enlarged))
    return this.logger("error", msgs.inval_stateValue(CHANGES.is_enlarged), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_enlarged } = CHANGES;
  const { widget, enlargeBtn } = this.ref;
  const flickityInst = this.modules.flickity.instance;
  this.logger("state-change", msgs.stateChange(is_enlarged), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////// Widget is-large attr. updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Updt. data-is-large attr. of widget...
  // - Trigger resize on flickity inst...
  widget.setAttribute("data-is-large", is_enlarged);
  flickityInst.resize();

  ////////////////// Enlarge btn. is-active attr. updt. //////////////////
  ////////////////////////////////////////////////////////////////////////

  enlargeBtn.setAttribute("data-is-active", is_enlarged);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
