// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_widgetStates_onEventCardsChange", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "updt_widgetStates_onEventCardsChange"],
  end: ["EventCalendar", "API", "updt_widgetStates_onEventCardsChange", "API call complete"],
  no_eventCardStates: ["EventCalendar", "API", "updt_widgetStates_onEventCardsChange", "no eventCardStates found"],
  no_widgetStates: ["EventCalendar", "API", "updt_widgetStates_onEventCardsChange", "no widgetStates found"],
};
const defData = { eventCardStates: null };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_widgets_onEventCardsChange(DATA = defData) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const data = { ...defData, ...DATA };
  const eventCardStates = data.eventCardStates || this.state.eventCardStates;
  const { widgetStates, is_tablet_prt, is_mobile, is_tablet_ls } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!eventCardStates) return this.logger("warning", msgs.no_eventCardStates, "warning", logArgs);
  if (!widgetStates) return this.logger("warning", msgs.no_widgetStates, "warning", logArgs);

  // Find active event card...
  const activeEventCard = eventCardStates.find((s) => !s.is_collapsed);

  // Extract widget states...
  const dateWidgetState = widgetStates.dateWidget;
  const marqueeWidgetState = widgetStates.marqueeWidget;
  const descriptWidgetState = widgetStates.descriptionWidget;
  const imgWidgetState = widgetStates.imgWidget;
  const slideShowWidgetState = widgetStates.slideShowWidget;
  const locationWidgetState = widgetStates.locationWidget;
  const ticketLinkWidgetState = widgetStates.ticketLinkWidget;
  const characterWidgetState = widgetStates.characterWidget;

  //////////////////////////////////////// Widgets reveal ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (activeEventCard) {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { eventType, eventTypeColors, dates } = activeEventCard;
    const { eventLocation, eventLocationLong, eventDescription } = activeEventCard;
    const { eventExtraImgs, eventImgCredits, eventImgCaptions } = activeEventCard;
    const { mapsLink, ticketLink } = activeEventCard;

    const has_descript = valid_str(eventDescription);
    const has_mapsLink = valid_str(mapsLink) && mapsLink !== "false";
    const has_location = valid_str(eventLocation);
    const has_ticketLink = valid_str(ticketLink) && ticketLink !== "false";

    const extraImgs_parsed = eventExtraImgs ? JSON.parse(eventExtraImgs) : [];
    const eventImgCredits_parsed = eventImgCredits ? JSON.parse(eventImgCredits) : [];
    const eventImgCaptions_parsed = eventImgCaptions ? JSON.parse(eventImgCaptions) : [];
    const has_singleExtraImg = extraImgs_parsed.length === 1;
    const has_multipleExtraImgs = extraImgs_parsed.length > 1;

    // Parse event type colors (to be sent to marquee widget)...
    const eventTypeColors_parsed = eventTypeColors ? JSON.parse(eventTypeColors) : { bg: "#000", fg: "#fff" };

    // Set init. next-widget position (left || right)...
    let nextWidgetPosition = Math.random() > 0.5 ? "left" : "right";

    /////////////////////// Description widget updt. ///////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Make updt. descript. widget state...
    // (only when on screen > tablet-portrait)
    if (!is_mobile && !is_tablet_prt) {
      const text = has_descript ? eventDescription : null;
      const descriptionWidgetState_new = { ...descriptWidgetState, is_hidden: !has_descript, text };
      if (has_descript) descriptionWidgetState_new.pos = nextWidgetPosition;

      // Set descript. widget state & position...
      this.setState({ widgetStates: { ...this.state.widgetStates, descriptionWidget: descriptionWidgetState_new } });
      if (has_descript) api.set_widgetPosition(descriptWidgetState.el, { position: nextWidgetPosition });

      // On mobile & tablet, disable descript. widget drag...
      const { dragInst } = descriptionWidgetState_new;
      if (dragInst) {
        if (is_mobile || is_tablet_ls) dragInst[0].disable();
        else dragInst[0].enable();
      }
    }

    ////////////////////////// Date widget updt. ///////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (!is_mobile) {
      // Make updt. date widget state...
      const date_start = dates.start;
      const date_end = dates.end;
      const dateWidgetState_new = { ...dateWidgetState, is_hidden: false, date_start, date_end };

      // Set date widget state & position...
      this.setState({ widgetStates: { ...this.state.widgetStates, dateWidget: dateWidgetState_new } });
      nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
      api.set_widgetPosition(dateWidgetState.el, {
        position: nextWidgetPosition,
        with_follower: true,
        followerEl: this.ref.widgetFollower_date,
        followerAlign: "behind-right",
      });
    }

    //////////////////////// Location widget updt. /////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (!is_mobile) {
      // Make updt. location widget state...
      const locationWidgetState_new = {
        ...locationWidgetState,
        text: eventLocation,
        text_long: eventLocationLong != eventLocation ? eventLocationLong : null,
        mapsLink: has_mapsLink ? mapsLink : null,
        is_hidden: !has_location,
      };

      // Set location widget state & position...
      this.setState({ widgetStates: { ...this.state.widgetStates, locationWidget: locationWidgetState_new } });
      if (has_location) {
        nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
        api.set_widgetPosition(locationWidgetState.el, {
          position: nextWidgetPosition,
          with_follower: true,
          followerEl: this.ref.widgetFollower_location,
          followerAlign: "below-right",
        });
      }
    }

    ////////////////////////// Img. widget updt. ///////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (!is_mobile) {
      // Make updt. img. widget state...
      const imgSrc = has_singleExtraImg ? extraImgs_parsed[0] : null;
      const imgCredit = has_singleExtraImg ? eventImgCredits_parsed[0] : null;
      const imgCaption = has_singleExtraImg ? eventImgCaptions_parsed[0] : null;
      const imgWidgetState_new = { ...imgWidgetState, is_hidden: !has_singleExtraImg, imgSrc, imgCredit, imgCaption };

      // Set img. widget state & position...
      this.setState({ widgetStates: { ...this.state.widgetStates, imgWidget: imgWidgetState_new } });
      if (has_singleExtraImg) {
        nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
        api.set_widgetPosition(imgWidgetState.el, {
          position: nextWidgetPosition,
          with_follower: true,
          followerEl: this.ref.widgetFollower_img,
          followerAlign: "below-right",
        });
      }
    }

    //////////////////////// SlideShow widget updt. ////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (!is_mobile) {
      // Make updt. slideShow widget state...
      const imgSrcs = has_multipleExtraImgs ? extraImgs_parsed : [];
      const imgCredits = has_multipleExtraImgs ? eventImgCredits_parsed : [];
      const slideShowWidgetState_new = { ...slideShowWidgetState, is_hidden: !has_multipleExtraImgs, imgSrcs, imgCredits };

      // Set slideShow widget state & position...
      this.setState({ widgetStates: { ...this.state.widgetStates, slideShowWidget: slideShowWidgetState_new } });
      if (has_multipleExtraImgs) {
        nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
        api.set_widgetPosition(slideShowWidgetState.el, { position: nextWidgetPosition });
      }
    }

    ///////////////////////// Marquee widget updt. /////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (!is_mobile) {
      // Make updt. marquee widget state...
      const marqueeText = eventType;
      const marqueeWidgetState_new = {
        ...marqueeWidgetState,
        is_hidden: false,
        text: marqueeText,
        colors: eventTypeColors_parsed,
      };

      // Set marquee widget state & position...
      this.setState({ widgetStates: { ...this.state.widgetStates, marqueeWidget: marqueeWidgetState_new } });
      nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
      api.set_widgetPosition(marqueeWidgetState.el, { position: nextWidgetPosition });
    }

    /////////////////////// TicketLink widget updt. ////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Make updt. ticketLink widget state...
    const ticketLinkWidgetState_new = {
      ...ticketLinkWidgetState,
      ticketLink: has_ticketLink ? ticketLink : null,
      is_hidden: !has_ticketLink,
    };

    // Set ticketLink widget state & position...
    this.setState({ widgetStates: { ...this.state.widgetStates, ticketLinkWidget: ticketLinkWidgetState_new } });
    if (has_ticketLink) {
      nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
      api.set_widgetPosition(ticketLinkWidgetState.el, { position: nextWidgetPosition });
    }

    //////////////////////// Character widget updt. ////////////////////////
    ////////////////////////////////////////////////////////////////////////

    if (!is_mobile) {
      // Random bool to determine whether to show character widget...
      const showCharWidget = Math.random() > 0.5;

      if (showCharWidget) {
        // Make updt. character widget state...
        const characterWidgetState_new = { ...characterWidgetState, is_hidden: false };

        // Updt. active illu. to random int. btw. 0 and 1...
        const activeIllu = Math.floor(Math.random() * 2);
        characterWidgetState_new.activeIllu = activeIllu;

        // Set character widget state & position...
        nextWidgetPosition = nextWidgetPosition === "left" ? "right" : "left"; // ← alternate position
        this.setState({ widgetStates: { ...this.state.widgetStates, characterWidget: characterWidgetState_new } });
        api.set_widgetPosition(characterWidgetState.el, { position: nextWidgetPosition });
      }
    }
  }

  ///////////////////////////////////////// Widgets hide /////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  else {
    // Hide all widgets...
    const dateWidgetState_new = { ...dateWidgetState, is_hidden: true };
    const marqueeWidgetState_new = { ...marqueeWidgetState, is_hidden: true };
    const descriptionWidgetState_new = { ...descriptWidgetState, is_hidden: true };
    const imgWidgetState_new = { ...imgWidgetState, is_hidden: true };
    const slideShowWidgetState_new = { ...slideShowWidgetState, is_hidden: true };
    const locationWidgetState_new = { ...locationWidgetState, is_hidden: true };
    const ticketLinkWidgetState_new = { ...ticketLinkWidgetState, is_hidden: true };
    const characterWidgetState_new = { ...characterWidgetState, is_hidden: true };
    this.setState({
      widgetStates: {
        ...widgetStates,
        dateWidget: dateWidgetState_new,
        marqueeWidget: marqueeWidgetState_new,
        descriptionWidget: descriptionWidgetState_new,
        imgWidget: imgWidgetState_new,
        slideShowWidget: slideShowWidgetState_new,
        locationWidget: locationWidgetState_new,
        ticketLinkWidget: ticketLinkWidgetState_new,
        characterWidget: characterWidgetState_new,
      },
    });
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
