// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_readMoreBtn_click", inline: true };
const msgs = {
  start: ["an event occurred"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_readMoreBtn_click(BTNEL, EVENTDATA) {
  // Setup...
  const { sections } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  // Scroll to second section via ebc. to WindowScroll mod...
  const secondSectEl = sections[1];
  const secondSectElTextBoxEl = secondSectEl.querySelector("[data-ref='textBox']");
  eventbus.emit("WindowScroll.api.scroll_toTarget", {
    target: secondSectElTextBoxEl,
    config: { topOffset: (window.innerHeight / 2) * 0.925 },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
