// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.reset", inline: true };
const msgs = {
  start: ["ContactForm", "API", "reset"],
  noJustValidInst: ["no justValidate instance found"],
  noMssgField: ["init. justValidate", "no message field found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function reset() {
  // Setup...
  const validInst = this.modules.justValidate.instance;
  const { messageField } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);
  if (!validInst) return this.logger("event", msgs.noJustValidInst, "error", logArgs);
  if (!this.validate_refEl(messageField)) return this.cancel_featInit(msgs.noMssgField);

  // Reset form...
  messageField.value = "";
  validInst.refresh();

  // Reset mode...
  this.setState({ mode: "ready" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
