// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_enterMatrixAnswerViewerBtn_click from "../eventHandlers/eh_enterMatrixAnswerViewerBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_enterMatrixAnswerViewerBtn", inline: true };
const msgs = {
  start: ["init. enterMatrixAnswerViewerBtn"],
  end: ["init. enterMatrixAnswerViewerBtn", "init. success"],
  no_el: ["init. enterMatrixAnswerViewerBtn", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_enterMatrixAnswerViewerBtn() {
  // Setup...
  const { enterMatrixAnswerViewerBtn } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(enterMatrixAnswerViewerBtn)) return this.cancel_featInit(msgs.no_el);

  // Event hdl...
  enterMatrixAnswerViewerBtn.addEventListener("click", eh_enterMatrixAnswerViewerBtn_click.bind(this));

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
