// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_instructCardStates_drawing", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`instructCardStates_drawing:`, CHANGE],
  no_change: ["No change to state provided."],
  no_someEl: ["state change hdl.", "stateChange", "no some el. found"],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_instructCardStates_drawing(CHANGES) {
  if (!("instructCardStates_drawing" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { instructCardStates_drawing } = CHANGES;
  this.logger("state-change", msgs.change(instructCardStates_drawing), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Process instruct card states to animate card position changes...
  instructCardStates_drawing.forEach((cardState, i) => {
    const pos = cardState.pos;
    gsap.to(cardState.el, { x: pos.x, y: pos.y, duration: 0.5, ease: "power2.inOut" });
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
