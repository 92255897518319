// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import SweetScroll from "sweet-scroll";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const msgs = {
  start: ["PartiForm", "API", "scrollTo_subsection"],
  end: ["PartiForm", "API", "scrollTo_subsection", "API call complete"],
  no_selector: ["PartiForm", "API", "scrollTo_subsection", "no section selector provided"],
  no_subsectionEl: ["PartiForm", "API", "scrollTo_subsection", "no subsection el. found"],
  no_overflowWrapperEl: ["PartiForm", "API", "scrollTo_subsection", "no overflow wrapper el. found"],
};

const defConfig = {
  duration: 1000,
  scrollTo_bottom: false,
  addOffset: 0,
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function scrollTo_subsection(SELECTOR_subsection, CONFIG = defConfig) {
  return new Promise((resolve) => {
    //////////////////////////////////////////// Setup /////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const config = { ...defConfig, ...CONFIG };
    const { duration, scrollTo_bottom, addOffset } = config;
    this.logger("event", msgs.start, "event", logArgs);

    // Guard...
    if (!valid_str(SELECTOR_subsection)) return this.logger("error", msgs.no_selector, "error", logArgs);

    //////////////////////////////////////////// ACTION ////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // Find section el. (+ guard)...
    const sectionEl = document.querySelector(SELECTOR_subsection);
    if (!sectionEl) return this.logger("error", msgs.no_subsectionEl, "error", logArgs);

    // Find overflow wrapper el. (+ guard)...
    const overflowWrapperEl = sectionEl.closest(".fp-overflow");
    if (!overflowWrapperEl) return this.logger("error", msgs.no_overflowWrapperEl, "error", logArgs);

    // Determ. top edge of subsection el. relative to overflow wrapper el....
    const topOffset = !scrollTo_bottom
      ? sectionEl.offsetTop - overflowWrapperEl.offsetTop
      : sectionEl.offsetTop - overflowWrapperEl.offsetTop + sectionEl.clientHeight - overflowWrapperEl.clientHeight;

    // - Create temp. SweetScroll instance...
    // - Scroll to subsection...
    const sweetScrollInst = new SweetScroll(
      { duration, complete: () => (resolve(), sweetScrollInst.destroy()) },
      overflowWrapperEl
    );
    sweetScrollInst.to(topOffset + addOffset);

    /////////////////////////////////////////// Conclude ///////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    this.logger("success", msgs.end, "success", logArgs);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
