// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.process_widgetStateUpdt_date", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "process_widgetStateUpdt_date"],
  end: ["EventCalendar", "API", "process_widgetStateUpdt_date", "API call complete"],
  no_widgetEl: ["EventCalendar", "API", "process_widgetStateUpdt_date", "No widget element found"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function process_widgetStateUpdt_date(WIDGETSTATE) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { el: widgetEl } = WIDGETSTATE;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!widgetEl) return this.logger("error", msgs.no_widgetEl, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. data-is-hidden attr...
  if ("is_hidden" in WIDGETSTATE) {
    const { is_hidden, followerEl } = WIDGETSTATE;
    widgetEl.setAttribute("data-is-hidden", is_hidden);
    if (!is_hidden) this.api.bringWidgetToFront(widgetEl);

    // Hide follower el...
    if (followerEl && is_hidden) followerEl.setAttribute("data-is-hidden", "true");
  }

  // Updt. start date text...
  if ("date_start" in WIDGETSTATE) {
    const { date_start } = WIDGETSTATE;
    const monthDisplay = widgetEl.querySelector("[data-ref='monthDisplay']");
    const dayDisplay = widgetEl.querySelector("[data-ref='dayDisplay']");
    const timeDisplay = widgetEl.querySelector("[data-ref='timeDisplay']");
    if (monthDisplay && monthDisplay.textContent != date_start.month) monthDisplay.textContent = date_start.month;
    if (dayDisplay && dayDisplay.textContent != date_start.day) dayDisplay.textContent = date_start.day;
    if (timeDisplay && timeDisplay.textContent != date_start.time) timeDisplay.textContent = date_start.time;
  }

  // Updt. end date text...
  if ("date_end" in WIDGETSTATE) {
    const { date_end, followerEl, is_hidden } = WIDGETSTATE;
    if (!followerEl) return;
    if (date_end) {
      if (!is_hidden) followerEl.setAttribute("data-is-hidden", "false");
      const monthDisplay = followerEl.querySelector("[data-ref='monthDisplay']");
      const dayDisplay = followerEl.querySelector("[data-ref='dayDisplay']");
      const timeDisplay = followerEl.querySelector("[data-ref='timeDisplay']");
      if (monthDisplay && monthDisplay.textContent != date_end.month) monthDisplay.textContent = date_end.month;
      if (dayDisplay && dayDisplay.textContent != date_end.day) dayDisplay.textContent = date_end.day;
      if (timeDisplay && timeDisplay.textContent != date_end.time) timeDisplay.textContent = date_end.time;
    } else followerEl.setAttribute("data-is-hidden", "true");
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
