// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// import interpolate_HSLcolorBrightness from "../util/interpolate_HSLcolorBrightness";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTrigger_introSection", inline: true };
const msgs = {
  start: ["init. scrollTrigger_introSection"],
  end: ["init. scrollTrigger_introSection", "init. success"],
  no_sectionEl: ["no valid section el. found"],
  no_subsectionEl: ["no valid subsection el. found"],
  no_overflowEl: ["no valid overflow el. found"],
  on_enterBack: ["PartiForm", "scrolling back into intro section"],
  stop_mobile: ["scrollTrigger_introSection", "stop. on mobile"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTrigger_introSection() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { sections, subsections } = this.ref;
  const section_intro = sections?.find((el) => el.dataset.id === "intro");
  const subsection_start = subsections?.find((el) => el.dataset.id === "start");
  const overflowWrapperEL = section_intro?.querySelector(".fp-overflow");
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(section_intro)) return this.cancel_featInit(msgs.no_sectionEl);
  if (!this.validate_refEl(subsection_start)) return this.cancel_featInit(msgs.no_subsectionEl);
  if (!this.validate_refEl(overflowWrapperEL)) return this.cancel_featInit(msgs.no_overflowEl);

  /////////////////////////////// Start-subsection scroll trigger ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create scroll trigger on overflow el...
  const scrollTriggerInst_intro = ScrollTrigger.create({
    trigger: subsection_start,
    scroller: overflowWrapperEL,
    start: "top top",
    end: "bottom bottom",

    ////////////////////////////// On update ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    onUpdate: (EVENTDATA) => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // When scrolled 10% into intro section, hide intro bg. illu...
      const { progress } = EVENTDATA;
      if (progress > 0.1) api.hide_bgIllu("intro");
      else api.show_bgIllu("intro");

      // Updt. page header headline-stretch (via eventbus)...
      eventbus.emit("windowScroll_stCh_scrollPosition", { scrollPercentage: progress * 100 });
    },

    ///////////////////// On return (scroll back into) /////////////////////
    ////////////////////////////////////////////////////////////////////////

    onEnterBack: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_enterBack, "event", { eventName: "*", inline: true });

      // Trans. bg. color back to original...
      api.set_pageBgColor("#a200ff");
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.modules.scrollTriggers.intro = scrollTriggerInst_intro;
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
