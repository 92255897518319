// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_filterBtn_click from "../eventHandlers/eh_filterBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_filterMenu", inline: true };
const msgs = {
  start: ["init. filterMenu"],
  end: ["init. filterMenu", "init. success"],
  no_filterMenuEl: ["init. filterMenu", "no filterMenu el. found"],
  no_openFilterMenuBtnEl: ["init. filterMenu", "no openFilterMenuBtn el. found"],
  no_filterBtnEls: ["init. filterMenu", "no filterBtn els. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_filterMenu() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { openFilterMenuBtn, filterMenu, filterBtns } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(filterMenu)) return this.cancel_featInit(msgs.no_filterMenuEl);
  if (!this.validate_refEl(openFilterMenuBtn)) return this.cancel_featInit(msgs.no_openFilterMenuBtnEl);
  if (filterBtns === undefined) return this.cancel_featInit(msgs.no_filterBtnEls);
  if (filterBtns.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_filterBtnEls);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Open filter menu btn. click-event hdl. //
  openFilterMenuBtn?.addEventListener("click", () => {
    const { filterMenuState } = this.state;
    this.setState({ filterMenuState: { ...filterMenuState, is_open: !filterMenuState.is_open } });
  });

  // Filter btn. click-event hdl. //
  filterBtns
    .filter((btnEl) => this.validate_refEl(btnEl))
    .forEach((btnEl) => btnEl.addEventListener("click", eh_filterBtn_click.bind(this, btnEl)));

  // Measure filter menu height to use as bottom-when-centered position on filterMenuWrapper
  const filterMenuRect = filterMenu.getBoundingClientRect();
  const h_filterMenu = filterMenuRect.height;

  // Set initial state...
  this.setState({
    filterMenuState: {
      is_hidden: false,
      is_open: false,
      position: "bottom",
      h_filterMenu,
    },
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
