// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeMonthFilters", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`active month filters:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeMonthFilters(CHANGES) {
  if (!("activeMonthFilters" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeMonthFilters } = CHANGES;
  const { activeContextFilters } = this.state;
  const { monthFilterBtns } = this.ref;
  this.logger("state-change", msgs.stateChange(activeMonthFilters), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////// Visible event card filtering /////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.api.filter_eventCards(activeMonthFilters, activeContextFilters);

  /////////////// Month-filter btns. is-active attr. updt ////////////////
  ////////////////////////////////////////////////////////////////////////

  monthFilterBtns.forEach((btn) => btn.setAttribute("data-is-active", activeMonthFilters.includes(btn.dataset.filterId)));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
