// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_slideChange from "../eventHandlers/eh_slideChange.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_flickity", inline: true };
const msgs = {
  start: ["init. flickity"],
  end: ["init. flickity", "init. success"],
  no_el: ["init. flickity", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_flickity() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { slidesWrapper } = this.ref;
  const { with_widget = false } = this.options;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(slidesWrapper)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create Flickity instance...
  const flickityInst = new Flickity(slidesWrapper, {
    prevNextButtons: false,
    pageDots: false,
    //////
    wrapAround: true,
    cellAlign: "center",
    adaptiveHeight: true,
    draggable: false,
    //////
    imagesLoaded: true,
    //////
    selectedAttraction: 0.025,
    friction: 0.28,
    //////
    on: {
      change: (INDEX) => eh_slideChange.bind(this)(INDEX),
      ready: () => {
        if (with_widget) this.init_widget();
      },
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.modules.flickity.instance = flickityInst;
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
