// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.blockFullpageScroll", inline: true };
const msgs = {
  start: ["PartiForm", "API", "blockFullpageScroll"],
  noSectionGroupID: ["PartiForm", "API", "show_section", "no section group ID provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function blockFullpageScroll() {
  // Setup...
  const fullpageInst = this.modules.fullpage.instance;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!fullpageInst) return this.logger("error", msgs.noFullpageInst, "error", logArgs);

  // Block fullpage scroll...
  fullpageInst.setAllowScrolling(false);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
