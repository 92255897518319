// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getHeight } from "mezr";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_scrollTrigger_keynotesSection", inline: true };
const logArgs_wildcard = { eventName: "*", inline: true };
const msgs = {
  start: ["init. scrollTrigger_keynotesSection"],
  end: ["init. scrollTrigger_keynotesSection", "init. success"],
  no_sectionEl: ["no valid section el. found"],
  no_subsectionEl: ["no valid subsection el. found"],
  no_overflowEl: ["no valid overflow el. found"],
  on_enter: ["PartiForm", "scrolling into keynotes section"],
  on_enterBack: ["PartiForm", "scrolling back into keynotes section"],
  on_leave: ["PartiForm", "scrolling out of keynotes section"],
  on_leaveBack: ["PartiForm", "scrolling backwards out of keynotes section"],
  stop_mobile: ["scrollTrigger_keynotesSection", "stop. on mobile"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_scrollTrigger_keynotesSection() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { sectionHeadlines } = this.options;
  const { sections, subsections } = this.ref;
  const { keynotes, keynotes_floating, keynoteExplainers } = this.ref;
  const section_intro = sections?.find((el) => el.dataset.id === "intro");
  const subsection_keynotes = subsections?.find((el) => el.dataset.id === "keynotes");
  const overflowWrapperEL = section_intro?.querySelector(".fp-overflow");
  const pageHeaderEl = document.querySelector("[g-component='SubpageHeader']");
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(section_intro)) return this.cancel_featInit(msgs.no_sectionEl);
  if (!this.validate_refEl(subsection_keynotes)) return this.cancel_featInit(msgs.no_subsectionEl);
  if (!this.validate_refEl(overflowWrapperEL)) return this.cancel_featInit(msgs.no_overflowEl);

  //////////////////////////////// Scroll trigger creation ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const scrollTrigger_keynotes = ScrollTrigger.create({
    trigger: subsection_keynotes,
    scroller: overflowWrapperEL,
    start: "top center",
    end: "bottom top",

    ////////////////////////////// On update ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    onUpdate: (EVENTDATA) => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      ////// Page header hide/reveal on scroll ///////
      ////////////////////////////////////////////////

      // Calc. & use scrolled pixels to hide/show page header...
      const { start, end, progress } = EVENTDATA;
      const dir = EVENTDATA.direction === 1 ? "down" : "up";
      if (pageHeaderEl) {
        const scrolledPixels = Math.round(progress * (end - start));
        const h_header = getHeight(pageHeaderEl) + 10; // ← 10px extra padding

        // Reset page header hidden-offscreen attr. when scrolling back up...
        if (dir === "up" && scrolledPixels < h_header) pageHeaderEl.setAttribute("data-hidden-offscreen", "false");

        // Progressively hide/reveal page header in synch. with scroll...
        if (scrolledPixels <= h_header) pageHeaderEl.style.transform = `translateY(${-scrolledPixels}px)`;
        else {
          pageHeaderEl.style.transform = `translateY(${-h_header}px)`;
          pageHeaderEl.setAttribute("data-hidden-offscreen", "true");
        }
      }

      ////////////// Keynote el. anim. ///////////////
      ////////////////////////////////////////////////

      keynotes.forEach((knEl) => {
        const keyId = knEl.dataset.id;
        const relatedFloatingEl = keynotes_floating.find((el) => el.dataset.id === keyId);
        const keyRect = knEl.getBoundingClientRect();

        //////////////////////////////////////
        // Floating keynote el. reveal/hide //
        //////////////////////////////////////

        const keynoteEl_is_atTop = keyRect.top <= 10 && relatedFloatingEl;
        knEl.style.opacity = keynoteEl_is_atTop ? 0 : 1;
        relatedFloatingEl.setAttribute("data-is-hidden", keynoteEl_is_atTop ? "false" : "true");

        ////////////////////////////////////////////
        // Floating keynote el. left/center shift //
        ////////////////////////////////////////////

        // If corresp. explainer el. is scrolled past center of viewport,
        // shift floating keynote el.to left...
        const relatedExplEl = keynoteExplainers.find((el) => el.dataset.id === keyId);
        const explRect = relatedExplEl.getBoundingClientRect();
        const explCenter = explRect.top + explRect.height / 2;
        const vpCenter = window.innerHeight / 2;

        if (explCenter < vpCenter) {
          // Use keynote rect. left edge as reference point to determ. how much to move to left...
          const distToLeftVpEdge = keyRect.left - 10; // ← 10px from left edge of vp.

          // Check if any previous floating el. exists to determ. how much to move down...
          let distToMoveDown = 0;
          const prevKeynoteEl = keynotes_floating.find((el) => el.dataset.id === `${parseInt(keyId) - 1}`);
          if (prevKeynoteEl) {
            const prevKeynoteElRect = prevKeynoteEl.getBoundingClientRect();
            distToMoveDown = prevKeynoteElRect.bottom; // ← 10px from bottom of prev. floating el.
          }

          gsap.to(relatedFloatingEl, { x: `-${distToLeftVpEdge}px`, y: distToMoveDown, duration: 0.5 });
        } else {
          gsap.to(relatedFloatingEl, { x: "0", y: "0", duration: 0.5 });
        }
      });
    },

    ////////////////////// On enter (scrolling into) ///////////////////////
    ////////////////////////////////////////////////////////////////////////

    onEnter: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_enter, "event", logArgs_wildcard);

      // Trans. bg. color to white...
      api.set_pageBgColor("#fff");
    },

    ///////////////////// On return (scroll back into) /////////////////////
    ////////////////////////////////////////////////////////////////////////

    onEnterBack: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_enterBack, "event", logArgs_wildcard);

      // Set form header title to intro section headline...
      const introSectionHeadl = valid_str(sectionHeadlines?.intro) ? sectionHeadlines.intro : "Intro";
      this.setState({ formHeaderState: { ...this.state.formHeaderState, titleText: introSectionHeadl } });

      // Show floating keynote els...
      if (keynotes_floating) gsap.to(keynotes_floating, { opacity: 1, duration: 0.5, stagger: 0.15, ease: "power2.inOut" });
    },

    ///////////////////// On leave (scrolling out of) //////////////////////
    ////////////////////////////////////////////////////////////////////////

    onLeave: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_leave, "event", logArgs_wildcard);

      // Hide floating keynote els...
      if (keynotes_floating) gsap.to(keynotes_floating, { opacity: 0, duration: 0.5, stagger: 0.15, ease: "power2.inOut" });
    },

    //////////////// On leave (scrolling backwards out of) /////////////////
    ////////////////////////////////////////////////////////////////////////

    onLeaveBack: () => {
      // Stop, if on mobile...
      if (this.state.is_mobile) return this.logger("warning", msgs.stop_mobile, "warning", logArgs);

      // Log...
      this.logger("event", msgs.on_leaveBack, "event", logArgs_wildcard);
    },
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.modules.scrollTriggers.keynotes = scrollTrigger_keynotes;
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
