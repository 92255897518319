// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_outro", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_outro"],
  end: ["PartiForm", "API", "processSteps_outro", "API call complete"],
  no_stepString: ["PartiForm", "API", "processSteps_outro", "No step string provided."],
  stepNotAllowed: (step) => ["PartiForm", "API", "processSteps_outro", `step not allowed: ${step}`],
  outroDisabled: ["PartiForm", "API", "processSteps_outro", "Outro is disabled.", "stopping step process"],
};

const possibleSteps = ["startOutro"];

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function processSteps_outro(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_outro) return this.logger("warning", msgs.outroDisabled, "warning", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { step } = DATA;
  const { sectionFooter_outro } = this.ref;
  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  switch (true) {
    ///////////////// "startOutro" /////////////////
    ////////////////////////////////////////////////

    // When starting outro section:
    // - Unblock fullpage scroll...
    // - Reveal + scroll to corresp. section...
    // - Reset current subsection state...
    // - Hide form header...
    // - Reset page background color...
    // - Show section footer
    case step == "startOutro":
      api.releaseFullpageScroll();
      api.show_section("outro");
      this.setState({
        currentSubsection: "null",
        formHeaderState: { ...this.state.formHeaderState, is_hidden: true },
      });
      api.scrollTo_section("outro");
      api.set_pageBgColor("#a200ff");
      setTimeout(() => {
        sectionFooter_outro.setAttribute("data-is-hidden", "false");
      }, 700); // ← let scroll anim. finish (to do: promise based solution)
      break;

    default:
      break;
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
