// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_randomElPosition from "../../../../../app/baseUtilities/calc/calc_randomElPosition";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_position", inline: true, disabled: false };
const msgs = {
  start: ["init. position"],
  end: ["init. position", "init. success"],
  no_el: ["init. position", "no valid DOM el. provided"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_position() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const el_widget = this.element;

  // - Calc. random position for widget...
  // - Calc. random rotation between -30deg and 30deg...
  const randomPos_onScreen = calc_randomElPosition(el_widget);
  const randomRotation = Math.floor(Math.random() * 60) - 30;

  // - Apply random position & rotation to widget...
  // - Show widget, with delay (is hidden on load to prevent visible position change)...
  el_widget.style.transform = `translate(${randomPos_onScreen.x}px, ${randomPos_onScreen.y}px) rotate(${randomRotation}deg)`;
  setTimeout(() => this.setState({ is_hidden: false }), 250);

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
