// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_mouseEnter", inline: true };
const msgs = {
  start: ["mouse entered cart"],
  is_mobile: ["mouse entered card", "while on mobile", "stopping event hdl."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_mouseEnter(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { is_mobile } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard: stop, if on mobile...
  if (is_mobile) return this.logger("warning", msgs.is_mobile, "warning", logArgs);

  ////////////////////////// Card expand. anim. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (CARDEL.dataset.expandOnHover === "true") api.expand_card(CARDEL);

  ///////////////////////// Card follower reveal /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (CARDEL.dataset.withFollower === "true") api.show_cardFollower(CARDEL);

  /////////////// Card SVG backgr./clip path/border expand ///////////////
  ////////////////////////////////////////////////////////////////////////

  if (CARDEL.dataset.hasExpandingBg === "true") {
    // - Expand SVG background...
    // - Expand clip path...
    api.expand_cardBg(CARDEL);
    api.expand_cardClipPath(CARDEL);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
