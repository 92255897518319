// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { computePosition, autoUpdate, offset, arrow } from "@floating-ui/dom";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const set_tooltipPos = (TOOLTIPEL, POS = { x: 0, y: 0 }, MIDDLEWAREDATA) => {
  // Tooltip pos...
  Object.assign(TOOLTIPEL.style, { left: `${POS.x}px`, top: `${POS.y}px` });

  // Arrow pos...
  if (MIDDLEWAREDATA.arrow) {
    const { x, y } = MIDDLEWAREDATA.arrow;
    Object.assign(TOOLTIPEL.querySelector("[data-ref='arrow']").style, {
      left: x != null ? `${x}px` : "",
      top: y != null ? `${y}px` : "",
    });
  }
};

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_tooltips", inline: true };
const msgs = {
  start: ["init. tooltips"],
  end: ["init. tooltips", "init. success"],
  no_el: ["init. tooltips", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_tooltips() {
  // Setup...
  const { tooltip_drawingToolbar } = this.ref;
  const tooltipEls = [tooltip_drawingToolbar];
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (tooltipEls.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Set up tooltipStates...
  const tooltipStates = [];

  ///////////////////////////////////
  // Drawing toolbar tooltip init. //
  ///////////////////////////////////

  if (tooltip_drawingToolbar) {
    // Setup...
    const { drawingToolbar } = this.ref;
    const toolBtnsWrapper = drawingToolbar.querySelector("[data-ref='toolBtnsWrapper']");
    const anchorEl = toolBtnsWrapper; // ← alias
    const tT = tooltip_drawingToolbar; // ← alias
    const tTarrowEl = tT.querySelector("[data-ref='arrow']");
    if (!toolBtnsWrapper) return;

    // Set up pos. & auto updt. fn...
    const posUpdtr = () => {
      computePosition(anchorEl, tT, {
        placement: "top",
        middleware: [offset(28), arrow({ element: tTarrowEl })],
      }).then(({ x, y, middlewareData }) => set_tooltipPos(tT, { x, y }, middlewareData));
    };
    const autoUpdtr = () => {
      return autoUpdate(anchorEl, tT, posUpdtr);
    };

    // Add to tooltipStates...
    tooltipStates.push({ id: "tooltip_drawingToolbar", el: tT, anchorEl, posUpdtr, autoUpdtr, is_hidden: true });
  }

  // Conclude...
  this.setState({ tooltipStates });
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
