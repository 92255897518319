// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_drawToolBtn_click from "../eventHandlers/eh_drawToolBtn_click.js";
import eh_undoDrawBtn_click from "../eventHandlers/eh_undoDrawBtn_click.js";
import eh_clearDrawBtn_click from "../eventHandlers/eh_clearDrawBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_drawingToolbar", inline: true };
const msgs = {
  start: ["init. drawingToolbar"],
  end: ["init. drawingToolbar", "init. success"],
  no_el: ["init. drawingToolbar", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_drawingToolbar() {
  // Setup...
  const { pencilToolBtn, spraycanToolBtn, shadowBrushToolBtn, undoDrawBtn, clearDrawBtn } = this.ref;
  const btnEls = [pencilToolBtn, spraycanToolBtn, shadowBrushToolBtn, undoDrawBtn, clearDrawBtn];
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (btnEls.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Event hdl...
  if (pencilToolBtn) pencilToolBtn.addEventListener("click", eh_drawToolBtn_click.bind(this, pencilToolBtn));
  if (spraycanToolBtn) spraycanToolBtn.addEventListener("click", eh_drawToolBtn_click.bind(this, spraycanToolBtn));
  if (shadowBrushToolBtn) shadowBrushToolBtn.addEventListener("click", eh_drawToolBtn_click.bind(this, shadowBrushToolBtn));
  if (undoDrawBtn) undoDrawBtn.addEventListener("click", eh_undoDrawBtn_click.bind(this, undoDrawBtn));
  if (clearDrawBtn) clearDrawBtn.addEventListener("click", eh_clearDrawBtn_click.bind(this, clearDrawBtn));

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
