// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_xyValueSubmitBtn_click", inline: true };
const msgs = {
  start: ["matrix answer x/y-value-submit btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_xyValueSubmitBtn_click() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { matrixXValueInput, matrixYValueInput } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Submit x/y values via comp. API...
  const xValue = matrixXValueInput?.value;
  const yValue = matrixYValueInput?.value;
  await this.api.submit_matrixAnswerXYvalues({ xValue, yValue });

  // Move to next step...
  this.setState({ currentStep: "matrixXYvaluesSubmitted" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
