// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start_out: "%cpage trans. → open parti. page → trans. out",
  start_in: "%cpage trans. → open parti. page → trans. in",
  skip_out: "%cpage trans. → open parti. page → trans. out → skip (no anim. necessary)",
  skip_in: "%cpage trans. → open parti. page → trans. in → skip (no anim. necessary)",
  done_in: "%cpage trans. → open parti. page → trans. in → complete",
  done_out: "%cpage trans. → open parti. page → trans. out → complete",
};

const logStyles = {
  def: "color: MediumPurple; font-weight: bold;",
  success: "color: MediumAquamarine; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default {
  /////////////////////////// Route selection ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  from: "/:lang", // matches any frontpage route
  to: "card-expand", // matches any link w/ data-swup-animation="card-expand"

  ///////////////////////////////// Out //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  out: async (FN_done, EVENTDATA) => {
    console.log(msgs.start_out, logStyles.def);

    // Find + expand card el...
    const visitData = EVENTDATA.visit;
    const triggerEl = visitData.trigger.el;
    const cardEl = triggerEl.closest(".frontpage-card");

    // Find card area wrapper (needed for corr. positioning of expanded card at 0|0)...
    const el_cardArea = cardEl.closest("[g-ref='DragCards:cardGroupAreas']");
    const rect_cardArea = el_cardArea.getBoundingClientRect();
    const y = rect_cardArea.top * -1;

    // Expand card...
    cardEl.setAttribute("data-is-expanding", "true");
    await gsap.to(cardEl, {
      duration: 0.625,
      width: "100vw",
      height: "100vh",
      scaleX: 1, // ← scale needs to be reset (might have been expanded on mouse-enter)
      scaleY: 1, // ← scale needs to be reset (might have been expanded on mouse-enter)
      "--borderR": "0",
      "--fs": "120px",
      "--lh": "140px",
      x: 0,
      y,
      zIndex: 999999999,
      ease: "power2.inOut",
    });
  },

  ///////////////////////////////// In ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  in: async (FN_done) => {
    console.log(msgs.start_in, logStyles.def);
    FN_done();
    // await gsap.fromTo("#swup", { opacity: 1 }, { opacity: 1, duration: 0.25 });
  },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
