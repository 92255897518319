// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.process_widgetStateUpdt_character", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "process_widgetStateUpdt_character"],
  end: ["EventCalendar", "API", "process_widgetStateUpdt_character", "API call complete"],
  no_widgetEl: ["EventCalendar", "API", "process_widgetStateUpdt_character", "No widget element found"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function process_widgetStateUpdt_character(WIDGETSTATE) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { el: widgetEl } = WIDGETSTATE;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!widgetEl) return this.logger("error", msgs.no_widgetEl, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Updt. data-is-hidden attr...
  if ("is_hidden" in WIDGETSTATE) {
    const { is_hidden } = WIDGETSTATE;
    widgetEl.setAttribute("data-is-hidden", is_hidden);
  }

  // Updt. active character illustration...
  if ("activeIllu" in WIDGETSTATE) {
    const { activeIllu } = WIDGETSTATE;
    const illuEls = widgetEl.querySelectorAll("img");
    illuEls.forEach((el, i) => el.setAttribute("data-is-hidden", i != activeIllu));
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
