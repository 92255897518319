// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_mouseMove", inline: true };
const msgs = {
  start: ["mouse is moving over comp. el."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_mouseMove(EVENTDATA) {
  // Setup...
  this.logger("event", msgs.start, "event", logArgs);

  // Updt. col. states: if mouse is on left side of comp. el., hide right col. and vice versa...
  const { clientX, clientY } = EVENTDATA;
  const { colStates } = this.state;
  const { innerWidth, innerHeight } = window;
  const window_is_landscape = innerWidth > innerHeight;
  const colStates_new = colStates.map((cS) => {
    const { el } = cS;
    const { left, width, top, height } = el.getBoundingClientRect();
    let is_hidden;
    if (window_is_landscape) is_hidden = clientX < left || clientX > left + width;
    else is_hidden = clientY < top || clientY > top + height;
    return { el, is_hidden };
  });
  this.setState({ colStates: colStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
