// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_mobileNextSectBtn", inline: true, disabled: false };
const msgs = {
  start: ["init. mobileNextSectBtn"],
  end: ["init. mobileNextSectBtn", "init. success"],
  no_el: ["init. mobileNextSectBtn", "no valid DOM el. provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_mobileNextSectBtn() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { mobileNextSectBtn } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(mobileNextSectBtn)) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Click hdl. //
  mobileNextSectBtn.addEventListener("click", () => {
    // Determ. next (sub)section based on curr. subsection...
    const { sections } = this.ref;
    const { currentSubsection } = this.state;
    const currSubsectionEl = sections.find((el) => el.dataset.id === currentSubsection);
    const index_currSubsect = sections.indexOf(currSubsectionEl);
    // const nextSubsection = sections[index_currSubsect + 1];
    const nextSection = sections[index_currSubsect + 1];

    // Show & scroll to next (sub)section...
    // if (!nextSubsection) return;
    if (!nextSection) return;
    // const id_nextSubsection = nextSubsection.dataset.id;
    const id_nextSection = nextSection.dataset.id;

    // Special case //
    if (currentSubsection == "futureMatrix") {
      api.scrollTo_subsection(`section[data-id='futureMatrix'] section[data-ref='subSection_questions']`);
      api.set_pageBgColor("#fff");
      api.hide_pageHeader();
    }
    // Regular case //
    else {
      switch (nextSection) {
        case "drawing":
          api.show_section("drawing");
          api.scrollTo_section("drawing", api.set_pageBgColor("#ff7130"));
          break;
        case "writing":
          api.show_section("writing");
          api.scrollTo_section("writing", api.set_pageBgColor("#a200ff"));
          break;

        default:
          break;
      }
    }

    // - Determ. & set curr. step...
    // - Updt. curr. subsection state...
    // - Hide mobileNextSectBtn (unless going to futureMatrix and there’s only one slide)...
    const { count_mobileContentSlides_futureMatrix } = this.options;
    const nextStep =
      id_nextSection === "drawing" ? "startDrawingGame" : id_nextSection === "writing" ? "startWritingGame" : "startMatrixGame";
    if (nextStep === "startMatrixGame" && currentSubsection != "futureMatrix") api.set_pageBgColor("#ff7130");
    this.setState({
      currentStep: nextStep,
      currentSubsection: id_nextSection,
      mobileNextSectBtnState: {
        ...this.state.mobileNextSectBtnState,
        is_hidden:
          nextStep === "startMatrixGame" && count_mobileContentSlides_futureMatrix === 1 && currentSubsection != "futureMatrix"
            ? false
            : true,
      },
    });
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
