// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_bgIlluStates", inline: true };
const msgs = {
  change: (CHANGE) => [`backgr. illu. states:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_bgIlluStates(CHANGES) {
  if (!("bgIlluStates" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { bgIlluStates } = CHANGES;
  const { bgIllus } = this.ref;
  this.logger("state-change", msgs.change(bgIlluStates), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  bgIlluStates?.forEach((state) => {
    ////////////////////////////// is-hidden ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const { id, is_hidden } = state;
    const bgIlluEl = bgIllus.find((el) => el.getAttribute("data-id") === id);
    if (bgIlluEl) bgIlluEl.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
