// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import expand_cardBg from "../../../component-drag-cards/js/api/expand/expand_cardBg";
import expand_cardClipPath from "../../../component-drag-cards/js/api/expand/expand_cardClipPath";
import contract_cardBg from "../../../component-drag-cards/js/api/contract/contract_cardBg";
import contract_cardClipPath from "../../../component-drag-cards/js/api/contract/contract_cardClipPath";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_mouseEvents", inline: true, disabled: false };
const msgs = {
  start: ["init. mouseEvents"],
  end: ["init. mouseEvents", "init. success"],
  no_el: ["init. mouseEvents", "no valid DOM el. provided"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_mouseEvents() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////// Mouse enter/leave init. ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Expand widget on mouse enter...
  function hdl_mouseEnter() {
    expand_cardBg.call(this, compEl);
    expand_cardClipPath.call(this, compEl);
  }
  compEl.addEventListener("mouseenter", hdl_mouseEnter.bind(this));

  // Contract widget on mouse leave...
  function hdl_mouseLeave() {
    contract_cardBg.call(this, compEl);
    contract_cardClipPath.call(this, compEl);
  }
  compEl.addEventListener("mouseleave", hdl_mouseLeave.bind(this));

  ///////////////////////// Drag instance init. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  Draggable.create(compEl, dragInstConfig);

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
