// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_colStates", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`column states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_colStates(CHANGES) {
  if (!("colStates" in CHANGES)) return this.logger("error", msgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { colStates } = CHANGES;
  this.logger("state-change", msgs.stateChange(colStates), "default", { ...logArgs, inline: false });

  // Updt. col. el. data-is-hidden attr...
  colStates.forEach((colState) => {
    const { el, is_hidden } = colState;
    el.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
