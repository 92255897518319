// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_footnotePos", inline: true, disabled: false };
const msgs = {
  start: ["PostFootnotes", "API", "set_footnotePos"],
  end: ["PostFootnotes", "API", "set_footnotePos", "API call complete"],
  no_linkEl: ["PostFootnotes", "API", "set_footnotePos", "no link el. provided"],
  no_footnoteEl: ["PostFootnotes", "API", "set_footnotePos", "no footnote el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_footnotePos(FOOTNOTELINKEL) {
  if (!FOOTNOTELINKEL) return this.logger("error", msgs.no_linkEl, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_tablet_ls } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Find footnote el. corrsp. to link...
  const footnoteIndex = FOOTNOTELINKEL.dataset.footnoteIndex;
  const footnoteEl = document.querySelector(`[data-ref="footnote"][data-index="${footnoteIndex}"]`);

  // Guard: Stop, if no footnote el. found...
  if (!footnoteEl) return this.logger("error", msgs.no_footnoteEl, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Set footnote width:
  // - Find page layout wrapper...
  // - Measure space available between right edge of page layout wrapper & right edge of viewport...
  // - Set footnote width to be equal to available space...
  const pageLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  if (pageLayoutWrapper) {
    const rect_layoutWrapper = getRect(pageLayoutWrapper);
    const r_layoutWrapper = rect_layoutWrapper.right;
    const r_viewPort = window.innerWidth;
    gsap.set(footnoteEl, { width: r_viewPort - r_layoutWrapper });
  }

  // Get related link top position...
  const rect_link = getRect(FOOTNOTELINKEL);
  const t_link = rect_link.top;

  // Check if there is another footnote el. preceding the current footnote el...
  const el_prev = footnoteEl.previousElementSibling;
  const el_prev_isFootnote = el_prev?.dataset?.ref === "footnote";

  // If preceding footnote el.'s bottom edge extends further than this footnote el.'s link,
  // set footnote el.top position to be equal to preceding footnote el.'s bottom edge...
  if (el_prev_isFootnote) {
    // const rect_prev = getRect(el_prev);
    const rect_prev = getRect([el_prev, "margin"]);
    const b_prev = rect_prev.bottom;
    if (Math.ceil(b_prev) > Math.floor(t_link)) gsap.set(footnoteEl, { y: b_prev });
  }

  // Otherwise, set footnote el. top position to be on same height as link...
  else {
    gsap.set(footnoteEl, { y: t_link });
  }

  // Show footnote el...
  footnoteEl.setAttribute("data-is-hidden", "false");

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
