// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_footnoteInteractions", inline: true, disabled: false };
const msgs = {
  start: ["init. init_footnoteInteractions"],
  end: ["init. init_footnoteInteractions", "init. success"],
  no_footnoteLinks: ["init. init_footnoteInteractions", "no footnote link els. found"],
  no_footnoteEls: ["init. init_footnoteInteractions", "no footnote els. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_footnoteInteractions() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find footnote link & footnote DOM elements on page...
  const footnoteLinks = document.querySelectorAll("[data-footnote-link]");
  const footnotes = document.querySelectorAll("[data-ref='footnote']");

  // Guard: Stop, is no footnote links or footnote els. found...
  if (!footnoteLinks.length) return this.logger("init", msgs.no_footnoteLinks, "warning", logArgs);
  if (!footnotes.length) return this.logger("init", msgs.no_footnoteEls, "warning", logArgs);

  // Hdl. click events on footnote links...
  footnoteLinks.forEach((linkEl) => {
    linkEl.addEventListener("click", () => {
      // - Find related footnote el...
      // - Scroll to related footnote el...
      const footnoteIndex = linkEl.dataset.footnoteIndex;
      const footnoteEl = [...footnotes].find((el) => el.dataset.index === footnoteIndex);
      eventbus.emit("WindowScroll.api.scroll_toTarget", { target: footnoteEl });
    });
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
