// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_glossaryLink_mouseEnter from "../eventHandlers/eh_glossaryLink_mouseEnter.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_glossaryLinks", inline: true };
const msgs = {
  start: ["init. glossary links"],
  end: ["init. glossary links", "init. success"],
  no_linkEls: ["init. glossary links", "no link elements found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_glossaryLinks() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find glossary link DOM elements on page...
  const linkEls = document.querySelectorAll("[data-glossary-link]");

  // Guard: Stop, is no link elements found...
  if (!linkEls.length) return this.logger("init", msgs.no_linkEls, "warning", logArgs);

  // Link event hdl...
  linkEls.forEach((el) => {
    if (el.dataset.term) {
      el.addEventListener("click", () => this.api.show_widget());
      el.addEventListener("mouseenter", eh_glossaryLink_mouseEnter.bind(this, el));
    }
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
