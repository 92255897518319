// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_string from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_stateChange", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`active slide index: ${CHANGE}`],
  no_change: ["No change to state provided."],
  no_someEl: ["state change hdl.", "stateChange", "no some el. found"],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeSlideIndex(CHANGES) {
  if (!("activeSlideIndex" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeSlideIndex } = CHANGES;
  this.logger("state-change", msgs.change(activeSlideIndex), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////// Caption updt. //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Check for caption text on new slide...
  const { slides } = this.ref;
  const newSlide = slides[activeSlideIndex];
  const newCaption = newSlide.getAttribute("data-caption");
  this.setState({ caption: valid_string(newCaption) ? newCaption : "" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
