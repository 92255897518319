// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_drag from "./init/init_drag.js";
import init_position from "./init/init_position.js";
import init_breakpoints from "../../../../app/baseUtilities/init/init_component_breakpoints.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ——————————————————————————————————————— COMP. DEFINITION ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class DisclaimerWidget extends Component {
  ///////////////////////////////////////// Constructor //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    /////////////////////////// DOM references /////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.ref = { someRef: null };

    /////////////////////////////// Options ////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "DisclaimerWidget",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      breakpoints: {
        mobile: 300,
        tablet_prt: 700,
        tablet_ls: 1000,
        desktop_sm: 1200,
        desktop_med: 1400,
      },
      //////
      run_withLogs: true,
      logStyles: defaultLogStyles,
    };

    ////////////////////////////// Utilities ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    ////////////////////////////// Init. f() ///////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.init_drag = init_drag.bind(this);
    this.init_position = init_position.bind(this);
    this.init_breakpoints = init_breakpoints.bind(this);

    /////////////////////////////// Modules ////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.modules = {};

    ///////////////////////////////// API //////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.api = {
      hide_comp: () => this.setState({ hidden: true }),
      show_comp: () => this.setState({ hidden: false }),
    };

    ////////////////////////// Eventbus listeners //////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);

    //////////////////////// State-change listeners ////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.stChL_mode = stChH_mode.bind(this);

    //////////////////////// Custom event handlers /////////////////////////
    //////////////////// (To be passed to parent class) ////////////////////

    // ...

    /////////////////////////// Pre-mount init. ////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // ...
  }

  //////////////////////////////////////////// Mount /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////////////////// Unmount ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    ////// Eventbus listener de-registration ///////
    ////////////////////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);

    /////////// API call de-registration ///////////
    ////////////////////////////////////////////////

    eventbus.off("DisclaimerWidget.api.hideComp", this.api.hide_comp);
    eventbus.off("DisclaimerWidget.api.showComp", this.api.show_comp);
  }

  ///////////////////////////////////////////// Init. ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// Base init. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_states();
    this.init_breakpoints();
    this.init_position();
    this.init_drag();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ///////////////////////////// States init. /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({ features: { mouse: window.matchMedia("(pointer:fine)").matches } });
  }

  //////////////////////////// Eventbus init. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    //////////// Listener registration /////////////
    ////////////////////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);

    //////////// API call registration /////////////
    ////////////////////////////////////////////////

    eventbus.on("DisclaimerWidget.api.hideComp", this.api.hide_comp);
    eventbus.on("DisclaimerWidget.api.showComp", this.api.show_comp);
  }

  /////////////////////////////////////// State management ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default DisclaimerWidget;
