// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Cookies from "js-cookie";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.acceptCookies", inline: true, disabled: false };
const msgs = {
  start: ["CookieWidget", "API", "acceptCookies"],
  end: ["CookieWidget", "API", "acceptCookies", "API call complete"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function acceptCookies() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { input_allowCookies_essential, input_allowCookies_analytics } = this.ref;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Check which cookie types are allowed...
  const allowCookies_essential = input_allowCookies_essential.checked;
  const allowCookies_analytics = input_allowCookies_analytics.checked;

  // - Set accept-cookies to true...
  // - Hide widget...
  Cookies.set("ash-accept-cookies-essential", allowCookies_essential, { expires: 7 });
  Cookies.set("ash-accept-cookies-analytics", allowCookies_analytics, { expires: 7 });
  Cookies.set("ash-accept-cookies", "true", { expires: 7 });
  this.setState({ is_hidden: true });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
