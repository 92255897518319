// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_colorSwitch_click from "../eventHandlers/eh_colorSwitch_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: ["init. colorSwitch"],
  end: ["init. colorSwitch", "init. success"],
  no_el: ["init. colorSwitch", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_colorSwitch() {
  // Setup...
  const { colorSwitch } = this.ref;
  this.logger("init", msgs.start, "action");

  // Guard...
  if (!this.validate_refEl(colorSwitch)) return this.cancel_featInit(msgs.no_el);

  // Listen to click events on the colorSwitch...
  colorSwitch.addEventListener("click", eh_colorSwitch_click.bind(this));

  // Conclude...
  this.logger("init", msgs.end, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
