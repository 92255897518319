// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import TweenMax from "gsap";
import { getWidth } from "mezr/getWidth";
import { getHeight } from "mezr/getHeight";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.move_cardIntoViewport", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "move_cardIntoViewport"],
  end: ["DragCards", "API", "move_cardIntoViewport", "API call complete"],
  no_cardEl: ["DragCards", "API", "move_cardIntoViewport", "no card el. provided", "stopping API hdl. execution"],
};

// ——————————————————————————————————————— API METHOD DEF. ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function move_cardIntoViewport(CARDEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!CARDEL) return this.logger("warning", msgs.no_cardEl, "warning", logArgs);

  /////////////////////////// Random position. of card within viewport ///////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // - Get curr. vertical scroll pos...
  // - Get card container el. (card group area)...
  // - Measure card container dist. from top of viewport...
  const scrollY = window.scrollY;
  const cardGroupEl = CARDEL.closest("[g-ref='DragCards:cardGroupAreas']");
  const dist_cardGroup_toVpTop = cardGroupEl.offsetTop;

  // Measure card el...
  const w_card = getWidth(CARDEL);
  const h_card = getHeight(CARDEL);

  // Determ. min./max. x/y pos. values...
  const min_x = 0;
  const max_x = window.innerWidth - w_card;
  const min_y = -dist_cardGroup_toVpTop + scrollY;
  const max_y = min_y + window.innerHeight - h_card;

  // Create random y pos. within viewport...
  const rand_x = Math.floor(Math.random() * (max_x - min_x + 1) + min_x);
  const rand_y = Math.floor(Math.random() * (max_y - min_y + 1) + min_y);

  // Move card to top of viewport...
  TweenMax.to(CARDEL, { x: rand_x, y: rand_y, duration: 0.6, ease: "power3.out" });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
