// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { ScrollTrigger } from "gsap/ScrollTrigger";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.show_subsection", inline: true };
const msgs = {
  start: ["PartiForm", "API", "show_subsection"],
  end: ["PartiForm", "API", "show_subsection", "API call complete"],
  no_selector: ["PartiForm", "API", "show_subsection", "no section selector provided"],
  no_subsectionEl: ["PartiForm", "API", "show_subsection", "no subsection el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function show_subsection(SELECTOR_subsection) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!valid_str(SELECTOR_subsection)) return this.logger("error", msgs.no_selector, "error", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Find subsection el. (+ guard)...
  const subsectionEl = document.querySelector(SELECTOR_subsection);
  if (!subsectionEl) return this.logger("error", msgs.no_subsectionEl, "error", logArgs);

  // Show subsection el...
  subsectionEl.setAttribute("data-is-hidden", false);

  // Refresh ScrollTrigger...
  ScrollTrigger.refresh();

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
