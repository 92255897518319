// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_windowScroll_stCh_scrollPosition", inline: true };
const msgs = {
  start: ["IlluScroller", "eventbus", "windowScroll_stCh_scrollPosition"],
  noActiveSection: ["IlluScroller", "eventbus", "windowScroll_stCh_scrollPosition", "No active section found."],
  noActiveIllu: ["IlluScroller", "eventbus", "windowScroll_stCh_scrollPosition", "No active illu. found."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_windowScroll_stCh_scrollPosition(ARGS) {
  // Setup...
  const { scrollPercentage, _name } = ARGS;
  // this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // // Updt. active section...
  // const activeSection = this.api.get_activeSection();
  // if (!activeSection) return this.logger("warning", msgs.noActiveSection, "warning", logArgs);
  // this.setState({
  //   activeSection: {
  //     id: activeSection.dataset.id,
  //     el: activeSection,
  //   },
  // });

  ///////////////////////////
  // Active illu. ID updt. //
  ///////////////////////////

  // Set activeIlluID state to ID of illu. corresponding whose data-set-active-at attr. corresponds
  // to current scroll percentage.

  const { illus } = this.ref;
  const activeIllu = illus.find((illuEl) => {
    const setActiveAt = parseInt(illuEl.dataset.setActiveAt, 10);
    return scrollPercentage >= setActiveAt && scrollPercentage < setActiveAt + 40;
  });
  this.setState({ activeIlluID: activeIllu ? activeIllu.dataset.id : "null" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
