// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import Marquee from "vanilla-marquee";
import { OverlayScrollbars } from "overlayscrollbars";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_JSONstr from "../../../../../app/baseUtilities/validate/validate_JSONstring.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_widget_drag from "../eventHandlers/eh_widget_drag.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_widgets", inline: true };
const msgs = {
  start: ["init. widgets"],
  end: ["init. widgets", "init. success"],
  no_el: ["init. widgets", "no valid DOM element(s) provided"],
};

const defConfig_marquee = { startVisible: true, duplicated: true, speed: 40, gap: 0, delayBeforeStart: 1000 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_widgets() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const {
    widget_date,
    widget_marquee,
    widget_description,
    widget_img,
    widget_slideShow,
    widget_location,
    widget_ticketLink,
    widget_character,
  } = this.ref;
  const elements = [
    widget_date,
    widget_marquee,
    widget_description,
    widget_img,
    widget_slideShow,
    widget_location,
    widget_ticketLink,
    widget_character,
  ];
  const { is_mobile, is_tablet_prt } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (elements === undefined) return this.cancel_featInit(msgs.no_el);
  if (elements.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Prep. gsap...
  gsap.registerPlugin(Draggable);
  const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };

  // Create init. widget states...
  const widgetStates = {};

  ////////////////////////////////////// Date-widget init. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && widget_date) {
    // Prep. drag. inst. config...
    const dragInstConfig_dateWidget = { ...dragInstConfig };

    // Init. widget follower...
    const { widgetFollower_date } = this.ref;
    if (widgetFollower_date)
      dragInstConfig_dateWidget.onDrag = eh_widget_drag.bind(this, widget_date, widgetFollower_date, {
        followerAlign: "behind-right",
      });

    // Init drag. inst...
    const dragInst = Draggable.create(widget_date, dragInstConfig_dateWidget);
    const dateWidgetState = { el: widget_date, followerEl: widgetFollower_date, is_hidden: true, dragInst };
    widgetStates.dateWidget = dateWidgetState;
  }

  ///////////////////////////////////// Marquee-widget init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && widget_marquee) {
    // Init drag. inst...
    const dragInst = Draggable.create(widget_marquee, dragInstConfig);

    // Init. marquee...
    const marqueeEl = widget_marquee.querySelector("[data-role='marquee']");
    const marqueeInst = marqueeEl ? new Marquee(marqueeEl, defConfig_marquee) : null;

    // Init. widget state...
    const marqueeWidgetState = { el: widget_marquee, is_hidden: true, dragInst, marqueeInst };
    widgetStates.marqueeWidget = marqueeWidgetState;
  }

  /////////////////////////////////// Description-widget init. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_tablet_prt && widget_description) {
    // Init drag. inst...
    const dragInst = Draggable.create(widget_description, dragInstConfig);
    const descriptionWidgetState = { el: widget_description, is_hidden: true, dragInst };
    widgetStates.descriptionWidget = descriptionWidgetState;

    // Init. overlay scrollbar inst...
    const scrollContentWrapper = widget_description.querySelector("[data-ref='scrollContentWrapper']");
    if (scrollContentWrapper) {
      const osInstance = OverlayScrollbars(scrollContentWrapper, { scrollbars: { theme: "os-theme-ash" } });
      widgetStates.descriptionWidget.osInstance = osInstance;
    }
  }

  /////////////////////////////////////// Img-widget init. ///////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && widget_img) {
    // Prep. drag. inst. config...
    const dragInstConfig_imgWidget = { ...dragInstConfig };

    // Init. widget follower...
    const { widgetFollower_img } = this.ref;
    if (widgetFollower_img) dragInstConfig_imgWidget.onDrag = eh_widget_drag.bind(this, widget_img, widgetFollower_img);

    // Init drag. inst...
    const dragInst = Draggable.create(widget_img, dragInstConfig_imgWidget);
    const imgWidgetState = { el: widget_img, is_hidden: true, dragInst, imgSrc: "", imgCaption: "" };
    widgetStates.imgWidget = imgWidgetState;

    // Init. enlarge btn...
    const enlargeBtn = widget_img.querySelector("[data-ref='enlargeBtn']");
    if (enlargeBtn)
      enlargeBtn.addEventListener("click", () => {
        // Toggle img. size...
        widget_img.setAttribute("data-is-large", widget_img.dataset.isLarge === "false");
        enlargeBtn.setAttribute("data-is-active", widget_img.dataset.isLarge);

        // Show/hide img. widget follower (contains img. caption)...
        if (widgetFollower_img) {
          const widgetState = this.state.widgetStates.imgWidget;
          if (widgetState.imgCaption !== "") {
            if (widget_img.dataset.isLarge === "true") {
              this.api.updt_widgetFollowerPos(widget_img, widgetFollower_img, { instant: true, followerAlign: "below-right" });
              widgetFollower_img.setAttribute("data-is-hidden", "false");
            } else {
              widgetFollower_img.setAttribute("data-is-hidden", "true");
            }
          }
        }
      });
  }

  //////////////////////////////////// Slideshow-widget init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && widget_slideShow) {
    // Init drag. inst...
    const dragInst = Draggable.create(widget_slideShow, dragInstConfig);

    // Init. flickity inst...
    let flickityInst = null;
    const slidesWrapper = widget_slideShow.querySelector("[data-ref='slidesWrapper']");
    if (slidesWrapper) {
      flickityInst = new Flickity(slidesWrapper, {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        cellAlign: "center",
        selectedAttraction: 0.025,
        friction: 0.28,
        draggable: false,
        fade: false,
        // imagesLoaded: true, // ← not needed due to fixed img. size/aspect ratio
      });

      // Init. slide show controls...
      const prevBtn = widget_slideShow.querySelector("[g-ref='prevBtn']");
      const nextBtn = widget_slideShow.querySelector("[g-ref='nextBtn']");
      if (prevBtn) prevBtn.addEventListener("click", () => flickityInst.previous());
      if (nextBtn) nextBtn.addEventListener("click", () => flickityInst.next());

      // Init. slide-change hdl...
      flickityInst.on("change", (index) => {
        const credits = widget_slideShow.dataset.credits;
        const has_credits = valid_JSONstr(credits);
        const imgCreditsDisplay = widget_slideShow.querySelector("[data-ref='imgCreditsDisplay']");

        // Set active img credits...
        if (has_credits && imgCreditsDisplay) {
          const credits_parsed = JSON.parse(credits);
          let activeImgCredits = credits_parsed[index];
          if (!activeImgCredits || activeImgCredits === undefined) activeImgCredits = "";
          imgCreditsDisplay.textContent = activeImgCredits;
        }
      });
    }

    // Init. widget state...
    const slideShowWidgetState = { el: widget_slideShow, is_hidden: true, dragInst, flickityInst };
    widgetStates.slideShowWidget = slideShowWidgetState;
  }

  //////////////////////////////////// Location-widget init. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (!is_mobile && widget_location) {
    // Prep. drag. inst. config...
    const dragInstConfig_locationWidget = { ...dragInstConfig };

    // Init. widget follower...
    const { widgetFollower_location } = this.ref;
    if (widgetFollower_location)
      dragInstConfig_locationWidget.onDrag = eh_widget_drag.bind(this, widget_location, widgetFollower_location);

    // Init drag. inst...
    const dragInst = Draggable.create(widget_location, dragInstConfig_locationWidget);
    const locationWidgetState = { el: widget_location, is_hidden: true, mapsLink: null, dragInst };
    widgetStates.locationWidget = locationWidgetState;
  }

  //////////////////////////////////// TicketLink-widget init. ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (widget_ticketLink) {
    // Init drag. inst...
    const dragInst = Draggable.create(widget_ticketLink, dragInstConfig);
    const ticketLinkWidgetState = { el: widget_ticketLink, is_hidden: true, ticketLink: null, dragInst };
    widgetStates.ticketLinkWidget = ticketLinkWidgetState;
  }

  //////////////////////////////////// Character-widget init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  if (widget_character) {
    // Init drag. inst...
    const dragInst = Draggable.create(widget_character, dragInstConfig);
    const characterWidgetState = { el: widget_character, is_hidden: true, dragInst, activeIllu: Math.floor(Math.random() * 3) };
    widgetStates.characterWidget = characterWidgetState;
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.setState({ widgetStates }); // ← set init. widget states
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
