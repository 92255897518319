// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";
import valid_arr from "../../../../../app/baseUtilities/validate/validate_array";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.processSteps_drawingGame", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "processSteps_drawingGame"],
  end: ["PartiForm", "API", "processSteps_drawingGame", "API call complete"],
  no_stepString: () => ["PartiForm", "API", "processSteps_drawingGame", "No step string provided."],
  stepNotAllowed: (step) => ["PartiForm", "API", "processSteps_drawingGame", `step not allowed: ${step}`],
  drawingGameDisabled: ["PartiForm", "API", "processSteps_drawingGame", "Drawing Game is disabled.", "stopping step process"],
};

const possibleSteps = ["startDrawingGame", "selectDrawTool", "startDrawing", "drawingSubmitted", "viewDrawings"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function processSteps_drawingGame(
  DATA = {
    step: null,
  }
) {
  if (!valid_str(DATA?.step)) return this.logger("error", msgs.no_stepString(), "error", logArgs);
  if (!possibleSteps.includes(DATA?.step)) return this.logger("error", msgs.stepNotAllowed(DATA?.step), "error", logArgs);
  if (!this.options.with_drawingGame) return this.logger("warning", msgs.drawingGameDisabled, "warning", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { step } = DATA;
  const { subsections, drawingCanvasWrapper, drawingCanvasInnerWrapper, drawingToolbar } = this.ref;
  const subsection_drawingGame = subsections.find((el) => el.getAttribute("data-id") === "drawingGame");
  const { drawingSubmitBtnWrapper, sectionFooter_drawing } = this.ref;
  const { notif_noDrawingSubmissions, gameSectionIllus } = this.ref;
  const gameSectionIlluEl = gameSectionIllus.find((el) => el.dataset.id === "drawing");
  const { with_futureMatrix, with_writingGame, sectionHeadlines } = this.options;
  const { is_mobile } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  ///////////////////////////////// Mobile instruct. card updt. //////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // On mobile, pass curr. step to mobile instruct. card updt. API to updt. card text...
  if (is_mobile) api.updt_mobileInstructCard({ step, color_bg: "var(--color-parti-bg)", color_text: "#000" });

  ///////////////////////////////////// Step-specific updts. /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  switch (true) {
    ////////////////////////// "startDrawingGame" //////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // - reveal + scroll to next section...
    // - ensure that intro sect. bg. illu. is hidden...
    // - ensure that page header is hidden...
    // - block fullpage scroll...
    // - set form header title to drawing game section headline...
    // - set mode to "drawing" + set current step to "selectDrawTool"...
    // - If slide for drawing game exists:
    //   - set active slide of info overlay state to "drawing"...
    //   - ...otherwise, disabled info overlay...
    // - init. drawing canvas (fabric)...
    case step == "startDrawingGame":
      const has_drawingGameInfoSlide = api.has_gameInfoSlide("drawing");
      api.show_section("drawing");
      api.scrollTo_section("drawing", api.set_pageBgColor("#ff7130"));
      api.hide_bgIllu("intro");
      api.hide_pageHeader();
      api.blockFullpageScroll();
      api.updt_formHeaderTitle(sectionHeadlines?.drawingGame);
      setTimeout(() => {
        this.setState({
          mode: "drawing",
          currentStep: "selectDrawTool",
          infoOverlayState: {
            ...this.state.infoOverlayState,
            is_disabled: !has_drawingGameInfoSlide,
            activeSlide: has_drawingGameInfoSlide ? "drawing" : null,
          },
        });
        this.init_fabric();
      }, 700); // ← let scroll anim. finish (to do: promise based solution)

    /////////////////////////// "selectDrawTool" ///////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // - init. pill borders...
    // - updt. form boder:
    //   - hide form border...
    case step == "selectDrawTool":
      const pillEls = Array.from(subsection_drawingGame.querySelectorAll("[g-ref='pills']"));
      if (pillEls.length > 0) this.init_pills(pillEls);
      this.setState({ formBorderState: { is_hidden: true } });
      break;

    //////////////////////////// "startDrawing" ////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // - enable drawing canvas + show submit-btn...
    // - updt. bg color...
    case step == "startDrawing":
      drawingCanvasWrapper.setAttribute("data-is-disabled", false);
      drawingSubmitBtnWrapper.setAttribute("data-is-hidden", false);

      // Backgr. color updt.: Trans. to white for remainder of drawing session...
      api.set_pageBgColor("#fff");
      break;

    /////////////////////////// "submitDrawing" ////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // - hide drawing canvas, toolbar & submit-btn...
    // - show submit-notif. for 2 sec. before moving to next step...
    case step == "drawingSubmitted":
      drawingCanvasWrapper.setAttribute("data-is-disabled", true);
      drawingCanvasInnerWrapper.setAttribute("data-is-hidden", true);
      drawingToolbar.setAttribute("data-is-hidden", true);
      drawingSubmitBtnWrapper.setAttribute("data-is-hidden", true);
      api.show_bgIllu("submitNotif");
      setTimeout(() => this.setState({ currentStep: "viewDrawings" }), 1100);
      break;

    //////////////////////////// "viewDrawing" /////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // - hide submit-notif...
    // - show section footer (if next game is enabled)...
    // - fetch drawings...
    // - show drawing viewer || no-submissions notif...
    // - show game section illu...
    case step == "viewDrawings":
      api.hide_bgIllu("submitNotif");
      if (with_futureMatrix || with_writingGame) sectionFooter_drawing.setAttribute("data-is-hidden", false);
      const newViewerImgs = await api.fetch_drawingViewerImgs();
      if (valid_arr(newViewerImgs)) api.show_drawingViewer(newViewerImgs);
      else notif_noDrawingSubmissions.setAttribute("data-is-hidden", false);
      if (gameSectionIlluEl) gameSectionIlluEl.setAttribute("data-is-hidden", false);
      break;

    ///////////////////////// Default (do nothing) /////////////////////////
    ////////////////////////////////////////////////////////////////////////

    default:
      break;
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
