// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_breakpoints", inline: true, disabled: false };
const msgs = {
  start: ["init. breakpoints"],
  end: ["init. breakpoints", "init. success"],
  no_breakpoints: ["init. breakpoints", "no breakpoints found"],
};
const defBreakpoints = {
  tablet_prt: 700,
  tablet_ls: 1000,
  desktop_sm: 1200,
  desktop_med: 1400,
  desktop_lg: 1600,
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_breakpoints(BREAKPOINTS = this.options.breakpoints ?? defBreakpoints) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!BREAKPOINTS) return this.cancel_featInit(msgs.no_breakpoints);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Set breakpoint-dependent states...
  const w_viewport = window.innerWidth;
  this.setState({
    is_mobile: w_viewport < BREAKPOINTS.tablet_prt,
    is_tablet_prt: w_viewport >= BREAKPOINTS.tablet_prt && w_viewport < BREAKPOINTS.tablet_ls,
    is_tablet_ls: w_viewport >= BREAKPOINTS.tablet_ls && w_viewport < BREAKPOINTS.desktop_sm,
    is_desktop_sm: w_viewport >= BREAKPOINTS.desktop_sm,
    is_desktop_md: w_viewport >= BREAKPOINTS.desktop_med,
    is_desktop_lg: w_viewport >= BREAKPOINTS.desktop_lg,
  });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
