// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_drag", inline: true };
const msgs = {
  start: ["card", "drag"],
  no_followerEl: ["card", "drag", "no card follower found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_drag(CARDEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  this.logger("event", msgs.start, "event", logArgs);

  /////////////////////////////////// Follower position updt. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const with_follower = CARDEL.dataset.withFollower === "true";
  if (with_follower)
    api.updt_cardFollowerPos(CARDEL, {
      positioningScheme: CARDEL.dataset.followerPos || "below",
    });

  //////////////////////////////// Comment widget position updt. /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const is_commentWidgetAnchor = CARDEL.getAttribute("data-is-comment-widget-anchor") === "true";
  if (is_commentWidgetAnchor) api.updt_commentWidgetPos(CARDEL);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
