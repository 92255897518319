// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stCh_windowScroll_scrollPosition(ARGS) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { maxScrollPosition, scrollPosition } = ARGS;

  /////////////////////////////// Subpage layout drop shadow updt. ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const innerPageLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  if (innerPageLayoutWrapper) {
    const maxDropShadowOpacity = 0.6;
    const showFullShadowThreshold = 0.3;
    const relativeScrollPercentage = scrollPosition / (maxScrollPosition * showFullShadowThreshold);
    const dropShadowOpacity = Math.min(relativeScrollPercentage, maxDropShadowOpacity);
    innerPageLayoutWrapper.style.setProperty("--dropShadowOpacity", dropShadowOpacity);
  }

  ////////////////////////////// Footer-in-view check & attr. updt. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const selector_footerEl = "[data-ref='Nav:footer']";
  const footerEl = document.querySelector(selector_footerEl);
  const footerMenuEl = footerEl?.querySelector("nav");
  if (footerMenuEl) {
    const footerMenuRect = footerMenuEl.getBoundingClientRect();
    const { top: top_footer } = footerMenuRect;
    const footerIsInView = top_footer < window.innerHeight;
    document.documentElement.setAttribute("data-footer-in-view", footerIsInView);
  }

  /////////////////////////////// is-at-top check & attr. updt. ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const isAtTop = scrollPosition < 50;
  document.documentElement.setAttribute("data-scrolled-to-top", isAtTop ? "true" : "false");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
