// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr";
import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.set_footnotePos", inline: true, disabled: false };
const msgs = {
  start: ["PostFootnotes", "API", "set_footnotePos"],
  end: ["PostFootnotes", "API", "set_footnotePos", "API call complete"],
  no_linkEl: ["PostFootnotes", "API", "set_footnotePos", "no link el. provided"],
  no_footnoteEl: ["PostFootnotes", "API", "set_footnotePos", "no footnote el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_footnotePos(FOOTNOTELINKEL) {
  if (!FOOTNOTELINKEL) return this.logger("error", msgs.no_linkEl, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_tablet_ls } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  // Find footnote el. corrsp. to link...
  const footnoteIndex = FOOTNOTELINKEL.dataset.footnoteIndex;
  const footnoteEl = document.querySelector(`[data-ref="footnote"][data-index="${footnoteIndex}"]`);

  // Guard: Stop, if no footnote el. found...
  if (!footnoteEl) return this.logger("error", msgs.no_footnoteEl, "error", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Set footnote width:
  // - Find page layout wrapper...
  // - Measure space between right edge of page layout wrapper & right edge of viewport...
  // - Set footnote width to be equal to this space...
  const pageLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  if (pageLayoutWrapper) {
    const rect_layoutWrapper = getRect(pageLayoutWrapper);
    const r_layoutWrapper = rect_layoutWrapper.right;
    const r_viewPort = window.innerWidth;
    const gap = is_tablet_ls ? 12 : 20;
    const w_footnote = r_viewPort - r_layoutWrapper - gap;
    gsap.set(footnoteEl, { width: w_footnote });
  }

  // Set footnote positions:
  // - Set footnote el. top position to be on same height as link...
  const linkRect = getRect(FOOTNOTELINKEL);
  const t_link = linkRect.top;
  gsap.set(footnoteEl, { y: t_link });

  // Show footnote el...
  footnoteEl.setAttribute("data-is-hidden", "false");

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
