// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import JustValidate from "just-validate";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_validationSuccess from "../eventHandlers/eh_validationSuccess.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_justValidate", inline: true };
const msgs = {
  start: ["init. justValidate"],
  end: ["init. justValidate", "init. success"],
  noSubmBtn: ["init. justValidate", "no contact form submit button found"],
  noMssgField: ["init. justValidate", "no message field found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_justValidate() {
  // Setup...
  const formEl = this.element;
  const { messageField } = this.ref;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(messageField)) return this.cancel_featInit(msgs.noMssgField);

  // Create justValidate inst. & add message field...
  const justValidateInstance = new JustValidate(formEl, { submitFormAutomatically: false, focusInvalidField: false });
  const validRule = [{ rule: "required", errorMessage: "Please enter a message" }];
  justValidateInstance.addField(messageField, validRule);

  // Subm. success hdl...
  justValidateInstance.onSuccess(eh_validationSuccess.bind(this));

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
  this.modules.justValidate.instance = justValidateInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
