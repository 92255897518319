// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.fetch_currDrawingSubmitIndex", inline: true };
const msgs = {
  start: ["PartiForm", "API", "fetch_currDrawingSubmitIndex"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function fetch_currDrawingSubmitIndex() {
  return new Promise((resolve, reject) => {
    //////////////////////////////// Setup /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.logger("event", msgs.start, "event", logArgs);

    //////////////////////////////// Fetch /////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    // Fetch current drawing submit index page API route...
    const { participationPageId: partiPageID } = this.options;
    const url = "/api.parti-form.get.drawing-submit-index";
    const data = { partiPageID };
    const options = { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(data) };
    try {
      fetch(url, options)
        .then((res) => res.json())
        .then((respData) => {
          // Guard...
          if (!respData) return reject("No submit index data found for page.");

          // Resolve...
          const drawingSubmitIndex = respData?.drawingSubmitIndex;
          resolve(drawingSubmitIndex);
        })
        .catch((respError) => {
          // Guard...
          if (respError) return reject(respError);

          // Resolve...
          resolve([]);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
