// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_stateChange", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`filterMenuState:`, CHANGE],
  no_change: ["No change to state provided."],
  no_someEl: ["state change hdl.", "stateChange", "no some el. found"],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_filterMenuState(CHANGES) {
  if (!("filterMenuState" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { filterMenuState } = CHANGES;
  this.logger("state-change", msgs.change(filterMenuState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////// is_open ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_open" in filterMenuState) {
    const { openFilterMenuBtn, filterMenu } = this.ref;
    const { is_open } = filterMenuState;
    filterMenu.setAttribute("data-is-open", is_open ? "true" : "false");
    openFilterMenuBtn.setAttribute("data-is-active", is_open ? "true" : "false");
  }

  ////////////////////////////// is_hidden ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in filterMenuState) {
    const { filterMenuWrapper } = this.ref;
    const { is_hidden } = filterMenuState;
    filterMenuWrapper.setAttribute("data-is-hidden", is_hidden ? "true" : "false");
  }

  /////////////////////////////// position ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("position" in filterMenuState) {
    const { filterMenuWrapper } = this.ref;
    const { position } = filterMenuState;
    filterMenuWrapper.setAttribute("data-position", position);

    // Filter menu should be closed when menu pos. changes to avoid visual bugs...
    if (this.state.filterMenuState.is_open)
      this.setState({
        filterMenuState: { ...this.state.filterMenuState, is_open: false },
      });
  }

  ///////////////////////////// h_filterMenu /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("h_filterMenu" in filterMenuState) {
    const { filterMenuWrapper } = this.ref;
    const { h_filterMenu } = filterMenuState;
    filterMenuWrapper.style.setProperty("--h-filter-menu", `${h_filterMenu}px`);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
