// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr";
import gsap from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_widgetFollowerPos", inline: true, disabled: false };
const msgs = {
  start: ["EventCalendar", "API", "updt_widgetFollowerPos"],
  end: ["EventCalendar", "API", "updt_widgetFollowerPos", "API call complete"],
  no_widgetEl: ["EventCalendar", "API", "updt_widgetFollowerPos", "no widget el. provided"],
  no_followerEl: ["EventCalendar", "API", "updt_widgetFollowerPos", "no follower el. provided"],
};
const defConfig = { instant: false, followerAlign: "below-right" };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_widgetFollowerPos(EL_widget, EL_follower, CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { instant, followerAlign } = config;
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!EL_widget) return this.logger("warning", msgs.no_widgetEl, "warning", logArgs);
  if (!EL_follower) return this.logger("warning", msgs.no_followerEl, "warning", logArgs);

  ///////////////////////////////////// Follower pos. updt. //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get widget el. position...
  const rect_widget = getRect(EL_widget);
  const w_widget = rect_widget.width;
  const h_widget = rect_widget.height;
  const l_widget = rect_widget.left;
  const r_widget = rect_widget.right;
  const b_widget = rect_widget.bottom;

  // Get follower el. dimensions...
  const rect_follower = getRect(EL_follower);
  const w_follower = rect_follower.width;

  // Get window scroll offset...
  const l_scroll = window.scrollY;

  // Init. follower x/y pos. vars...
  let x, y;

  /////////////////// Below-right placement (default) ////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Determ. follower x/y pos. (account for scroll offset)...
  if (followerAlign === "below-right") {
    x = l_widget + w_widget / 3;
    y = b_widget - l_scroll;
  }

  //////////////////////// Behind-left placement ////////////////////////
  ////////////////////////////////////////////////////////////////////////
  else if (followerAlign === "behind-left") {
    x = l_widget - w_follower + w_widget / 2.5;
    y = b_widget - l_scroll - h_widget / 2.5;
  }

  //////////////////////// Behind-right placement ////////////////////////
  ////////////////////////////////////////////////////////////////////////
  else if (followerAlign === "behind-right") {
    x = r_widget - w_widget / 3;
    y = b_widget - l_scroll - h_widget / 2.5;
  }

  //////////////////////////// Position updt. ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Updt. follower position...
  if (instant) gsap.set(EL_follower, { x, y });
  else gsap.to(EL_follower, { x, y, duration: 0.3, ease: "power3.out" });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
