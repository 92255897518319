// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.blockSubsectionScroll", inline: true, disabled: false };
const msgs = {
  start: ["PartiForm", "API", "blockSubsectionScroll"],
  end: ["PartiForm", "API", "blockSubsectionScroll", "API call complete"],
  no_selector: ["PartiForm", "API", "blockSubsectionScroll", "no section selector provided"],
  no_subsectionEl: ["PartiForm", "API", "blockSubsectionScroll", "no subsection el. found"],
  no_overflowWrapperEl: ["PartiForm", "API", "blockSubsectionScroll", "no overflow wrapper el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function blockSubsectionScroll(SELECTOR_subsection) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!valid_str(SELECTOR_subsection)) return this.logger("error", msgs.no_selector, "error", logArgs);

  //////////////////////////////////////// Scroll block. /////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find subsection el. (+ guard)...
  const subsectionEl = document.querySelector(SELECTOR_subsection);
  if (!subsectionEl) return this.logger("error", msgs.no_subsectionEl, "error", logArgs);

  // Find overflow wrapper el. (+ guard)...
  const overflowWrapperEl = subsectionEl.closest(".fp-overflow");
  if (!overflowWrapperEl) return this.logger("error", msgs.no_overflowWrapperEl, "error", logArgs);

  // Block scroll on overflow wrapper el...
  overflowWrapperEl.style.overflowY = "hidden";

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
