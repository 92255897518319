// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect } from "mezr";
import gsap from "gsap";

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.updt_cardFollowerPos", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "updt_cardFollowerPos"],
  end: ["PartiForm", "API", "updt_cardFollowerPos", "API call complete"],
  no_cardEl: ["DragCards", "API", "updt_cardFollowerPos", "no valid card el. provided"],
  no_followerEl: (CARDEL) => ["DragCards", "API", "updt_cardFollowerPos", "no follower el. found for card el.: ", CARDEL],
};

const defConfig = {
  positioningScheme: "below",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function updt_cardFollowerPos(CARDEL, CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  this.logger("event", msgs.start, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.logger("error", msgs.no_cardEl, "error", logArgs);

  ////////////////////////// Card follower el. retrieval & measurement ///////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Find related follower el...
  const followerEl = this.get_cardFollowerEl(CARDEL);
  if (!followerEl) return this.logger("error", msgs.no_followerEl(CARDEL), "error", { ...logArgs, inline: false });

  // Get card & follower el. dimensions...
  const rect_card = getRect(CARDEL);
  const { left: l_card, bottom: b_card, right: r_card, top: t_card } = rect_card;

  // Get offset of follower container relative to viewport...
  const followerContainerEl = followerEl.offsetParent;
  const rect_container = getRect(followerContainerEl);
  const { left: l_container, top: t_container } = rect_container;

  ///////////////////////////////// Card follower position updt. /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Setup...
  let x, y;
  const { positioningScheme } = config;

  // Determ. follower x/y pos. (while accounting for container offset)...
  if (positioningScheme === "below") {
    x = l_card - l_container + rect_card.width / 3;
    y = b_card - t_container;
  } else if (positioningScheme === "top-right") {
    const follower_isClipped = followerEl.dataset.expandOnHover === "true";
    const rect_follower = getRect(followerEl);
    const { width: w_follower, height: h_follower } = rect_follower;
    const offset = {
      x: follower_isClipped ? w_follower * 0.1 + w_follower / 2 : 0,
      y: follower_isClipped ? (h_follower * 0.25 + 30) * -1 : 0,
    };
    x = r_card - l_container - offset.x;
    y = t_card - h_follower - t_container - offset.y;
  }

  // Set card follower pos...
  gsap.to(followerEl, { duration: 0.3, ease: "power3.out", x, y });

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
