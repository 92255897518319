// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import TweenMax from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elementTransform from "../../../../../app/baseUtilities/get/get_elementTransform";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.filter_cards", inline: true };
const msgs = {
  start: ["DragCards", "API", "filter_cards"],
  end: ["DragCards", "API", "filter_cards", "API call complete"],
  in_initMode: ["DragCards", "API", "filter_cards", "in init mode", "stopping API hdl. execution"],
  no_filterId: ["DragCards", "API", "filter_cards", "no filter ID provided", "stopping API hdl. execution"],
  no_cardStates: ["DragCards", "API", "filter_cards", "no card states found", "stopping API hdl. execution"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function filter_cards(FILTERIDS = ["all"]) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { mode, cardStates } = this.state;
  this.logger("event", msgs.start, "event", logArgs);
  if (mode === "init") return this.logger("warning", msgs.in_initMode, "warning", logArgs);

  // Guard...
  if (!FILTERIDS) return this.logger("warning", msgs.no_filterId, "warning", logArgs);
  if (!cardStates) return this.logger("warning", msgs.no_cardStates, "warning", logArgs);

  //////////////////////////////////////// Card filtering ////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Create new card states w/ updated "is_hidden" state...
  const noFilterSelected = FILTERIDS.includes("all") && FILTERIDS.length === 1;
  const newCardStates = cardStates.map((cardState) => {
    // If no filter selected (i.e. only "all" filter), show all cards...
    if (noFilterSelected) return { ...cardState, state: { ...cardState.state, is_hidden: false } };

    // - Get tags of card (to match against filters)...
    // - If no tags found, hide card...
    const cardTags = cardState?.config?.tags;
    if (!cardTags) return { ...cardState, state: { ...cardState.state, is_hidden: true } };

    // Stop if card is to be shown + already visible + has already been filtered (has pos. before filtering)...
    const is_alreadyVisible = !cardState.state.is_hidden && FILTERIDS.some((filterID) => cardTags.includes(filterID));
    const has_beenFiltered = cardState.state.pos_beforeFiltering !== null;
    if (is_alreadyVisible && has_beenFiltered) return cardState;

    // If card is to be shown, record curr. pos. (to restore on filter deselect)...
    const is_hidden = !FILTERIDS.some((filterID) => cardTags.includes(filterID));
    let pos_beforeFiltering = cardState.state.pos_beforeFiltering;
    if (!is_hidden) {
      const cardElTransform = get_elementTransform(cardState.el);
      pos_beforeFiltering = { x: cardElTransform.tx, y: cardElTransform.ty };
    }

    // Match card tags against filter IDs...
    return { ...cardState, state: { ...cardState.state, is_hidden, pos_beforeFiltering } };
  });
  this.setState({ cardStates: newCardStates });

  /////////////////////////// Card move-into-view/move-back-to-origin ////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // If not filters selected, move all cards back to their prev. positions...
  if (noFilterSelected) {
    newCardStates.forEach((cardState) => {
      //////////////////////////////// Setup /////////////////////////////////
      ////////////////////////////////////////////////////////////////////////

      const { el: cardEl, state } = cardState;
      const { pos_beforeFiltering } = state;

      /////////////////////////// Position restore ///////////////////////////
      ////////////////////////////////////////////////////////////////////////

      // Move back to its prev. position...
      if (pos_beforeFiltering) {
        const { x, y } = pos_beforeFiltering;
        TweenMax.to(cardEl, { x, y, duration: 0.6, ease: "power3.out" });
      }
    });
  }

  // Otherwise, move active cards into user's view...
  else {
    const cards_active = newCardStates.filter((cardState) => !cardState.state.is_hidden);
    if (cards_active.length > 0) {
      cards_active.forEach((cardState) => this.api.move_cardIntoViewport(cardState.el));
    }
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
