// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const msgs = {
  change: (CHANGE) => [`active list item:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeListItem(CHANGES) {
  if (!("activeListItem" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { activeListItem } = CHANGES;
  const { listItems } = this.ref;
  this.logger("state-change", msgs.change(activeListItem), "default", { ...logArgs, inline: false });

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // - Updt. items' data-is-active attr...
  // - Reset scale if not active...
  listItems.forEach((item) => {
    const itemID = item.dataset.id;
    const is_active = itemID === activeListItem.id;
    item.setAttribute("data-is-active", is_active);
    if (!is_active) item.style.setProperty("--scale-x-when-active", 1);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
