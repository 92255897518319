// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_cardExpandType(CARDEL, CONFIG) {
  return CARDEL.hasAttribute("data-expand-type") ? CARDEL.getAttribute("data-expand-type") : CONFIG.type;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.contract_card", inline: true, disabled: false };
const msgs = {
  start: ["DragCards", "API", "contract_card"],
  end: ["PartiForm", "API", "contract_card", "API call complete"],
};

const defConfig = {
  duration: 0.333,
  width: "100vw",
  height: "100vh",
  x: 0,
  y: 0,
  zIndex: 1000,
  ease: "power3.inOut",
  type: "width-height",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function contract_card(CARDEL, CONFIG = defConfig) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  // Config. //
  const config = { ...defConfig, ...CONFIG };
  const { duration, ease } = config;
  const type = get_cardExpandType(CARDEL, config); // ← determ. expand. anim. type

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(msgs.no_el);

  ///////////////////////// Anim. card contract. /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Set card as animating & contracting...
  CARDEL.setAttribute("data-is-animating", "true");
  CARDEL.setAttribute("data-is-contracting", "true");

  // Get org. card dimensions from el. data attr...
  const w_org = CARDEL.dataset.wOrg;
  const h_org = CARDEL.dataset.hOrg;

  // Prep. anim. config...
  const animConfig = {};
  if (type === "width-height") {
    animConfig.width = w_org;
    animConfig.height = h_org;
  } else if (type === "scale") {
    animConfig.scaleX = 1;
    animConfig.scaleY = 1;
  }

  // Apply org. size to card el...
  CARDEL.setAttribute("data-is-expanded", "false");
  gsap.to(CARDEL, {
    ...animConfig,
    duration,
    ease,
    onComplete: () => {
      //// is-animating/-contracting attr. reset /////
      ////////////////////////////////////////////////

      CARDEL.setAttribute("data-is-animating", "false");
      CARDEL.setAttribute("data-is-contracting", "false");

      /////////// Card follower pos. updt. ///////////
      ////////////////////////////////////////////////

      if (CARDEL.dataset.withFollower === "true")
        this.api.updt_cardFollowerPos(CARDEL, { positioningScheme: CARDEL.dataset.followerPos || "below" });
    },
  });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("success", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
