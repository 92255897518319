// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_surveyForm_submitBtn_click", inline: true };
const msgs = {
  start: ["survey-form btn. was clicked"],
};
const consoleStyles = {
  default: "color: orange; font-weight: bold;",
  success: "color: green; font-weight: bold;",
  error: "color: red; font-weight: bold;",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_surveyForm_submitBtn_click(CARDEL, EVENTDATA) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const notifEl = CARDEL.querySelector("[data-ref='notif']");
  const submitBtn = CARDEL.querySelector("[data-ref='submitBtn']");
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Submit form...
  return new Promise((resolve, reject) => {
    const surveyPageID = CARDEL.getAttribute("data-id");
    const answerInput = CARDEL.querySelector("[data-ref='answerInput']");
    const answerText = answerInput.value;

    // Set up backend request...
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("surveyPageID", surveyPageID);
    data.append("answer", answerText);

    // Init. + send request...
    req.open("POST", "/api.survey-form.submit", true);
    req.onreadystatechange = () => {
      //////////////////////// Successful subm. hdl. /////////////////////////
      ////////////////////////////////////////////////////////////////////////

      if (req.readyState === 4 && req.status === 200) {
        console.log("%cSurvey form submitted.", consoleStyles.success);

        // Show success notif...
        if (notifEl) {
          notifEl.setAttribute("data-is-hidden", "false");
          notifEl.setAttribute("data-mode", "success");
          setTimeout(() => notifEl.setAttribute("data-is-hidden", "true"), 3000);
        }

        // - Reset form fields & submit btn...
        // - Reset form textarea height...
        // - Resolve...
        answerInput.value = "";
        submitBtn.setAttribute("data-is-hidden", "true");
        answerInput.style.height = "auto";
        resolve(req.responseText);
      }

      ////////////////////////// Failed subm. hdl. ///////////////////////////
      ////////////////////////////////////////////////////////////////////////

      if (req.status !== 200) {
        console.log("%cSurvey form submission failed.", consoleStyles.error);

        // Show error notif...
        if (notifEl) {
          notifEl.setAttribute("data-is-hidden", "false");
          notifEl.setAttribute("data-mode", "error");
          setTimeout(() => notifEl.setAttribute("data-is-hidden", "true"), 3000);
        }

        // Reject...
        reject("eh_surveyForm_submitBtn_click(): Survey form submission failed.");
      }
    };
    req.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
