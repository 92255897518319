// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { getRect, getWidth, getHeight } from "mezr";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_cardCommentBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["card comment btn. click"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_cardCommentBtn_click(CARDEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { is_mobile } = this.state;
  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////// Comment widget reveal/hide //////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { commentWidgetState } = this.state;
  const { is_hidden } = commentWidgetState;
  const mb_card_default = 20;

  //////////////// Widget reveal /////////////////
  ////////////////////////////////////////////////

  if (is_hidden) {
    // - Get card context...
    // - Fetch post comments...
    const cardContext = CARDEL.dataset.context;
    const commentsData = await api.fetch_postComments(CARDEL.dataset.id);

    // Get card dimensions...
    const rect_card = getRect(CARDEL);
    const w_card = getWidth(CARDEL);
    const { left: l_card, bottom: b_card } = rect_card;

    // Determ. new widget x/y pos.
    const x = l_card;
    const y = b_card;

    // Show + reposition comment widget via comp. API...
    api.show_commentWidget({ x, y }, w_card, cardContext, commentsData);

    // On mobile, add bottom margin to post card to create space for comment widget...
    if (is_mobile) {
      // - Measure comment widget height...
      // - Add margin to post card...
      const { commentWidget } = this.ref;
      const h_commentWidget = getHeight(commentWidget);
      CARDEL.style.marginBottom = `${h_commentWidget + mb_card_default}px`;
    }
  }

  ///////////////// Widget hide //////////////////
  ////////////////////////////////////////////////
  else {
    // Hide comment widget via comp. API...
    api.hide_commentWidget();

    // On mobile, remove bottom margin from post card...
    if (is_mobile) CARDEL.style.marginBottom = `${mb_card_default}px`;
  }

  //////////////////////// Comment widget anchor el. setting /////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Mark card el. as anchor el. of comment widget...
  CARDEL.setAttribute("data-is-comment-widget-anchor", "true");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
