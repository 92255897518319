// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elMeasurements from "../../../../../app/baseUtilities/get/get_elementMeasurements";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_widget", inline: true, disabled: false };
const msgs = {
  start: ["init. widget"],
  end: ["init. widget", "init. success"],
  no_widgetWrapper: ["init. widget", "no widget wrapper el. found"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_widget() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { widgetWrapper } = this.ref;
  const { is_tablet_ls } = this.state;
  const { wigetPosition = "random" } = this.options;
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(widgetWrapper)) return this.cancel_featInit(msgs.no_widgetWrapper);

  ////////////////////////////////////// Random pos. init. ///////////////////////////////////////
  //////////////////////////////////////// (desktop only) ////////////////////////////////////////

  if (!is_tablet_ls) {
    // - Determ. side of screen to move widget to...
    // - Determ. if to move widget up or down...
    // - Measure widget...
    // - Determ. random offsets by which to move widget away from edge...
    // - Init. pos. vars...
    const leftOrRight = wigetPosition === "random" ? (Math.random() > 0.5 ? "left" : "right") : wigetPosition;
    const upOrDown = Math.random() > 0.5 ? "up" : "down";
    const { l: l_widget, r: r_widget } = get_elMeasurements(widgetWrapper);
    const randomOffset_x = Math.random() * 100 + 50;
    // const randomOffset_y = Math.random() * 25 + 50;
    const randomOffset_y = 0;
    let x;
    let y = upOrDown === "up" ? -randomOffset_y : randomOffset_y;

    // Move widget to left edge of screen...
    if (leftOrRight === "left") {
      // - Determ. dist. to left edge...
      // - Set pos. vars...
      const dist_toLeftEdge = l_widget;
      x = -dist_toLeftEdge + randomOffset_x;
    }

    // Move widget to right edge of screen...
    else {
      // - Determ. dist. to right edge...
      // - Set pos. vars...
      const dist_toRightEdge = window.innerWidth - r_widget;
      x = dist_toRightEdge - randomOffset_x;
    }

    // Move widget...
    widgetWrapper.style.transform = `translate(${x}px, ${y}px)`;
  }

  ///////////////////////////////////////// Drag. init. //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Init drag. inst...
  const dragInstConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  const dragInst = Draggable.create(widgetWrapper, dragInstConfig)[0];

  // If init. while on mobile (< tablet landscape), disable drag behavior (will be re-enabled on window resize)...
  if (is_tablet_ls) dragInst.disable();

  // Save drag. inst. to comp. modules...
  this.modules.drag.instance = dragInst;

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
