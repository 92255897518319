// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_footnotes", inline: true, disabled: false };
const msgs = {
  start: ["init. init_footnotes"],
  end: ["init. init_footnotes", "init. success"],
  no_footnoteLinks: ["init. init_footnotes", "no footnote link els. found"],
  no_footnoteEls: ["init. init_footnotes", "no footnote els. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_footnotes() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { is_mobile, is_tablet_prt, is_tablet_ls, is_desktop_sm } = this.state;
  this.logger("init", msgs.start, "action", logArgs);

  // Find footnote link & footnote DOM elements on page...
  const footnoteLinks = document.querySelectorAll("[data-footnote-link]");
  const footnotes = document.querySelectorAll("[data-ref='footnote']");

  // Guard: Stop, is no footnote links or footnote els. found...
  if (!footnoteLinks.length) return this.logger("init", msgs.no_footnoteLinks, "warning", logArgs);
  if (!footnotes.length) return this.logger("init", msgs.no_footnoteEls, "warning", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////// On screens < tablet-landscape /////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (is_mobile || is_tablet_prt) {
    // Init. footnote interactions (click on footnote link scroll to footnote)...
    this.init_footnoteInteractions();
  }

  //////////////////// On screens >= tablet-landscape ////////////////////
  ////////////////////////////////////////////////////////////////////////
  else if (is_tablet_ls || is_desktop_sm) {
    // Set footnote positions...
    this.init_footnotePositions();
  }

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
