// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_btn_click from "../eventHandlers/eh_btn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_controls", inline: true };
const msgs = {
  start: ["init. controls"],
  end: ["init. controls", "init. success"],
  no_el: ["init. controls", "no valid DOM element(s) provided"],
};

// —————————————————————————————————————— INIT. METHOD DEF. ——————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_controls() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { prevBtn, nextBtn, enlargeBtn } = this.ref;
  const btnEls = [prevBtn, nextBtn, enlargeBtn];
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (btnEls.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Prev./Next-btn. event hdl...
  [prevBtn, nextBtn].forEach((el) => el.addEventListener("click", eh_btn_click.bind(this, el)));

  // Enlarge-btn. event hdl...
  if (enlargeBtn) enlargeBtn.addEventListener("click", () => this.setState({ is_enlarged: !this.state.is_enlarged }));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
