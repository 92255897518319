// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_filterBtn_click", inline: true, disabled: false };
const msgs = {
  start: ["an event occurred"],
};
const defConfig = { filterType: "month" };

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_filterBtn_click(BUTTONEL, CONFIG = defConfig) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const config = { ...defConfig, ...CONFIG };
  const { filterType } = config;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Copy active filters...
  let activeFilters_new =
    filterType === "month"
      ? [...this.state.activeMonthFilters]
      : filterType === "context"
      ? [...this.state.activeContextFilters]
      : [];

  // Get filter ID from btn. el...
  const filterID = BUTTONEL.dataset.filterId;

  // If filter is already active, remove it...
  if (activeFilters_new.includes(filterID)) {
    activeFilters_new = activeFilters_new.length === 1 ? ["all"] : activeFilters_new.filter((ID) => ID !== filterID);
  }

  // Otherwise, add it to active filters...
  else activeFilters_new.push(filterID);

  // Updt. state...
  if (filterType === "month") this.setState({ activeMonthFilters: activeFilters_new });
  else if (filterType === "context") this.setState({ activeContextFilters: activeFilters_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
