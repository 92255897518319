// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_infoOverlayState", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`infoOverlayState:`, CHANGE],
  no_change: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_infoOverlayState(CHANGES) {
  if (!("infoOverlayState" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const infoOverlayState = { ...this.state.infoOverlayState, ...CHANGES.infoOverlayState };
  this.logger("state-change", msgs.change(infoOverlayState), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// is-hidden ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_hidden" in infoOverlayState) {
    const { infoOverlay, openInfoOverlayBtn } = this.ref;

    // Updt info overlay hidden attr...
    const { is_hidden } = infoOverlayState;
    infoOverlay.setAttribute("data-is-hidden", is_hidden);

    // Updt open info overlay btn active attr...
    openInfoOverlayBtn.setAttribute("data-is-active", !is_hidden);
  }

  ///////////////////////////// is_disabled //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("is_disabled" in infoOverlayState) {
    const { openInfoOverlayBtn } = this.ref;
    const { is_disabled } = infoOverlayState;
    openInfoOverlayBtn.setAttribute("data-is-hidden", is_disabled);
  }

  ///////////////////////////// activeSlide //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("activeSlide" in infoOverlayState) {
    const { infoOverlaySlides } = this.ref;
    const { activeSlide } = infoOverlayState;
    infoOverlaySlides.forEach((s) => s.setAttribute("data-is-hidden", s.dataset.gameId != activeSlide));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
