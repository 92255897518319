// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_gameSelectBtn_click", inline: true };
const msgs = {
  start: ["game-select. btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_gameSelectBtn_click(BTNEL) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  ////////////////////////////// Step updt. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Updt. current-step state...
  const gameID = BTNEL.getAttribute("data-id");
  const nextStep = gameID === "drawing" ? "startDrawingGame" : gameID === "writing" ? "startWritingGame" : "startMatrixGame";
  this.setState({ currentStep: nextStep });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
