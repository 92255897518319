// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_hidden", inline: true, disabled: false };
const msgs = {
  change: (CHANGE) => [`hidden: ${CHANGE}`],
  no_change: () => ["No change to state provided."],
  inval_stateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_hidden(CHANGES) {
  if (!("hidden" in CHANGES)) return this.logger("error", msgs.no_change(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.hidden))
    return this.logger("error", msgs.inval_stateValue(CHANGES.change), "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { hidden } = CHANGES;
  this.logger("state-change", msgs.change(hidden), "default", logArgs);

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { menuBtn, siteControls, searchBar } = this.ref;

  ///////////////////// subcomp. hidden-attr. updt. //////////////////////
  ////////////////////////////////////////////////////////////////////////

  [menuBtn, siteControls, searchBar].forEach((el) => el.setAttribute("data-is-hidden", hidden));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
