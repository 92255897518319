// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeContextFilters", inline: true, disabled: false };
const msgs = {
  stateChange: (CHANGE) => [`active context filters:`, CHANGE],
  no_change: ["No change to state provided."],
};

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeContextFilters(CHANGES) {
  if (!("activeContextFilters" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const { activeContextFilters } = CHANGES;
  const { activeMonthFilters } = this.state;
  const { contextFilterBtns } = this.ref;
  this.logger("state-change", msgs.stateChange(activeContextFilters), "default", { ...logArgs, inline: false });

  //////////////////////////////////// State change execution ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////// Visible event card filtering /////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.api.filter_eventCards(activeMonthFilters, activeContextFilters);

  ///////////// Context-filter btns. active/inactive setting /////////////
  ////////////////////////////////////////////////////////////////////////

  contextFilterBtns.forEach((btn) => btn.setAttribute("data-is-active", activeContextFilters.includes(btn.dataset.filterId)));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
