// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_el_isVisible from "../../../../../app/baseUtilities/check/check_el_isVisible.js";
import set_btnBorderSVGCornerRadius from "../util/set_btnBorderSVGCornerRadius.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_btnSVGborders", inline: true };
const msgs = {
  start: ["init. btnSVGborders"],
  end: ["init. btnSVGborders", "init. success"],
  no_el: ["init. btnSVGborders", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_btnSVGborders(BTNELS) {
  // Setup...
  this.logger("init", msgs.start, "action", logArgs);

  // Guard...
  if (BTNELS === undefined) return this.cancel_featInit(msgs.no_el);
  if (BTNELS.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(msgs.no_el);

  // Set border SVG corner radius on visible btns...
  const btns_visible = BTNELS.filter((btn) => check_el_isVisible(btn));
  btns_visible.forEach((btn) => set_btnBorderSVGCornerRadius(btn));

  // Conclude...
  this.logger("init", msgs.end, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
