// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_glossaryLink_mouseEnter", inline: true };
const msgs = {
  start: ["mouse entered glossary link"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default async function eh_glossaryLink_mouseEnter(LINKEL) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("event", msgs.start, "event", logArgs);

  /////////////////////////////// Glossary term widget state updt. ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get glossary term attached to link...
  const glossaryTerm = LINKEL.dataset.term;
  const context = LINKEL.dataset.context;

  // - Fetch corresp. term text...
  // - Updt. display widget state...
  //   - Insert term text...
  //   - Extract and updt. colors...
  const termText = await this.api.fetch_termText(glossaryTerm);
  const termColor_bg = LINKEL.dataset.colorBg;
  const termColor_text = LINKEL.dataset.colorText;
  const { activeTermWidgetState } = this.state;
  const activeTermWidgetState_new = {
    ...activeTermWidgetState,
    linkEl: LINKEL,
    term: glossaryTerm,
    text: termText,
    context,
    colors: {
      bg: termColor_bg,
      text: termColor_text,
      scrollbar: termColor_text,
    },
  };
  this.setState({ activeTermWidgetState: activeTermWidgetState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
