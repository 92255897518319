// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Marquee from "vanilla-marquee";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import valid_str from "../../../../../app/baseUtilities/validate/validate_string";
import create_slideShowSlide from "../util/create_slideShowSlide";

function eh_inactiveLink_click(e) {
  e.preventDefault();
  e.stopPropagation();
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_widgetStates", inline: true };
const msgs = {
  stateChange: (CHANGE) => [`widget states:`, CHANGE],
  no_change: ["No change to state provided."],
};

const defMarqueeConfig = { startVisible: true, duplicated: true, speed: 40, gap: 0, delayBeforeStart: 0 };

// —————————————————————————————————————————— HDL. DEF. ——————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_widgetStates(CHANGES) {
  if (!("widgetStates" in CHANGES)) return this.logger("error", msgs.no_change, "error", logArgs);

  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const api = this.api;
  const { is_tablet_prt } = this.state;
  const { widgetStates } = CHANGES;
  this.logger("state-change", msgs.stateChange(widgetStates), "default", { ...logArgs, inline: false });

  //////////////////////////////// Widget-state changes execution ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////// Date-widget //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("dateWidget" in widgetStates) {
    api.process_widgetStateUpdt_date(widgetStates.dateWidget);
  }

  //////////////////////////// Marquee-widget ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("marqueeWidget" in widgetStates) {
    const marqueeWidgetState = widgetStates.marqueeWidget;
    const { el, text, colors, is_hidden } = marqueeWidgetState;

    // Is-hidden attr. //
    if ("is_hidden" in marqueeWidgetState) {
      // - Updt. data-is-hidden attr...
      // - If visible, ensure higher z-index than other widgets...
      el?.setAttribute("data-is-hidden", is_hidden);
      if (!is_hidden) api.bringWidgetToFront(el);
    }

    // Updt. marquee text...
    if ("text" in marqueeWidgetState) {
      // Guard: check if data-text of widget el. already matches new text...
      if (el.dataset.text != text) {
        // Update data-text attr. + marquee text...
        el?.setAttribute("data-text", text);
        const marqueeEl = el.querySelector("[data-role='marquee']");
        const marqueeInst = marqueeWidgetState.marqueeInst;
        if (marqueeInst) {
          // Clear marqueeEl’s innerHTML + destroy marqueeInst...
          marqueeInst.pause();
          marqueeEl.innerHTML = "";
          marqueeInst.destroy();

          // Create mew marquee text + init. marquee...
          const markup = `<div class="txt-btn-lg uppercase">
                            <span>${text}</span>
                            <span>${text}</span><span>&nbsp;</span>
                          </div>`;
          marqueeEl.innerHTML = markup;
          const marqueeInst_new = new Marquee(marqueeEl, defMarqueeConfig);
          marqueeWidgetState.marqueeInst = marqueeInst_new;
        }
      }
    }

    // Updt. marquee custom color props...
    if ("colors" in marqueeWidgetState) {
      const { text, bg } = colors;
      el.style.setProperty("--color-text", text);
      el.style.setProperty("--color-bg", bg);
    }
  }

  ////////////////////////// Description-widget //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if (!is_tablet_prt && "descriptionWidget" in widgetStates) {
    const descriptionWidgetState = widgetStates.descriptionWidget;
    const { el, text, is_hidden } = descriptionWidgetState;

    // Updt. data-is-hidden attr...
    if ("is_hidden" in descriptionWidgetState) el?.setAttribute("data-is-hidden", is_hidden);

    // Updt. description text...
    if ("text" in descriptionWidgetState) {
      const textDisplay = el.querySelector("[data-ref='textDisplay']");
      if (textDisplay && textDisplay.textContent != text) textDisplay.innerHTML = text;
    }
  }

  ////////////////////////////// Img-widget //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("imgWidget" in widgetStates) {
    const imgWidgetState = widgetStates.imgWidget;
    const { el, imgSrc, imgCredit, is_hidden } = imgWidgetState;

    // Updt. data-is-hidden attr...
    if ("is_hidden" in imgWidgetState) el?.setAttribute("data-is-hidden", is_hidden);

    // Updt. img src...
    if ("imgSrc" in imgWidgetState) {
      if (imgSrc) {
        const imgDisplay = el.querySelector("[data-ref='imgDisplay']");
        const imgEl = el.querySelector("img");
        if (imgDisplay && imgEl && imgEl.src != imgSrc) imgEl.src = imgSrc;
      }
    }

    // Updt. img credit...
    if ("imgCredit" in imgWidgetState) {
      const has_imgCredit = valid_str(imgCredit);
      const creditDisplay = el.querySelector("[data-ref='imgCreditsDisplay']");
      if (creditDisplay && creditDisplay.textContent != imgCredit) creditDisplay.textContent = imgCredit;
      if (creditDisplay) creditDisplay.setAttribute("data-is-hidden", !has_imgCredit);
    }
  }

  ////////////////////////// Slideshow-widget ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("slideShowWidget" in widgetStates) {
    const slideShowWidgetState = widgetStates.slideShowWidget;
    const { el, imgSrcs, imgCredits, is_hidden } = slideShowWidgetState;

    // Updt. data-is-hidden attr...
    if ("is_hidden" in slideShowWidgetState) el?.setAttribute("data-is-hidden", is_hidden);

    // Updt. slides (from new imgSrcs) & img credits (if avail., from new imgCredits)...
    if ("imgSrcs" in slideShowWidgetState) {
      const flickityEl = el.querySelector("[data-ref='slidesWrapper']");
      if (flickityEl) {
        const flickityInst = Flickity.data(flickityEl);
        if (flickityInst) {
          // Remove curr. slides...
          flickityInst.remove(flickityInst.getCellElements());

          // Add new slides...
          imgSrcs.forEach((src) => {
            const slide = create_slideShowSlide(imgSrcs.indexOf(src), src);
            flickityInst.append(slide);
            flickityInst.resize();
          });
        }

        if ("imgCredits" in slideShowWidgetState) {
          // Supply new credits to slideshow inst. (via markup)...
          const slideShowEl = flickityEl.parentElement;
          slideShowEl?.setAttribute("data-credits", JSON.stringify(imgCredits));

          // Set init. img. credits...
          const imgCreditsDisplay = slideShowEl.querySelector("[data-ref='imgCreditsDisplay']");
          if (imgCreditsDisplay) imgCreditsDisplay.textContent = imgCredits[0];
        }
      }
    }
  }

  /////////////////////////// Location widget ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("locationWidget" in widgetStates) {
    const locationWidgetState = widgetStates.locationWidget;
    const { widgetFollower_location } = this.ref;
    const { el, text, text_long, mapsLink, is_hidden } = locationWidgetState;

    // Is-hidden attr. //
    if ("is_hidden" in locationWidgetState) {
      // - Updt. data-is-hidden attr...
      // - If visible, ensure higher z-index than other widgets...
      el?.setAttribute("data-is-hidden", is_hidden);
      if (!is_hidden) api.bringWidgetToFront(el);

      // - Updt. widget follower data-is-hidden attr...
      if (widgetFollower_location) widgetFollower_location.setAttribute("data-is-hidden", is_hidden);
    }

    // Updt. location text...
    if ("text" in locationWidgetState) {
      const locationDisplay = el.querySelector("[data-ref='locationDisplay']");
      if (locationDisplay && locationDisplay.textContent != text) locationDisplay.textContent = text;
    }

    // Updt. widget follower location text (long)...
    if ("text_long" in locationWidgetState && widgetFollower_location) {
      const locationDisplay_long = widgetFollower_location.querySelector("[data-ref='locationDisplay_long']");
      if (locationDisplay_long && locationDisplay_long.textContent != text_long) locationDisplay_long.textContent = text_long;
    }

    // Updt. maps link...
    if ("mapsLink" in locationWidgetState) {
      el.href = mapsLink === null ? "#" : mapsLink;
      el?.setAttribute("data-has-url", mapsLink !== null);

      // Add/Remove inactive-link-click hdl...
      if (mapsLink === null) el.addEventListener("click", eh_inactiveLink_click);
      else el.removeEventListener("click", eh_inactiveLink_click);
    }
  }

  ////////////////////////// ticketLink-widget ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("ticketLinkWidget" in widgetStates) {
    const ticketLinkWidgetState = widgetStates.ticketLinkWidget;
    const { el, ticketLink, is_hidden } = ticketLinkWidgetState;

    // Is-hidden attr. //
    if ("is_hidden" in ticketLinkWidgetState) {
      // - Updt. data-is-hidden attr...
      // - If visible, ensure higher z-index than other widgets
      el?.setAttribute("data-is-hidden", is_hidden);
      if (!is_hidden) api.bringWidgetToFront(el);
    }

    // Updt. ticket link...
    if ("ticketLink" in ticketLinkWidgetState) {
      el.href = ticketLink === null ? "#" : ticketLink;
      el?.setAttribute("data-has-url", ticketLink !== null);

      // Add/Remove inactive-link-click hdl...
      if (ticketLink === null) el.addEventListener("click", eh_inactiveLink_click);
      else el.removeEventListener("click", eh_inactiveLink_click);
    }
  }

  /////////////////////////// Character-widget ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  if ("characterWidget" in widgetStates) {
    api.process_widgetStateUpdt_character(widgetStates.characterWidget);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
